import styled from 'styled-components';

export default function Modal({ visible, onClose, children }) {
  const handleBackClick = (e) => {
    e.stopPropagation();
    e.preventDefault();

    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return visible && <ModalWrapper onClick={handleBackClick}>{children}</ModalWrapper>;
}

const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 50;
  background-color: rgba(0, 0, 0, 0.5);
`;
