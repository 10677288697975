import BodyTitle from 'components/BodyTitle';
import InterestContents from 'components/InterestContents';
import { AuthActions } from 'context/AuthContextProvider';
import { useAuthDispatch, useAuthState } from 'hooks/useAuthContext';
import { useCallback } from 'react';
import { Container, Heading } from 'react-bulma-components';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

export default function EditInterestContents() {
  const history = useHistory();
  const user = useAuthState();
  const dispatch = useAuthDispatch();

  const handleNext = useCallback(
    ([interest1, interest2, interest3, interest4, interest5]) => {
      dispatch({
        type: AuthActions.SET_PROFILE,
        payload: {
          interest1,
          interest2,
          interest3,
          interest4,
          interest5,
        },
      });
      history.replace('/mypage/profile');
    },
    [history, dispatch]
  );

  return (
    <StyledContainer py={6} breakpoint="desktop" max={true}>
      <BodyTitle paths={['홈', '마이페이지', '프로필 편집', '관심 활동 변경']} title="관심있는 활동" />
      <Heading pt={6} subtitle>
        선호하는 활동을 선택하면(최대 5개) 매일매일 관심 있는 콘텐츠를 추천 받을 수 있습니다.
      </Heading>
      <InterestContents
        initialDatas={[user.interest1, user.interest2, user.interest3, user.interest4, user.interest5].filter(Boolean)}
        onNext={handleNext}
      />
    </StyledContainer>
  );
}

const StyledContainer = styled(Container)`
  @media screen and (max-width: 1023px) {
    padding-right: 72px !important;
    padding-left: 72px !important;
  }
  @media screen and (max-width: 768px) {
    padding-right: 12px !important;
    padding-left: 12px !important;
  }
`;
