import { faClock, faComment, faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DeleteButton, EditButton } from 'CommonStyles';
import dayjs from 'dayjs';
import { Block, Icon } from 'react-bulma-components';
import styled from 'styled-components';

export default function PostAuthor({
  author,
  created,
  viewCount,
  commentCount = 0,
  isWriter,
  onEditButtonClick,
  onDeleteButtonClick,
}) {
  return (
    <Wrapper>
      <Extra>
        <Author>{author}</Author>
        <span>
          <Icon>
            <FontAwesomeIcon icon={faClock} style={{ color: '#999' }} />
          </Icon>
          {dayjs().to(dayjs(created))}
        </span>
        <span>
          <Icon>
            <FontAwesomeIcon icon={faEye} style={{ color: '#999' }} />
          </Icon>
          {viewCount}
        </span>
        <span>
          <Icon>
            <FontAwesomeIcon icon={faComment} style={{ color: '#999' }} />
          </Icon>
          {commentCount}
        </span>
      </Extra>
      {isWriter && (
        <Block display="flex">
          <EditButton onClick={onEditButtonClick}>수정</EditButton>
          <DeleteButton onClick={onDeleteButtonClick}>삭제</DeleteButton>
        </Block>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding-bottom: 7px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Author = styled.div`
  display: inline-block;
  padding: 20px 0;
  &::after {
    padding: 2px 15px 0;
    content: ' | ';
  }
`;

const Extra = styled.div`
  display: inline-flex;
  color: #999;
  span:not(.icon) {
    align-items: center;
    display: flex;
    margin-right: 12px;
  }
  svg {
    margin-right: 4px;
  }
`;
