import theme from 'constants/theme';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { Block, Modal } from 'react-bulma-components';
import styled from 'styled-components';

import Button from './Button';

function detectIE() {
  const ua = window.navigator.userAgent;

  // Test values; Uncomment to check result …

  // IE 10
  // ua = 'Mozilla/5.0 (compatible; MSIE 10.0; Windows NT 6.2; Trident/6.0)';

  // IE 11
  // ua = 'Mozilla/5.0 (Windows NT 6.3; Trident/7.0; rv:11.0) like Gecko';

  const msie = ua.indexOf('MSIE ');
  if (msie > 0) {
    // IE 10 or older => return version number
    return true;
  }

  const trident = ua.indexOf('Trident/');
  if (trident > 0) {
    // IE 11 => return version number
    return true;
  }

  // other browser
  return false;
}

export default function ChromeDownloadModal() {
  const [visible, setVisible] = useState(false);

  const close = () => {
    setVisible(false);
  };

  const open = () => {
    setVisible(true);
  };

  useEffect(() => {
    const isIeDetected = detectIE();
    const today = dayjs().startOf('day');
    const lastVisited = localStorage.getItem('__IE__');
    const isLastVisitedAfterToday = lastVisited == null ? true : today.isAfter(JSON.parse(lastVisited));

    if (isIeDetected && isLastVisitedAfterToday) {
      open();
      localStorage.setItem('__IE__', JSON.stringify(today));
    }
  }, []);

  return visible ? (
    <Modal show={visible} onClose={close}>
      <ModalContent>
        <ModalHeader>
          <p>마이돌봄 페이지는</p>
          <p style={{ color: theme.yellow }}>크롬, 엣지, 파이어폭스, 사파리에 최적화 되어있습니다.</p>
        </ModalHeader>
        <p style={{ textAlign: 'center', fontSize: 20, paddingTop: 32 }}>
          Internet Explorer 환경에선 원활한 화면 작동이 이루어지지 않을 수 있습니다.
        </p>
        <p style={{ textAlign: 'center', fontSize: 20, paddingTop: 4, paddingBottom: 32 }}>
          다른 브라우저를 이용해 마이돌봄 페이지를 열어주시길 바랍니다.
        </p>
        <Block pt={5} display="flex" justifyContent="center">
          <Button
            onClick={() => {
              window.open('https://www.google.com/intl/ko/chrome/', '_blank');
            }}
          >
            크롬 다운로드
          </Button>
        </Block>
      </ModalContent>
    </Modal>
  ) : null;
}

const ModalContent = styled(Modal.Content)`
  padding: 20px;
  border-radius: 8px;
  background-color: #fff;
  width: 90%;
  @media screen and (min-width: 769px) {
    width: 960px;
  }
`;

const ModalHeader = styled.div`
  font-size: 2rem;
  text-align: center;
  font-weight: bold;

  p {
    padding: 8px 0;
  }
`;
