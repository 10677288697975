import { requestEditEmail } from 'api';
import BodyTitle from 'components/BodyTitle';
import Button from 'components/Button';
import { ForwardedInput } from 'components/Form';
import FormLabel from 'components/Form/FormLabel';
import ERROR_REQUEST from 'constants/errorMessages';
import { AuthActions } from 'context/AuthContextProvider';
import { useAuthDispatch, useAuthState } from 'hooks/useAuthContext';
import { Block, Box, Container, Form } from 'react-bulma-components';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { emailRegex } from 'utils/validate';

const { Field, Help, Control } = Form;

export default function EditEmail() {
  const user = useAuthState();
  const dispatch = useAuthDispatch();
  const history = useHistory();
  const methods = useForm();
  const { register, formState } = methods;
  const { errors } = formState;

  const handleSubmit = (data) => {
    (async () => {
      const {
        data: response,
        error,
        requestName,
      } = await requestEditEmail({
        email: data.email,
      });

      if (error) {
        alert(ERROR_REQUEST[requestName][error.response.status]);
        return;
      }

      if (response) {
        dispatch({
          type: AuthActions.SET_PROFILE,
          payload: {
            email: data.email,
          },
        });
        history.replace('/mypage/profile');
      }
    })();
  };

  return (
    <StyledContainer py={6} breakpoint="desktop" max={true}>
      <BodyTitle paths={['홈', '마이페이지', '프로필편집', '이메일 변경']} title="이메일 변경" />

      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(handleSubmit)}>
          <ResponsiveBox mt={5}>
            <Field color="yellow">
              <FormLabel>현재 이매일</FormLabel>
              <Block>{user.email}</Block>
            </Field>
            <Field>
              <FormLabel required>변경할 이메일</FormLabel>
              <Control>
                <ForwardedInput
                  {...register('email', {
                    required: '변경할 이메일을 입력해주세요.',
                    pattern: {
                      value: emailRegex,
                      message: '이메일 형식으로 입력해주세요.',
                    },
                  })}
                  type="text"
                  placeholder="변경할 이메일을 입력해주세요."
                  color={errors?.email && 'danger'}
                />
              </Control>
              {errors?.email && <Help color="danger">{errors?.email?.message}</Help>}
            </Field>
            <EditButton block={true} noBorder={true} type="submit">
              변경하기
            </EditButton>
          </ResponsiveBox>
        </form>
      </FormProvider>
    </StyledContainer>
  );
}

const StyledContainer = styled(Container)`
  @media screen and (min-width: 1024px) {
    max-width: 24rem;
  }
`;

const ResponsiveBox = styled(Box)`
  width: 24rem;
  margin: 0 auto;
  .field .label {
    padding-top: 16px;
  }

  @media screen and (max-width: 768px) {
    box-shadow: none;
    width: 100%;
  }
`;

const EditButton = styled(Button)`
  margin-top: 12px;
  background-color: ${({ theme }) => theme.yellow};
`;
