import { getSchedule } from 'api/requests/contentsRequests';
import BodyTitle from 'components/BodyTitle';
import UserGuide from 'components/UserGuide';
import dayjs from 'dayjs';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import { useFetch } from 'hooks/useFetch';
import cloneDeep from 'lodash.clonedeep';
import { useMemo, useState } from 'react';
import { Container } from 'react-bulma-components';
import styled from 'styled-components';
import { getWeek } from 'utils/date';
import { setUserGuideToured } from 'utils/user-guide-util';

import { steps } from './guideData';
import WeeklyCalendar from './WeeklyCalendar';

dayjs.extend(weekOfYear);

const MONTHLY_TOURED = '__MONTHLY_TOURED__';

const isScheduleEmpty = ({ list = null }) => {
  if (!list) {
    return true;
  }

  for (let i = 0; i < list.length; i++) {
    for (let j = 0; j < (list[i]?.daily?.length || 0); j++) {
      if (list[i]?.daily[j]?.contents?.length) {
        return false;
      }
    }

    if (list[i]?.weekly?.length) {
      return false;
    }
  }

  return true;
};

export default function MonthlyProgramPage({ history }) {
  const tourFinished = localStorage.getItem(MONTHLY_TOURED) || null;

  const todayYear = dayjs().get('year');
  const todayMonth = dayjs().get('month') + 1;
  const todayDate = dayjs().get('date');
  const [selectedYear, setSelectedYear] = useState(todayYear);
  const [selectedMonth, setSelectedMonth] = useState(todayMonth);
  const [selectedWeek, setSelectedWeek] = useState(getWeek(dayjs()) + 1);

  const params = useMemo(
    () => ({
      year: selectedYear,
      month: selectedMonth,
    }),
    [selectedMonth, selectedYear]
  );
  const prevMonthParams = useMemo(
    () => ({
      year: selectedMonth === 1 ? selectedYear - 1 : selectedYear,
      month: selectedMonth === 1 ? 12 : selectedMonth - 1,
    }),
    [selectedMonth, selectedYear]
  );
  const nextMonthParams = useMemo(
    () => ({
      year: selectedMonth === 12 ? selectedYear + 1 : selectedYear,
      month: selectedMonth === 12 ? 1 : selectedMonth + 1,
    }),
    [selectedMonth, selectedYear]
  );
  const { data, error } = useFetch(getSchedule, params);
  const { data: prevMonthData } = useFetch(getSchedule, prevMonthParams);
  const { data: nextMonthData } = useFetch(getSchedule, nextMonthParams);

  if (error) {
    alert('스케쥴 데이터를 불러오는데 실패하였습니다.');
  }

  const handleFinished = () => {
    history.push('/main');
    setUserGuideToured(MONTHLY_TOURED);
  };
  const handleSkipped = () => {
    setUserGuideToured(MONTHLY_TOURED);
  };
  const prevMonth = () => {
    setSelectedWeek(1);
    if (selectedMonth === 1) {
      setSelectedYear(selectedYear - 1);
      setSelectedMonth(12);
    } else {
      setSelectedMonth(selectedMonth - 1);
    }
  };
  const nextMonth = () => {
    setSelectedWeek(1);
    if (selectedMonth === 12) {
      setSelectedYear(selectedYear + 1);
      setSelectedMonth(1);
    } else {
      setSelectedMonth(selectedMonth + 1);
    }
  };

  const mergedData = cloneDeep(data);

  for (const i of [0, 1, 2, 3, 4]) {
    if (data?.list[0]?.daily[i]?.contents) {
      if (prevMonthData?.list[prevMonthData?.list.length - 1]?.daily[i]?.contents) {
        if (
          data?.list[0].daily[i].date === 0 ||
          prevMonthData.list[prevMonthData.list.length - 1].daily[i].date === 0
        ) {
          mergedData.list[0].daily[i].date =
            data.list[0]?.daily[i].date || prevMonthData.list[prevMonthData.list.length - 1].daily[i].date;
          mergedData.list[0].daily[i].contents = [
            ...data.list[0].daily[i].contents,
            ...prevMonthData.list[prevMonthData.list.length - 1].daily[i].contents,
          ];
        }
      }

      if (nextMonthData?.list[0]?.daily[i]?.contents) {
        if (data.list[data.list.length - 1].daily[i].date === 0 || nextMonthData.list[0].daily[i].date === 0) {
          mergedData.list[data.list.length - 1].daily[i].date =
            data.list[data.list.length - 1]?.daily[i].date || nextMonthData.list[0].daily[i].date;
          mergedData.list[data.list.length - 1].daily[i].contents = [
            ...data.list[data.list.length - 1].daily[i].contents,
            ...nextMonthData.list[0].daily[i].contents,
          ];
        }
      }
    }
  }

  return (
    <>
      <Body breakpoint="desktop" max={true}>
        <BodyTitle title={'월간 프로그램'} paths={['홈', '월간 프로그램']} />
      </Body>
      {!!mergedData?.list && (
        <WeeklyCalendar
          selectedMonth={selectedMonth}
          selectedWeek={selectedWeek}
          todayMonth={todayMonth}
          todayDate={todayDate}
          list={mergedData.list}
          setSelectedWeek={setSelectedWeek}
          dailyContents={mergedData.daily}
          weeklyContents={mergedData.weekly}
          prevMonth={prevMonthData && !isScheduleEmpty(prevMonthData) ? prevMonth : null}
          nextMonth={nextMonthData && !isScheduleEmpty(nextMonthData) ? nextMonth : null}
        />
      )}
      {!tourFinished && <UserGuide steps={steps} onSkipped={handleSkipped} onFinished={handleFinished} />}
    </>
  );
}

const Body = styled(Container)`
  padding-top: 40px;
`;
