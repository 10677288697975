import 'bulma/bulma.sass';
// import './bulma.sass';
import 'aos/dist/aos.css';
import './summernote.scss';

import { AuthProvider } from 'context/AuthContextProvider';
import MainRouter from 'router/MainRouter';

function App() {
  return (
    <AuthProvider>
      <MainRouter />
    </AuthProvider>
  );
}

export default App;
