import AvoidFixedHeaderSection from 'components/AvoidFixedHeaderSection';

import FindPwForm from './components/FindPwForm';

export default function FindPwPage() {
  return (
    <AvoidFixedHeaderSection>
      <FindPwForm />
    </AvoidFixedHeaderSection>
  );
}
