import { requestDeleteComment } from 'api';
import { DeleteButton, EditButton } from 'CommonStyles';
import ERROR_REQUEST from 'constants/errorMessages';
import { CommentsActions } from 'context/CommentsContextProvider';
import dayjs from 'dayjs';
import { useCommentsDispatch } from 'hooks/useCommentsContext';
import { useCallback, useState } from 'react';
import { Block } from 'react-bulma-components';
import styled from 'styled-components';

import CommentForm from './CommentForm';

export default function Comment({
  id,
  index,
  content,
  isDeleted,
  isWriter,
  accountId,
  createdAt,
  comment: replyList = [],
  boardId,
  parentIndex,
  className,
}) {
  const dispatch = useCommentsDispatch();
  const [showReplyForm, setShowReplyForm] = useState(false);
  const [showCommentForm, setShowCommentForm] = useState(false);

  const handleEditButtonClick = useCallback(() => {
    setShowCommentForm(true);
  }, []);

  const handleDeleteButtonClick = useCallback(() => {
    (async () => {
      const { error, requestName } = await requestDeleteComment({ id });
      if (error) {
        console.error(error.response);
        alert(ERROR_REQUEST[requestName][error.response?.status]);
        return;
      }

      window.location.reload();

      dispatch({
        type: CommentsActions.DELETE_COMMENT,
        payload: {
          index,
          parentIndex,
        },
      });
    })();
  }, [id, dispatch, index, parentIndex]);

  const today = new Date();

  return (
    <>
      <CommentWrapper className={className}>
        {isDeleted ? (
          <p>삭제된 댓글입니다.</p>
        ) : showCommentForm ? (
          <CommentForm
            key={new Date()}
            editMode={true}
            id={id}
            parentIndex={parentIndex}
            index={index}
            boardId={boardId}
            onClose={() => {
              setShowCommentForm(false);
            }}
            defaultContent={content}
          />
        ) : (
          <>
            <Author>{accountId}</Author>
            <Content>{content}</Content>
            <Block display="flex" alignItems="center" justifyContent="space-between">
              <More>
                <CreatedAt>
                  {dayjs(createdAt).format('YYYYMMDD') === dayjs(today).format('YYYYMMDD')
                    ? dayjs(createdAt).format('hh:mm')
                    : dayjs(createdAt).format('YYYY-MM-DD')}
                </CreatedAt>
                {boardId && <ReplyButton onClick={() => setShowReplyForm(true)}>대댓글</ReplyButton>}
              </More>
              {isWriter && (
                <Block display="flex">
                  <EditButton type="button" onClick={handleEditButtonClick}>
                    수정
                  </EditButton>
                  <DeleteButton type="button" onClick={handleDeleteButtonClick}>
                    삭제
                  </DeleteButton>
                </Block>
              )}
            </Block>
          </>
        )}
      </CommentWrapper>

      {(showReplyForm || replyList?.length > 0) && (
        <ReplySection>
          {replyList.map((reply, replyIndex) => (
            <Reply key={'reply' + reply.id} {...reply} index={replyIndex} parentIndex={index} />
          ))}
          {showReplyForm && <ReplyForm parentId={id} boardId={boardId} />}
        </ReplySection>
      )}
    </>
  );
}

const CommentWrapper = styled.div`
  padding: 18px;
  border-top: 1px solid #e9e9e9;
`;

const Author = styled.div`
  color: #aaaaaa;
  margin-bottom: 15px;
  font-size: 0.9rem;
`;

const Content = styled.div`
  font-size: 1.05rem;
  margin-bottom: 12px;
  word-break: break-all;
`;

const More = styled.div`
  font-size: 0.8rem;
  color: #bbb;

  & div {
    display: inline-block;
    margin-right: 10px;
  }
`;

const CreatedAt = styled.div``;

const ReplyButton = styled.div`
  cursor: pointer;
`;

const ReplySection = styled.div`
  background: #fafafa;
  padding: 0 0 25px 25px;
`;

const Reply = styled(Comment)`
  padding: 18px 18px 0;
  margin-top: 18px;
  border-top: 1px solid #e9e9e9;

  &:first-child {
    margin-top: 0;
  }
`;

const ReplyForm = styled(CommentForm)`
  position: relative;
  margin: 0 20px 40px;
  padding-top: 20px;
`;
