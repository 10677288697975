import { Route, Switch } from 'react-router-dom';

import TeacherBoard from './pages/TeacherBoard';
import TeacherBoardEdit from './pages/TeacherBoardEdit';
import TeacherBoardWrite from './pages/TeacherBoardWrite';
import TeacherPost from './pages/TeacherPost';

export default function TeacherRoutes(props) {
  return (
    <Switch>
      <Route exact path={`${props.match?.path}/board`}>
        <TeacherBoard />
      </Route>
      <Route exact path={`${props.match?.path}/board/:id`}>
        <TeacherPost />
      </Route>
      <Route exact path={`${props.match?.path}/board-write`}>
        <TeacherBoardWrite />
      </Route>
      <Route exact path={`${props.match?.path}/board-edit/:id`}>
        <TeacherBoardEdit />
      </Route>
    </Switch>
  );
}

// const { Tab } = Tabs;

// const tabDatas = [
//   { label: '연수 프로그램', value: 'study-program' },
//   { label: '외부 강사 신청', value: 'external-teacher-apply' },
//   { label: '대나무숲', value: 'board' },
//   { label: '아이디어 제안', value: 'suggest-idea' },
// ];

// export default function TeacherTabs() {
//   const location = useLocation();
//   const history = useHistory();

//   /**
//    * TODO: activeTab 구현 방법 고민
//    * 1. 라우팅으로 구현 (마찬가지로 대나무숲에서 다른 쿼리스트링 필요할까봐)
//    * 2. 쿼리스트링으로 구현 (현재 구현된 방식)
//    * 3. state로 관리 (이러면 대나무숲 쿼리스트링 구현 시 조금 꼬임)
//    */
//   const { tab } = QueryString.parse(location.search.replace('?', ''));

//   const handleTabClick = useCallback(
//     (value) => {
//       history.push(`/teacher?tab=${value}`);
//     },
//     [history]
//   );

//   return (
//     <AvoidFixedHeaderSection>
//       <Tabs>
//         {tabDatas.map(({ label, value }, index) => (
//           <Tab
//             key={index}
//             active={value === tab}
//             onClick={() => handleTabClick(value)}
//           >
//             {label}
//           </Tab>
//         ))}
//       </Tabs>
//       <TabContentsArea>ㅇㅅㅇ</TabContentsArea>
//     </AvoidFixedHeaderSection>
//   );
// }

// const TabContentsArea = styled.div``;
