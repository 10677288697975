import { Columns } from 'react-bulma-components';
import styled from 'styled-components';

import Content from './Content';

export default function ContentList({ contents, useTitleHighlight = false, type, search, noTag = false }) {
  return (
    <ContentListWrapper>
      {contents.map((item, index) => (
        <Content
          key={index}
          {...item}
          useTitleHighlight={useTitleHighlight}
          type={type}
          search={search}
          noTag={noTag}
        />
      ))}
    </ContentListWrapper>
  );
}

const ContentListWrapper = styled(Columns)`
  padding: 35px 0;
`;
