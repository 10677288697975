import { getEvent } from 'api/requests/eventRequests';
import BodyTitle from 'components/BodyTitle';
import { useFetch } from 'hooks/useFetch';
import { useMemo } from 'react';
import { Container } from 'react-bulma-components';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

export default function EventListPage() {
  const { id } = useParams();
  const params = useMemo(() => ({ id }), [id]);
  const { data = {} } = useFetch(getEvent, params);

  const content = data?.content || '';

  return (
    <EventListPageWrapper breakpoint="desktop" max={true}>
      <BodyTitle paths={['홈', '이벤트']} title={data?.title} />
      <ContentWrapper dangerouslySetInnerHTML={{ __html: content }} />
    </EventListPageWrapper>
  );
}

const EventListPageWrapper = styled(Container)`
  padding-top: 40px;
`;
const ContentWrapper = styled.div`
  margin: 25px auto 160px;
`;
