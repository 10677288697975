import { Box, Container, Content } from 'react-bulma-components';
import styled from 'styled-components';

export default function TermsOfUse() {
  return (
    <Container py={6}>
      <Box>
        <TermsContent>
          <h1>마이돌봄 이용약관</h1>
          <p>본 약관은 2021년 7월 19일부터 적용됩니다.</p>
          <h3 id="1-">1. 총칙</h3>
          <h4 id="-1-">제 1조 목적</h4>
          <p>
            마이돌봄 서비스 약관(이하 “약관”)은 주식회사 한국시청각(이하 “회사”)가 제공하는 동영상 스트리밍
            서비스(웹서비스인 마이돌봄 서비스(이하 “서비스”)를 이용함에 있어 회사와 회원간의 권리, 의무 및 책임사항,
            서비스 이용조건 및 절차 등 기본적인 사항을 규정함을 목적으로 합니다.
          </p>
          <h4 id="-2-">제 2조 설명 및 용어의 정의</h4>
          <p>
            1항. 회사는 무료 및 유료 서비스를 이용하고자 하는 회원이 최초 계약을 체결하기 전 회원에게 마이돌봄 서비스
            구독계약의 성격과 해지 및 환불 조건 등 주요 내용을 이 약관과 별개로 설명합니다.
          </p>
          <p>2항. 이 약관에서 사용되는 용어의 정의는 다음과 같습니다.</p>
          <ol>
            <li>
              회원: 마이돌봄 약관 및 본 약관에 동의하고 계정을 생성한 자로서, 회사가 제공하는 서비스를 통하여 무료로
              제공하는 정보를 지속적으로 이용할 수 있는 자를 말합니다.{' '}
            </li>
            <li>
              유료회원: 별도의 금액을 지불하고 월 정기 이용권 등을 결제하여 유료 서비스를 이용하는 회원을 의미합니다.
            </li>
            <li>
              유료 서비스: 회사가 제공하는 서비스 중 회원 및 기관이 회사에 일정 금액을 결제하거나, 회원이 회사 또는
              제3자와의 거래 내지 약정 조건을 수락하는 경우에 이용할 수 있는 회사의 서비스를 의미합니다. 단, 회사 또는
              제3자로부터 사이트에 연결(링크)된 유료 콘텐츠 제공업체 및 회사와의 계약을 통하여 입점한 제휴 서비스는
              회사의 유료 서비스 이용행위로 보지 않으며, 본 약관을 적용하지 아니합니다.
            </li>
            <li>
              ‘결제’라 함은 회원 및 기관이 유료 서비스를 이용하기 위하여 이 약관 제 7조 각호에서 정한 각종 지불수단을
              통하여 회사가 정한 일정 금액을 회사에 지불하는 것을 의미합니다.
            </li>
            <li>
              ‘콘텐츠’라 함은 「정보통신망이용촉진 및 정보보호 등에 관한 법률」 제2조 제1항 제1호의 규정에 의한
              정보통신망에서 사용되는 부호, 문자, 음성, 음향, 이미지 또는 영상 등으로 표현된 자료 또는 정보입니다.
            </li>
            <li>
              ’유료 서비스 이용계약’이라 함은 마이돌봄의 유료 서비스를 이용하고자 하는 회원의 청약에 따라, 회사는 현재
              제공하고자 하는 디지털 콘텐츠에 대해 월 단위의 접근 권한을 회원에게 부여하고 디지털 콘텐츠에 대한 정보 및
              개인화된 추천, 큐레이션 등을 제공하며, 회원은 그 이용에 대한 대가로 월 단위의 지급 결제를 주요 내용으로
              하는 계약을 말합니다.
            </li>
          </ol>
          <p>
            3항. 이 약관에서 사용하는 용어의 정의는 제2항에서 정하는 것을 제외하고는 관계 법령 및 서비스별 안내에서
            정하는 바에 의합니다.
          </p>
          <h4 id="-3-">제 3조 약관의 효력/변경 등</h4>
          <p>
            1항. 이 약관은 회원이 이 약관에 동의한 날로부터 회원 탈퇴 시까지 적용하는 것을 원칙으로 합니다. 단, 이
            약관의 4조, 5조, 13조, 14조, 17조, 18조, 19조, 20조, 21조, 22조는 회원이 탈퇴 후에도 유효하게 적용될 수
            있습니다.
          </p>
          <p>
            2항. 회사는 필요에 따라 약관을 수정할 수 있으며, 개정 전 약관과 함께 적용일자 7일 전부터 웹사이트에서 확인할
            수 있도록 게시합니다. 다만, 이용자에게 불리하게 약관을 변경하는 경우에는 적용일자 30일 전에 개정내용을
            이용자가 확인할 수 있도록 게시합니다. 회원은 변경된 약관에 동의하지 않을 경우, 회원 탈퇴(해지)를 할 수
            있으며, 회원이 변경된 개정약관의 효력 발생일 이후에도 서비스를 계속 이용할 경우, 개정약관이 적용 됩니다.
          </p>
          <p>3항. 회사는 이 약관을 회원이 그 전부를 인쇄할 수 있고 확인할 수 있도록 필요한 기술적 조치를 취합니다.</p>
          <p>
            4항. 이 약관에 명시되지 아니한 사항에 대하여는 콘텐츠산업진흥법, 전자상거래 등에서의 소비자 보호에 관한
            법률, 약관의 규제에 관한 법률 등 관련 법령에 따릅니다.
          </p>
          <h3 id="2-">2. 유료 서비스 이용 계약</h3>
          <h4 id="-4-">제 4조 유료 서비스 이용계약의 성립 및 승낙의 제한</h4>
          <p>
            1항. 회사가 운영하는 마이돌봄 유료 서비스 대한 이용계약은 이 약관에 동의한다는 의사표시(동의함을 선택)와
            함께 이용 신청을 하고 회사가 이를 승낙함으로써 성립합니다.
          </p>
          <p>
            2항. 회원은 유료 서비스 이용계약을 체결하기 전에 해당 유료 서비스에 대하여 이 약관에서 회사가 명시, 고지하는
            사항을 숙지하고, 착오 없이 정확히 거래할 수 있도록 하여야 합니다.
          </p>
          <p>
            3항. 회사와 회원간 유료 서비스 이용계약은 회사의 승낙이 있는 때(유료 서비스의 “구매/결제완료 등”의 표시가
            회원에게 절차상 표시된 시점)에 성립합니다.
          </p>
          <p>
            4항. 회사는 다음 각 호에 해당하는 경우, 이용신청을 승낙하지 아니하거나 소정의 조건 성취 또는 제한 사유가
            해소될 때까지 일정 기간 동안 승낙을 유보할 수 있습니다.
          </p>
          <ol>
            <li>가입신청자가 이 약관에 의하여 이전에 회원자격을 상실한 적이 있는 경우</li>
            <li>실명이 아니거나 타인의 명의를 이용한 경우</li>
            <li>허위의 정보를 기재하거나, ‘회사’가 제시하는 내용을 기재하지 않은 경우</li>
            <li>
              사에 서비스 이용료 등을 납부하지 아니하여 이용계약이 해지된 자가 체납된 이용료를 납부하지 아니한 경우
            </li>
            <li>회원의 개인 신용등급 및 타 서비스 요금 연체, 미납 등의 이유로 정상적인 결제가 이뤄지지 못하는 경우</li>
            <li>공공의 안녕질서 및 미풍양속을 해할 우려가 있는 경우</li>
            <li>설비에 여유가 없거나 기술상 ‘서비스’ 제공이 어려운 경우</li>
            <li>‘이용자’의 귀책사유로 인하여 승인이 불가능하거나 기타 규정한 제반 사항을 위반하며 신청하는 경우</li>
            <li>기타 ‘회사’가 ‘서비스’ 제공상 필요하다고 인정하는 경우</li>
          </ol>
          <p>
            5항. 회원은 무료 및 유료 서비스 이용 신청 시 필요한 제반 사항을 기재할 경우 회원의 현재의 사실과 일치하는
            정보를 기재하여야 하며, 회원이 이용하고자 하는 유료 서비스의 결제방법의 선택 및 선택한 결제방법에 필요한
            결제정보를 정확히 회사에 제공하여야 하며, 해당 사항이 변경될 경우 지체 없이 회사가 정한 절차에 따라
            변경사항을 고지, 반영하여야 합니다.
          </p>
          <p>
            6항. 회사는 회원 가입에 필요한 기재사항 이외에 회원의 서비스 이용에 필요한 최소한의 정보를 수집할 수
            있습니다. 이를 위해 회사가 문의한 사항에 대해 회원은 성실하게 고지합니다. 회사는 이 경우 수집하는 회원의
            정보를 본 약관, 개인정보취급방침 및 「정보통신망의 이용촉진 및 정보보호 등에 관한 법률」이 정한 바에 따라
            이용, 관리합니다.
          </p>
          <h4 id="-5-">제 5조 (프로그램 설치 및 데이터 수집)</h4>
          <p>
            1항. 회사는 무료 및 유료 서비스를 제공함에 있어 필요한 경우 회원에게 어플리케이션 및 별도의 프로그램을 설치
            및 업데이트하도록 안내할 수 있으며, 회원은 원하지 않을 경우 그 설치 및 업데이트를 거부할 수 있습니다. 다만,
            회원이 어플리케이션 및 별도 프로그램의 설치 및 업데이트를 거부하는 경우 서비스 이용이 제한될 수 있습니다.
          </p>
          <p>2항. 회사는 회원의 서비스 이용 단말기의 데이터를 수집하고 이용할 수 있습니다.</p>
          <p>
            3항. 회사는 제2항의 데이터를 서비스를 개선하거나 이용자의 사용환경에 적합한 서비스 또는 기술을 제공하기 위한
            목적으로만 사용하며 그 외의 다른 목적으로 사용하지 않습니다.
          </p>
          <h4 id="-6-">제 6조 서비스 이용의 기술적 요구 사항</h4>
          <p>
            1항. 회사는 서비스를 공급하면서 다음 각 호의 사양이 충족되어야 정상적인 서비스 이용이 가능하며, 회원이
            아래의 필수 사양을 충족하지 못할 경우 서비스 이용이 제한될 수 있습니다.
          </p>
          <h5 id="-">&lt;서비스 이용 필수 사양&gt;</h5>
          <ol>
            <li>
              컴퓨터(PC) 서비스는 아래 열거한 웹브라우저 이용이 가능한 Windows, macOS, Linux 등의 OS에서 동작합니다.
              <ul>
                <li>지원 웹브라우저: Chrome, Edge, Firefox (상기 브라우저의 최신 정식 버전에서 가장 잘 동작합니다)</li>
              </ul>
            </li>
            <li>
              모바일 단말기(스마트폰, 태블릿 PC) 서비스는 가장 최신의 정식 버전 OS에서 가장 잘 동작하며, 최소 지원 OS
              버전 정보는 다음과 같습니다.
              <ul>
                <li>Android: 4.4 버전 이상</li>
                <li>Android TV: 5.0 버전 이상(일부 기기는 제조사 사정에 따라 지원이 제외될 수 있습니다)</li>
                <li>iOS: iOS 11.0 버전 이상</li>
              </ul>
            </li>
          </ol>
          <p>
            2항. 서비스의 화질은 회원이 결제한 이용권, 사용하는 디바이스, 인터넷 접속 속도, 인터넷 대역폭 등 다양한
            요인의 영향을 받기 때문에 모든 콘텐츠가 언제나 HD, Full HD, Ultra HD, HDR 등의 화질로 제공되는 것은
            아닙니다.
          </p>
          <h3 id="3-">3. 유료 서비스 이용</h3>
          <h4 id="-7-">제 7조 유료 서비스와 유료회원</h4>
          <p>
            1항. 회사가 유료회원의 이용신청을 승낙한 때(일부 서비스의 경우, 이용 신청 접수 후 사전 공지한 지정된
            일자)로부터 유료 서비스는 개시되며, 회사의 기술적 사유 등 기타 사정에 의하여 유료 서비스를 개시할 수 없는
            경우에는 제3조(약관의 효력/변경 등)의 방법에 따라 회원에게 사전 공지합니다.
          </p>
          <p>
            2항. 회사는 회원의 유료 서비스 이용신청이 있게 되는 경우, 해당 회원에게 이용신청의 내용을 통지합니다. 회사의
            통지를 받은 회원은 의사표시의 불일치 등이 있는 경우 회사에 정정 또는 수정을 요청하여야 하며, 회사는 회원의
            요청에 따라 처리하여야 합니다. 다만, 이미 대금을 지불한 경우에는 이 약관의 청약 철회 등에 관한 규정에
            따릅니다.
          </p>
          <p>
            3항. 회사는 회원이 유료 서비스 이용시 서비스 체험을 위해 무료체험 기간이 포함된 유료서비스 상품을 회원에게
            제안할 수 있습니다.{' '}
          </p>
          <p>
            4항. 회사는 다음 각 호와 같은 유료 서비스를 제공하며, 회사의 사정, 기타 제반 여건에 따라 서비스 내용을
            추가하거나 변경할 수 있습니다.
          </p>
          <ol>
            <li>기간만료형 이용권: 이용 가능 기간이 만료되는 경우, 유료 서비스의 이용이 종료되는 서비스</li>
            <li>
              판매방식에 따른 분류
              <ul>
                <li>
                  직접판매: 회사가 제공하는 유료 서비스를 회원에게 결제 수단을 통해 직접 제공하는 방식을 말합니다.
                </li>
                <li>
                  대행판매: 회사가 제공하는 유료 서비스를 제3자를 통해 판매하는 방식으로 해당 판매상품의 이용방식은
                  제휴사가 제공하는 유료 서비스 안내에 따릅니다.
                </li>
              </ul>
            </li>
          </ol>
          <p>
            5항. 유료회원이 유/무선 네트워크를 통하여 서비스에 접속하거나 유/무선 네트워크가 연결된 상태의 기기 내에
            탑재된 웹을 통하여 제반 서비스를 이용하는 경우, 회원과 회원이 가입한 해당 통신사간에 체결된 통신 요금제에
            의한 별도의 데이터 통화료가 발생합니다. 이 경우 발생하는 데이터 통화료는 회원과 해당 통신사간에 체결된
            통신요금제에 따라 과금/청구/수납되므로, 데이터 통화료에 대해서는 회사는 어떠한 책임도 지지 않습니다.
          </p>
          <p>
            6항. 콘텐츠(교육 영상, TV 프로그램 등)의 권리를 보유하고 있는 개인, 단체, 법인(‘권리자 등’) 간의 계약의 체결
            여부 및 개별 계약의 내용, ‘권리자 등’의 사후 요청에 따라 콘텐츠의 이용이 제한되거나 변경될 수 있습니다.
            회사는 ‘권리자 등’의 요청이 있게 되는 경우, 해당 요청에 따라 유료 서비스를 지원하는 기기의 종류, 동시 재생
            회선 수 등을 변경할 수 있습니다.
          </p>
          <h4 id="-9-">제 9조 결제수단</h4>
          <ul>
            <li>
              <p>회원이 유료 서비스의 결제를 위하여 이용할 수 있는 수단은 다음 각 호와 같습니다.</p>
              <ol>
                <li>신용카드</li>
                <li>학교장터</li>
                <li>
                  정보통신망 이용촉진 및 정보보호 등에 관한 법률에 의한 통신과금서비스
                  <ul>
                    <li>서비스 요금이 회사와 제휴한 통신사의 통신요금과 합산하여 청구됩니다.</li>
                  </ul>
                </li>
                <li>전자지급결제대행업을 영위하는 제3자를 통한 결제 서비스</li>
              </ol>
            </li>
          </ul>
          <h4 id="-10-">제 10조 서비스 지역</h4>
          <ul>
            <li>본 서비스는 대한민국에서만 이용 가능합니다.</li>
          </ul>
          <h4 id="-11-b2b-">제 11조 B2B 거래</h4>
          <p>1항. B2B 거래란 특정 법인에게 마이돌봄 유료 서비스를 대량으로 판매하는 거래를 말합니다.</p>
          <p>2항. B2B 거래 시 거래량 및 해당 이용권의 용도에 따라 일정 수준의 할인율을 적용할 수 있습니다.</p>
          <h3 id="4-">4. 유료 서비스의 변경, 청약 철회, 해지, 정지 및 중단</h3>
          <h4 id="-12-">제 12조 유료 서비스 가액 변경</h4>
          <p>
            회사가 운영상, 기술상의 필요에 따라 공급하는 유료 서비스 가액을 인상할 경우, 인상된 가액을 적용 받는
            회원에게 동의를 받습니다. 회원이 해당 변경에 동의하지 않는 경우 서비스 이용 승낙이 일시적으로 유보되거나
            이용계약이 해지될 수 있습니다.
          </p>
          <h4 id="-13-">제 13조 유료서비스 예약 변경, 즉시 변경 등</h4>
          <p>
            1항. 회원이 이미 구매/가입한 유료 서비스는 회사가 정한 정책에 따라 변경신청할 경우 즉시 변경 혹은 1개월 예약
            변경을 할 수 있습니다.
          </p>
          <p>
            2항. 즉시 변경의 경우, 회원이 변경 요청한 이용권으로 즉시 교체되며, 기존 구매/가입한 이용권의 남은 기간과
            변경 요청한 이용권의 가격을 비례배분하여 이용 기간이 조정되며 새로운 결제주기가 적용 됩니다.
          </p>
          <p>
            3항. 예약 변경의 경우, 회원이 변경 요청한 달의 이용기간이 만료되는 날까지 기존 구매/가입한 이용권을 이용할
            수 있으며, 이용기간 만료일 익일부터 변경 요청한 이용권으로 변경 적용됩니다.
          </p>
          <p>
            4항. 타사 계정 혹은 제휴를 통해 유료 서비스의 이용권을 구매하여 사용하는 경우 해당 타사의 정책을 따릅니다.
          </p>
          <h4 id="-14-">제 14조 (서비스 이용계약의 청약철회, 해제⋅해지 및 환불)</h4>
          <p>
            1항. 회원의 변심 등 회원의 귀책 사유로 청약철회, 해제⋅해지하는 경우, 회원은 이용내역이 없으면 최초
            결제일부터 7일 이내에 청약철회할 수 있고 다음 결제주기부터는 결제일로부터 7일 이내에 계약을 해제⋅해지할 수
            있습니다. 이 경우 회사는 청약철회를 제외하고는 결제수수료 등 부대비용을 제외하고 전액 환불합니다. 다만,
            결제주기 내 계약의 해제⋅해지는 서비스 이용내역이 있는 경우 또는 정상적인 이용이 가능한 상태에서 결제일로부터
            7일이 도과한 경우 제한됩니다.{' '}
          </p>
          <p>
            2항. 회사가 안내한 방법으로 회원이 이용을 시도하였음에도 정상적인 이용이 불가능한 경우 등 약정한
            “유료서비스&quot;가 정상적으로 제공되지 않음이 확인된 경우 또는 시스템 장애 등 회사의 귀책사유로
            해제⋅해지하는 경우 잔여일수에 해당하는 대금을 회원에게 환급하고 동금액의 10%를 위약금(회사의 귀책사유가 아닌
            경우 위약금 제외)으로 지급합니다.
          </p>
          <p>
            3항. 1개월을 초과하는 장기 이용권의 경우에도 제1항 및 제2항을 적용하되, 월정액 요금을 기준으로 이용대금을
            산정하여 이용요금을 공제하고, 환불할 금액 및 위약금은 잔여 기간(월단위 기준)에 대하여 월정액 요금을 기준으로
            상호 산정하여 공제합니다.
          </p>
          <p>
            4항. 청약철회, 계약의 해제⋅해지는 유료회원이 서비스 홈페이지 등 회사가 인정한 방법을 통해 의사표시를 할 수
            있으며, 회사에 의사를 표시하여 회사에 도달될 때 그 효력이 발생하고, 회사는 유료회원의 의사표시를 수령한 후
            지체 없이 이러한 사실을 유료회원에게 회신합니다.
          </p>
          <p>
            5항. 회사는 유료회원의 청약 철회, 해지・해제 시 환불 금액이 있을 경우, 원칙적으로 유료회원의 해당 의사표시를
            수령한 날로부터 3영업일 이내에 결제수단 별 사업자에게 대금의 청구 정지 내지 취소를 요청하고, 유료회원이
            결제한 동일 결제수단으로 환불함을 원칙으로 합니다. 단, 회사가 사전에 유료회원에게 전자 메일, 서비스
            홈페이지로 공지한 경우 및 아래의 각 경우와 같이 개별 결제 수단별 환불 방법, 환불 가능 기간 등이 차이가 있을
            수 있습니다.
          </p>
          <ol>
            <li>신용카드 등 수납 확인이 필요한 결제수단의 경우에는 수납 확인일로부터 3영업일 이내</li>
            <li>
              결제수단 별 사업자가 회사와의 약정을 통하여 사전에 대금 청구 정지 내지 결제 취소 가능 기한 등을 미리
              정하여 둔 경우로 해당 기한을 지난 환불의 경우
            </li>
            <li>
              유료회원이 환불 처리에 필요한 정보 내지 자료를 회사에 즉시 제공하지 않는 경우(현금 환불 시 신청인의 계좌
              및 신분증 사본을 제출하지 아니하거나, 타인 명의의 계좌를 제공하는 경우 등)
            </li>
            <li>해당 회원의 명시적 의사표시가 있는 경우</li>
          </ol>
          <p>
            6항. 유료 서비스 결제자와 이용자가 다른 경우의 환불은, 해당 유료 서비스 결제자가 이용요금을 결제한 동일
            결제수단으로 환불함을 원칙으로 합니다.
          </p>
          <p>
            7항. 회사는 「콘텐츠산업 진흥법」제28조에 따라 제정된 「콘텐츠이용자보호지침」 등에 따라, 회사가
            부담하였거나 부담할 부대비용, 수수료를 공제하여 환불할 수 있습니다.
          </p>
          <p>
            8항. 프로모션 등을 통해 무료/무상으로 취득하는 등 회원이 직접 비용을 지불하지 아니하고 이용하는 유료
            서비스에 대하여 회사는 회원에게 유료 서비스 결제 대금을 환불할 의무를 부담하지 않습니다.
          </p>
          <p>
            9항. 회원이 이 약관에서 정하는 회원의 의무를 위반하였을 경우, 이 약관 제19조에서 정한 절차를 거쳐 계약을
            해제, 해지하거나 서비스 이용 제한, 손해배상 청구 등의 조치를 취할 수 있으며, 계약 해지 후 환불하여야 할
            금액이 있을 경우에는 일정한 범위 내에서 회원이 부담할 금액을 공제하고 환불합니다. 이 경우 회원은 해당 회사의
            조치에 대하여 회사가 정한 절차에 따라 이의 신청을 할 수 있으며, 회사는 정당하다고 확인하는 경우 서비스 이용
            재개 등을 할 수 있으며, 이용자가 자신의 고의, 과실 없었음을 입증한 경우 회사는 서비스를 정지한 기간만큼 이용
            기간을 연장합니다.
          </p>
          <p>
            10항. 유료 서비스를 이용 중인 유료회원이 탈퇴하는 경우, 해당 이용권은 더 이상 사용할 수 없으며, 탈퇴한
            회원이 이용요금 환불을 요청할 경우 이 약관에서 정한 기준을 따릅니다. 이 경우 유료회원의 정보와 마이돌봄
            이용내용은 관련 법령이 정하는 경우를 제외하고는 본 약관에 따라 처리됩니다. 다만 타사 계정을 결제 수단으로
            사용하여 구독한 경우, 해당 타사를 통해서 해지해야 할 수도 있습니다.
          </p>
          <h4 id="-15-">제 15조 과오금</h4>
          <p>
            1항. 회사는 유료 서비스 결제와 관련하여 과오금이 발생한 경우 이용대금의 결제와 동일한 방법으로 과오금 전액을
            환불합니다. 다만, 동일한 방법으로 환불이 불가능할 때는 이를 사전에 고지합니다.
          </p>
          <p>
            2항. 회사의 귀책사유로 과오금이 발생한 경우 과오금 전액을 환불합니다. 단, 회원의 귀책사유로 과오금이 발생한
            경우, 회사가 과오금을 환불하는데 소요되는 비용은 합리적인 범위 내에서 이용자가 부담하여야 하며, 회사는 해당
            비용을 차감 후 과오금을 환불할 수 있습니다.
          </p>
          <p>
            3항. 회사는 회원이 과오금을 주장하며 환불을 요구하는 경우, 정당하게 유료서비스 요금이 부과되었음을 입증하면
            환불을 거부할 수 있습니다.
          </p>
          <p>
            4항. 회사는 과오금의 세부 환불절차 및 기타 사항에 대하여 다음과 같이 「콘텐츠이용자보호지침」이 정하는 바에
            따릅니다.
          </p>
          <ol>
            <li>
              회사나 회원이 과오금의 발생사실을 안 때에는 전자우편, 서비스 홈페이지 등 회사가 제시한 방법에 따라
              상대방에게 통보
            </li>
            <li>회사는 회원에게 환불에 필요한 정보를 요청(회원 성명, 결제증빙서류, 전화번호, 환불 요청 계좌 등)</li>
            <li>회원은 환불에 필요한 2.호의 정보를 회사에 제공</li>
            <li>
              회사는 이용자의 정보 제공일로부터 7일 이내 환불 처리 (회원의 명시적 의사표시가 있는 경우 차회 요금에서
              상계)
            </li>
          </ol>
          <h4 id="-16-">제 16조 유료 서비스의 정지, 중단</h4>
          <p>1항. 회사는 원칙적으로 연중무휴 1일 24시간 유료 서비스를 제공합니다.</p>
          <p>
            2항. 회사는 이용자에 대한 서비스 개선을 목적으로 하는 설비 점검 및 보수 시에는 서비스 전부 또는 일부의
            제공을 제한, 중지, 중단할 수 있습니다. 이 경우 회사는 가능한 한 그 중단 사유, 중단 기간 등을 이 약관
            제3조(약관의 효력/변경 등)의 방법을 통하여 사전에 회원에게 공지하며, 다음 각 호의 경우에는 경위와 원인이
            확인된 즉시 사후에 공지할 수 있습니다.
          </p>
          <ol>
            <li>회원, 기타 불특정 제3자의 불법, 범죄행위로 인하여 기술적으로 정상적인 서비스의 제공이 어려운 경우</li>
            <li>
              시스템 또는 기타 서비스 설비의 장애, 유무선 네트워크 장애 또는 유료 서비스 이용의 폭주 등으로 정상적인
              유료 서비스 제공이 불가능할 경우
            </li>
            <li>기타 천재지변, 국가비상사태, 정전 등 회사가 통제할 수 없는 불가항력적 사유로 인한 경우</li>
          </ol>
          <p>
            3항. 회사는 제2항 각 호의 사유가 아닌 회사의 귀책사유로 서비스의 제공이 중단됨으로 인하여 회원이 입은 손해에
            대하여 아래와 같이 배상합니다. 다만, 천재지변 등 불가항력으로 인한 경우는 아래 이용중지 또는 장애발생 시간에
            산입하지 아니합니다. 또한, 각 호를 적용함에 있어 사전고지는 서비스 중지, 장애시점을 기준으로 3시간 이전에
            고지된 것에 한합니다.
          </p>
          <ol>
            <li>
              사업자의 책임 있는 사유로 인한 서비스 중지 또는 장애의 경우 : 서비스 중지•장애시간만큼 무료로 서비스
              이용기간 연장
            </li>
            <li>
              사업자가 서비스의 중지•장애에 대하여 사전고지한 경우에 있어서 이용자의 피해구제 등은 다음 각호에 의합니다.
              다만, 서비스 개선을 목적으로 한 설비 점검 및 보수시 1개월을 기준으로 최대 24시간은 중지•장애 시간에
              포함하지 아니합니다. ㄱ. 1개월을 기준으로 서비스 중지•장애시간이 24시간을 초과하는 경우 : 24시간과 이를
              초과한 시간의 2배의 시간만큼 이용기간을 무료로 연장 ㄴ. 1개월을 기준으로 서비스 중지•장애시간이 24시간을
              초과하지 않은 경우 : 중지•장애 시간에 해당하는 시간을 무료로 연장
            </li>
          </ol>
          <p>
            4항. 회사는 무료로 제공되는 서비스의 일부 또는 전부를 회사의 정책, 운영상의 긴요한 사유로 수정, 중단, 변경할
            수 있으며, 이에 대하여 관련 법령에 별도 규정이 있지 아니하는 한 별도의 보상을 하지 않습니다.
          </p>
          <h4 id="-17-">제 17조 정보의 제공 및 광고의 게재</h4>
          <p>
            1항. 회사는 회원의 사전 동의를 얻어 서비스 이용 중 필요하다고 인정되는 다양한 정보를 공지사항이나 전자우편
            등의 방법으로 제공할 수 있습니다. 다만, 회사의 서비스를 이용한지 6개월이 경과하지 아니한 회원의 경우 회원의
            사전동의가 없더라도 회사는 서비스 홈페이지, 전자우편 등의 방법으로 다양한 정보를 제공할 수 있습니다. 회원은
            언제든지 회사에게 전자우편 등을 통하여 수신거절을 할 수 있습니다.
          </p>
          <p>
            2항. 회사는 게재된 광고를 통한 광고주의 판촉활동에 회원이 참여하거나 교신 또는 거래의 결과로서 발생하는 손실
            또는 손해에 대해 책임을 지지 않습니다.
          </p>
          <h4 id="-18-">제 18조 개인정보보호</h4>
          <p>1항. 회사는 회원의 서비스 이용에 필요한 최소한의 정보를 수집할 수 있습니다.</p>
          <p>
            2항. 회사가 회원의 개인 식별이 가능한 개인정보를 수집하는 때에는 다음 각호의 모든 사항을 알리고 당해 회원의
            동의를 받습니다.
          </p>
          <ol>
            <li>개인정보의 수집, 이용 목적</li>
            <li>수집하는 개인정보의 항목</li>
            <li>개인정보의 보유, 이용 기간</li>
          </ol>
          <p>
            3항. 회사는 서비스 제공과 관련하여 취득한 회원의 정보를 당해 회원의 동의 없이 목적 외로 이용하거나 제3자에게
            제공할 수 없습니다. 다만, 다음 각 호의 경우에는 그러하지 않습니다.
          </p>
          <ol>
            <li>관계법령에 의한 수사상의 목적으로 관계기관으로부터 요구 받는 경우</li>
            <li>통계작성, 학술연구 또는 시장조사를 위하여 특정 개인을 식별할 수 없는 형태로 제공하는 경우</li>
            <li>
              원활한 서비스를 제공하고 회원의 편의를 증진하기 위하여 회사의 위임을 받은 관계사 및 업무위탁계약을 체결한
              업무수탁자에게 제공하는 경우. 단, 이 경우 회사는 개인정보 취급위탁을 받는 자와 취급을 위탁하는 업무의
              내용에 대하여 개인정보취급방침을 통해 공개하거나 전자우편 등을 통해 회원에게 고지합니다.
            </li>
            <li>서비스 제공에 따른 요금정산을 위하여 필요한 경우</li>
            <li>도용방지를 위하여 본인 확인에 필요한 경우</li>
            <li>다른 법률에 특별한 규정이 있는 경우</li>
          </ol>
          <p>4항. 이용자는 제2항 또는 제3항의 동의 요청을 거절할 수 있습니다.</p>
          <p>5항. 회원 본인의 가족 구성원은 서비스 이용 과정에서 회원 본인의 개인정보를 볼 수 있습니다.</p>
          <p>
            6항. 회사는 정보통신망이용촉진 및 정보보호에 관한 법률 등 관계 법령이 정하는 바에 따라 이용자의 개인정보를
            보호하기 위해 노력합니다. 개인정보의 보호 및 사용에 대해서는 관련법령 및 ‘회사’의 개인정보보호정책이
            적용됩니다.
          </p>
          <h3 id="5-">5. 계약당사자의 의무, 손해배상 등</h3>
          <h4 id="-19-">제 19조 회원의 의무, 위반시 회사의 조치 등</h4>
          <p>
            1항. 회원은 회사가 제공하는 서비스 이용 시 관계법령, 약관, 세부이용지침, 서비스 이용안내 및 사이트 내 공지한
            주의사항, 회사가 서비스 이용과 관련하여 회원에게 통지하는 사항 등을 준수하여야 하며, 기타 회사 및 타인의
            명예를 훼손하거나 서비스 운영 기타 업무수행에 지장을 초래하는 행위를 해서는 안됩니다.
          </p>
          <p>
            2항. 회원은 아이디 및 비밀번호를 관리할 책임이 있으며, 회원 본인과 본인의 가족구성원이 아닌 제 3자가
            사용하게 하여서는 안됩니다. 이를 위반하여 회원의 개인정보를 타인이 사용하여 서비스를 이용함으로써 발생한
            결과에 대한 책임은 회원에게 있습니다. 회원은 타인의 명의, 아이디, 비밀번호, 휴대폰 번호, 계좌번호,
            신용카드번호 등 개인정보를 도용하거나 부정하게 사용하여서는 안됩니다.
          </p>
          <p>
            3항. 회원은 회사가 사전에 허용한 경우를 제외하고는 영업활동 등 영리목적으로 서비스를 이용하거나 이 약관에서
            금지하는 행위를 하거나 허용하는 범위를 벗어난 이용행위를 하여서는 안됩니다.
          </p>
          <p>
            4항. 회원은 서비스 이용과정에서 위법행위 또는 선량한 풍속 기타 사회질서에 반하는 행위를 하여서는 안됩니다.
          </p>
          <p>
            5항. 회원은 서비스를 이용하며 얻은 정보를 회사의 사전 승낙 없이 서비스의 이용 이외의 목적으로 사용하여서는
            안됩니다.
          </p>
          <p>
            6항. 회원은 서비스를 결제하여 콘텐츠를 최종소비자의 지위에서 이용하여야 하고, 영리를 목적으로 하는 영업장,
            매장 등에서 재생 등의 방법으로 사용할 수 없습니다. 또한 서비스 내의 콘텐츠를 불법적으로 복제, 유포,
            공유하여서는 아니 되며, 이를 위반하여 발생한 제반 문제에 대한 책임은 회원에게 있습니다(이 경우, 국내외
            제3자의 저작권 등을 침해하는 행위로서 회사가 IP 접속 차단 등 기술적인 조치를 통하여 타인에 대한 권리 침해
            방지 조치를 취하였음에도 불구하고 이용자가 고의로 또는 회사를 기망하는 수단과 방법을 통하여 마이돌봄에
            접속하는 등 제3자의 저작권 등을 침해하는 행위를 포함합니다).
          </p>
          <p>
            7항. 회사는 제1항 내지 제6항에 해당하는 회원의 위반 행위가 있는 경우, 다음 각호에서 정한 기준에 따라 회원의
            서비스 이용을 정지하거나, 이용계약의 해제 또는 해지, 회원 자격의 상실 등의 조치를 취할 수 있습니다.
          </p>
          <ol>
            <li>
              제3의 이용자들의 피해를 방지하거나, 범죄의 위험성 등을 차단하기 위하여 필요하다고 판단되는 경우, 위반
              행위가 발견된 즉시 회사는 회원의 서비스 이용을 정지할 수 있습니다.
            </li>
            <li>
              해당 회원이 동일한 위반 행위를 2회 이상 반복하거나 30일 이내에 위반행위가 시정되지 않을 경우 회사는 회원의
              이용계약을 해지 또는 해제하거나, 회원의 자격을 상실시키는 등의 조치를 취할 수 있습니다.
            </li>
            <li>
              회사가 회원의 서비스 이용을 정지시키거나, 이용계약을 해제 또는 해지하는 조치를 취할 시 회사는 이 사실을
              즉시 해당 회원에게 통지하며, 회원은 안내받은 절차에 따라 회사에 이의신청을 할 수 있습니다.
            </li>
            <li>
              회사는 회원의 자격을 상실시키는 조치를 취하기 전에 회원에게 충분한 기간을 정하여 소명할 기회를 부여합니다.
            </li>
            <li>
              회원이 자신의 고의, 과실 없음을 입증하거나 회원의 이의가 정당하다고 회사가 인정하는 경우 회사가 취한
              조치는 즉시 철회되며 회원의 서비스 이용권한이 회복됩니다. 이때 서비스 이용이 정지됐던 기간을 고려하여 이용
              기간이 소급 적용됩니다.
            </li>
            <li>
              회사의 고의 또는 과실로 회원의 이용을 부당하게 제한한 것으로 증명된 경우에는 회원은 정지된 이용기간에
              대하여 회사에 이용요금 환불을 요청할 수 있습니다.
            </li>
          </ol>
          <p>
            8항. 서비스 이용과 관련하여 진실한 정보를 입력하지 않은 회원은 법적인 보호를 받을 수 없으며, 서비스 이용에
            제한을 받을 수 있습니다.
          </p>
          <h4 id="-20-">제 20조 손해배상</h4>
          <p>
            1항. 회사는 서비스의 결함에 의하여 유료회원에게 손해가 발생한 경우 회사는 유료회원에게 실제 발생한 손해만을
            배상합니다. 다만, 회사의 고의 또는 과실 없이 유료회원에게 발생한 일체의 손해에 대하여는 책임을 지지
            아니합니다. 회사는 이 약관에서 명시되지 아니한 회사의 귀책사유로 인하여 유료 서비스의 이용 회원에게 손해가
            발생한 경우 회사의 배상 책임과 관련하여 회사는 「콘텐츠이용자보호지침」의 관련 규정 및 기타 상관례를
            따릅니다.
          </p>
          <p>
            2항. 회사는 회원이 서비스와 관련하여 게재 또는 전송한 정보 및 자료의 사실성, 신뢰도, 정확성 등에 관하여는
            책임을 지지 않습니다.
          </p>
          <p>
            3항. 회사는 회원이 서비스를 이용하는 과정에서 이 약관 또는 법령에 위반한 행위로 발생한 개인적인 비용이나
            손해에 대해서는 책임을 지지 않습니다.
          </p>
          <p>
            4항. 회사에 제3자의 해킹, 바이러스 유통 등 불법행위로 인한 사고 발생시, 그 사고에 관하여 회원의 고의 또는
            중대한 과실이 있거나, 회사가 그 사고를 방지하기 위하여 보안절차를 수립하고 이를 철저히 준수하는 등
            관련법령에서 요구되는 주의의무를 다한 경우에 회사는 책임을 지지 않습니다.
          </p>
          <p>
            5항. 회사는 회사에 귀책사유가 없는 서비스 장애, 전송 또는 게시의 실패, 서비스 이용과정에서 발생하는 데이터
            오류, 생략, 누락, 상실, 파괴, 별진 등에 대하여 책임을 지지 않습니다.
          </p>
          <p>
            6항. 회사는 무료로 제공되는 서비스의 일부 또는 전부를 회사의 정책, 운영상의 긴요한 사유로 수정, 중단, 변경할
            수 있으며, 이에 대하여 관련 법령에 별도 규정이 있지 아니하는 한 별도의 보상을 하지 않습니다.
          </p>
          <p>
            7항. 회원이 이 약관상의 의무를 위반함으로 인하여 회사에 손해가 발생한 경우 또는 유료회원이 유료 서비스를
            이용함에 있어 회사에 손해를 입힌 경우, 해당 회원은 회사에 그 손해를 배상하여야 합니다.
          </p>
          <h4 id="-21-">제 21조 면책</h4>
          <p>
            1항. 회사는 다음 각 호의 사유로 인하여 회원 또는 제3자에게 발생한 손해에 대하여는 그 책임을 지지 아니합니다.
          </p>
          <ol>
            <li>천재지변 또는 이에 준하는 불가항력으로 인해 서비스를 제공할 수 없는 경우</li>
            <li>회원이 자신의 아이디 또는 비밀번호 등의 관리를 소홀히 한 경우</li>
            <li>회사의 관리영역이 아닌 공중통신선로의 장애로 서비스 이용이 불가능한 경우</li>
            <li>기타 회사의 귀책사유가 없는 통신 서비스 등의 장애로 인한 경우</li>
          </ol>
          <p>
            2항. 회사는 회원이 서비스를 이용하여 기대하는 효용을 얻지 못하거나 상실한 것, 서비스에 게시된 게시물에 대한
            취사 선택 또는 이용으로 발생하는 결과 등에 대해서는 책임을 지지 않습니다. 또한 회사는 회원이 사이트에 게재한
            리뷰, 평점 등 게시물의 정확성 등의 내용에 대하여는 책임을 지지 않습니다.
          </p>
          <p>
            3항. 회사는 회원 상호간 또는 회원과 제3자 상호간에 서비스와 관련하여 발생한 분쟁에 대하여 개입할 의무가
            없으며, 회사에 귀책사유가 없는 한 이로 인하여 발생한 손해를 배상할 책임이 없습니다.
          </p>
          <h4 id="-22-">제 22조 분쟁의 해결</h4>
          <p>
            1항. 서비스 이용과 관련하여 회사와 회원 사이에 분쟁이 발생한 경우, 회사와 회원은 분쟁의 해결을 위해 성실히
            협의하고, 협의가 안될 경우 콘텐츠산업진흥법 상 콘텐츠분쟁 조정 위원회에 분쟁조정을 신청할 수 있습니다.
          </p>
          <p>
            2항. 전항에 따라 분쟁이 해결되지 않을 경우 양 당사자는 소를 제기할 수 있으며, 회사와 회원 간의 소의 관할은
            민사소송법에 따라 정합니다.
          </p>
        </TermsContent>
      </Box>
    </Container>
  );
}

const TermsContent = styled(Content)`
  li {
    padding-bottom: 8px;
  }
  p {
    padding-left: 8px;
    line-height: 1.4;
  }
`;
