import BorderBottomHeading from 'pages/LandingPage/components/BorderBottomHeading';
import { Columns, Container, Section } from 'react-bulma-components';
import styled from 'styled-components';
import { getAosAnimation } from 'utils/animation-util';

const { Column } = Columns;
const aosAnimation = getAosAnimation('fade-up', 600, 'ease', 300);

export default function HalfSection({
  height = 'auto',
  backgroundColor = '#ffffff',
  heading,
  left = null,
  right = null,
  reverse = false,
  background,
}) {
  const NewColumns = reverse ? ReverseInMobileColumns : Columns;

  return (
    <HalfSectionWrapper height={height} backgroundColor={backgroundColor} size={'small'}>
      <Container>
        {heading && <BorderBottomHeading text={heading} />}
        <WithBackground background={background}>
          <NewColumns tablet={{ gap: 8 }}>
            <FontReponsiveColumn size={6} textAlign="center" {...aosAnimation}>
              {left}
            </FontReponsiveColumn>
            <FontReponsiveColumn size={6} textAlign="center" {...aosAnimation}>
              {right}
            </FontReponsiveColumn>
          </NewColumns>
        </WithBackground>
      </Container>
    </HalfSectionWrapper>
  );
}

const HalfSectionWrapper = styled(Section)`
  height: ${({ height }) => (height === 'auto' ? height : height + 'px')};
  background-color: ${({ backgroundColor }) => backgroundColor};
  position: relative;

  & img {
    padding: 75px;

    @media screen and (max-width: 768px) {
      padding: 20px;
    }
  }
`;

const WithBackground = styled.div`
  position: relative;
  &::before {
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    width: 100%;
    height: 100%;
    transform: scale(0.9);
    ${({ background }) =>
      background &&
      `
      background-image: url(${background});
  `};
  }
`;

const ReverseInMobileColumns = styled(Columns)`
  @media screen and (max-width: 768px) {
    flex-wrap: nowrap !important;
    flex-direction: column-reverse;
    display: flex;
  }
`;

const FontReponsiveColumn = styled(Column)`
  @media screen and (max-width: 768px) {
    .title.is-1 {
      font-size: 1.625rem;
    }
  }
`;
