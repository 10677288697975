import { faHeart, faPlay, faStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Icon } from 'react-bulma-components';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const renderStars = (difficulty) => {
  const nodeList = [];
  for (let i = 0; i < 3; i += 1) {
    if (i < difficulty) {
      nodeList.push(<Star key={i} />);
    } else {
      nodeList.push(<Star key={i} filled={'#e9e9e9'} />);
    }
  }

  return nodeList;
};

export default React.forwardRef(
  (
    {
      id,
      title,
      thumbnailUrl,
      sellMaterial,
      difficulty,
      category1Name,
      category2Name,
      category4Name,
      runningTime,
      noTag,
      scheduleType,
      likeCount,
      viewCount,
    },
    ref
  ) => {
    return (
      <ContentWrapper ref={ref} className="flicking-panel">
        <Link to={`/${category1Name === '퀴즈' ? 'quiz' : 'video'}/detail/${id}`}>
          <Thumbnail
            style={{ backgroundImage: `url('${thumbnailUrl}')` }}
            tag={category4Name || category2Name}
            tagColor={
              scheduleType === 'weekly'
                ? 'rgba(50, 50, 50, 0.75)'
                : category2Name === '프로그램'
                ? '#eb7eff'
                : category2Name === '클립'
                ? '#1ddb5a'
                : '#ff352b'
            }
            noTag={noTag}
          >
            {typeof difficulty === 'number' && <Stars>{renderStars(difficulty)}</Stars>}
            {!!runningTime && (
              <Duration>
                {Math.round(runningTime / 60)}:{(runningTime % 60).toString().padStart(2, '0')}
              </Duration>
            )}
          </Thumbnail>
          <TitleWrapper>
            <Title>
              {!!sellMaterial && <GiftEmoji>🎁</GiftEmoji>}
              {title}
            </Title>
            {viewCount !== undefined && likeCount !== undefined && (
              <InfoGroup>
                <Info>
                  <Play /> {viewCount}
                </Info>
                <Info>
                  <Heart /> {likeCount}
                </Info>
              </InfoGroup>
            )}
          </TitleWrapper>
        </Link>
      </ContentWrapper>
    );
  }
);

const ContentWrapper = styled.div`
  width: 18.7%;
  margin-right: 1.6%;

  @media screen and (max-width: 1023px) {
    width: 23.8%;
    margin-right: 1.6%;
  }
  @media screen and (max-width: 768px) {
    width: calc(30% - 10px);
    min-width: 200px;
  }
`;

const Thumbnail = styled.div`
  width: 100%;
  padding-bottom: 75%;
  background: #dddddd no-repeat center center;
  background-size: cover;
  border-radius: 6px;
  position: relative;
  transition: transform 0.15s ease;
  transform-origin: center;

  ${({ noTag, tag, tagColor }) =>
    noTag
      ? ''
      : `
        &:after {
          content: '${tag}';
          position: absolute;
          top: 10px;
          left: 0;
          font-size: 14px;
          font-weight: bold;
          line-height: 1.25;
          padding: 5px 11px;
          border-top-right-radius: 6px;
          border-bottom-right-radius: 6px;
          background-color: ${tagColor};
          color: #ffffff;
          opacity: 0.9;
        }`}
`;

const Duration = styled.div`
  background: rgba(0, 0, 0, 0.8);
  color: #ffffff;
  position: absolute;
  right: 5px;
  bottom: 5px;
  padding: 3px 4px;
  border-radius: 2px;
  font-size: 0.8rem;
  font-weight: 500;
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  padding-bottom: 5px;
`;

const Title = styled.div`
  color: #1d1d1f;
  line-height: 1.25;
  font-size: 0.95rem;
  height: 1.25em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const Stars = styled.div`
  position: absolute;
  top: 14px;
  right: 10px;
`;
const InfoGroup = styled.div`
  margin-left: 5px;
  font-size: 0.76rem;
  display: flex;
  align-items: center;
`;
const Info = styled.div`
  display: inline-flex;
  align-items: center;
  margin-right: 12px;

  &:last-child {
    margin-right: 3px;
  }
`;

const Star = ({ filled }) => {
  return (
    <Icon style={{ width: 'auto', height: 'auto', marginRight: 3 }}>
      <StyledFontAwesomeIcon icon={faStar} filled={filled} />
    </Icon>
  );
};

const Play = () => {
  return (
    <Icon style={{ width: 8, height: 8, marginRight: 6 }}>
      <StyledFontAwesomeIcon icon={faPlay} filled={'#8e8e8f'} />
    </Icon>
  );
};

const Heart = () => {
  return (
    <Icon style={{ width: 8, height: 8, marginRight: 6 }}>
      <StyledFontAwesomeIcon icon={faHeart} filled={'#8e8e8f'} />
    </Icon>
  );
};

const StyledFontAwesomeIcon = styled(({ filled, ...rest }) => <FontAwesomeIcon {...rest} />)`
  color: ${({ filled, theme }) => filled || theme.yellow};
`;

const GiftEmoji = styled.span`
  margin-right: 6px;
  vertical-align: top;
`;
