import JoyRide, { STATUS } from 'react-joyride';

const locale = {
  back: '이전',
  close: '닫기',
  last: '닫기',
  next: '다음',
  skip: '넘어가기',
};

export default function UserGuide({ steps, onSkipped, onFinished }) {
  const handleJoyRideCallback = ({ action, controlled, index, lifecycle, size, status, step, type }) => {
    if (status === STATUS.SKIPPED) {
      onSkipped && onSkipped();
    }
    if (status === STATUS.FINISHED) {
      onFinished && onFinished();
    }
  };

  return (
    <JoyRide
      locale={locale}
      steps={steps}
      showSkipButton
      continuous
      floaterProps={{ disableAnimation: true }}
      callback={handleJoyRideCallback}
    />
  );
}
