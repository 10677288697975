import { requestResetPw } from 'api';
import Button from 'components/Button';
import Countdown from 'components/Countdown';
import { ForwardedInput } from 'components/Form';
import FormLabel from 'components/Form/FormLabel';
import ERROR_REQUEST from 'constants/errorMessages';
import { useFindPwSMSRequest } from 'hooks/useSMSRequest';
import * as R from 'ramda';
import React, { useCallback, useMemo, useRef } from 'react';
import { Box, Columns, Form, Heading } from 'react-bulma-components';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { autoHyphen } from 'utils/str';
import { passwordRegex } from 'utils/validate';

const { Field, Help, Control } = Form;

export default function FindPwForm() {
  const { isPhoneVerifyRequested, smsAuthToken, onRequestSMSVerify, onRequestSMSVerifyCode } = useFindPwSMSRequest();
  const history = useHistory();
  const methods = useForm();
  const { register, watch, formState, getValues, setFocus } = methods;
  const { errors } = formState;

  const password = useRef({});
  password.current = watch('password', '');

  const handleRequestCodeClick = useCallback(() => {
    const hp = getValues('hp');

    onRequestSMSVerify(hp, () => {
      setFocus('hpVerify');
    });
  }, [getValues, setFocus, onRequestSMSVerify]);

  const handlePhoneVerifyCode = useCallback(() => {
    const [hp, code] = getValues(['hp', 'hpVerify']);

    onRequestSMSVerifyCode({
      hp,
      code,
    });
  }, [onRequestSMSVerifyCode, getValues]);

  const handleSubmit = (data) => {
    (async () => {
      const validDatas = R.omit(['hpVerify'], data);
      const {
        data: response,
        error,
        requestName,
      } = await requestResetPw({
        ...validDatas,
        smsAuthToken,
      });

      if (error) {
        alert(ERROR_REQUEST[requestName][error.response.status]);
        return;
      }

      if (response) {
        alert('비밀번호가 정상적으로 변경되었습니다. 다시 로그인해주세요.');
        history.push('/login');
      }
    })();
  };

  const hasSmsAuthToken = useMemo(() => smsAuthToken.length > 0, [smsAuthToken]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(handleSubmit)}>
        <ResponsiveBox>
          <Heading>비밀번호 변경하기</Heading>
          <Field color="yellow">
            <FormLabel required>아이디</FormLabel>
            <Control>
              <ForwardedInput
                {...register('id', { required: '아이디를 입력해주세요.' })}
                type="text"
                placeholder="아이디를 입력해주세요."
                color={errors?.id && 'danger'}
              />
            </Control>
            {errors?.id && <Help color="danger">{errors?.id?.message}</Help>}
          </Field>
          <Field>
            <FormLabel required>핸드폰 번호</FormLabel>
            <PhoneColumns vCentered>
              <Columns.Column size={7}>
                <Control>
                  <PhoneInput
                    {...register('hp', {
                      required: '핸드폰 번호를 입력해주세요 ("-" 없이)',
                    })}
                    type="text"
                    placeholder="핸드폰 번호를 입력해주세요. (번호만 입력)"
                    value={autoHyphen(watch('hp') || '')}
                    color={errors?.hp && 'danger'}
                  />
                </Control>
              </Columns.Column>
              <Columns.Column>
                <Button
                  block
                  disabled={isPhoneVerifyRequested}
                  onClick={handleRequestCodeClick}
                  type="button"
                  color={hasSmsAuthToken && 'primary'}
                >
                  {hasSmsAuthToken ? '인증 완료' : '인증번호발송'}
                </Button>
              </Columns.Column>
            </PhoneColumns>
            {isPhoneVerifyRequested ? (
              !hasSmsAuthToken ? (
                <Columns vCentered>
                  <Columns.Column size={7}>
                    <Control>
                      <ForwardedInput
                        placeholder="인증번호를 입력해주세요."
                        {...register('hpVerify', {
                          required: '인증번호를 입력해주세요.',
                          maxLength: 6,
                        })}
                      />
                    </Control>
                  </Columns.Column>
                  <Columns.Column>
                    <Button type="button" block onClick={handlePhoneVerifyCode}>
                      확인
                    </Button>
                  </Columns.Column>
                  <Columns.Column style={{ paddingLeft: 0 }}>
                    <Countdown remainTime={180} />
                  </Columns.Column>
                </Columns>
              ) : null
            ) : null}
            {errors?.hp && <Help color="danger">{errors?.hp?.message}</Help>}
          </Field>
          <Field>
            <FormLabel required>새 비밀번호</FormLabel>
            <Control>
              <ForwardedInput
                {...register('password', {
                  required: '새 비밀번호는 필수 입력사항입니다.',
                  minLength: {
                    value: 8,
                    message: '최소 8자 이상 입력해주세요.',
                  },
                  pattern: {
                    value: passwordRegex,
                    message: '영문자, 숫자, 특수문자의 조합으로 8자 이상으로 입력해주세요.',
                  },
                })}
                type="password"
                placeholder="새 비밀번호를 입력해주세요."
                color={errors?.password && 'danger'}
              />
            </Control>
            {errors?.password && <Help color="danger">{errors?.password?.message}</Help>}
          </Field>
          <Field>
            <FormLabel required>새 비밀번호 재입력</FormLabel>
            <Control>
              <ForwardedInput
                {...register('passwordConfirm', {
                  required: '새 비밀번호를 한번 더 입력해주세요',
                  validate: (value) => password.current === value || '입력한 비밀번호와 다릅니다.',
                })}
                type="password"
                placeholder="새 비밀번호를 한번 더 입력해주세요"
                color={errors?.passwordConfirm && 'danger'}
              />
            </Control>
            {errors?.passwordConfirm && <Help color="danger">{errors?.passwordConfirm?.message}</Help>}
          </Field>
          <ConfirmButton block={true} noBorder={true} type="submit">
            확인
          </ConfirmButton>
        </ResponsiveBox>
      </form>
    </FormProvider>
  );
}

const ResponsiveBox = styled(Box)`
  width: 24rem;
  margin: 0 auto;
  .field .label {
    padding-top: 16px;
  }
  @media screen and (max-width: 768px) {
    box-shadow: none;
    width: 100%;
  }
`;

const ConfirmButton = styled(Button)`
  margin-top: 12px;
  background-color: ${({ theme }) => theme.yellow};
`;

const PhoneColumns = styled(Columns)`
  margin-bottom: 0 !important;
`;

const PhoneInput = styled(ForwardedInput)`
  &:read-only {
    cursor: not-allowed;
    background-color: whitesmoke;
    border-color: whitesmoke;
    color: #7a7a7a;
  }
`;
