import api from 'api';

export async function getEvents(params = {}) {
  const { data, error } = await api({
    method: 'get',
    url: '/event',
    params: params,
  });

  return { data, error, requestName: 'GET_EVENT_LIST' };
}

export async function getEvent({ id }) {
  const { data, error } = await api({
    method: 'get',
    url: `/event/${id}`,
  });

  return { data, error, requestName: 'GET_EVENT' };
}
