import { Link } from 'react-router-dom';
import styled from 'styled-components';

export default function CategoryItem({ type, category2, category3, name, thumbnail_url, ...rest }) {
  return (
    <Link to={type === 'video' ? `/${type}/list/${category2}/${category3}/${name}` : `/${type}/list/${category2}`}>
      <CategoryItemWrapper style={{ backgroundImage: `url('${thumbnail_url}')` }}>
        <Dim>{name}</Dim>
      </CategoryItemWrapper>
    </Link>
  );
}

const CategoryItemWrapper = styled.div`
  background-image: url(${({ thumbnail }) => thumbnail});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  font-size: 32px;
  font-weight: 900;
  color: #ffffff;
  border-radius: 6px;
  width: 100%;
  padding-bottom: 100%;
  overflow: hidden;
  text-align: center;
  line-height: 1.15;
  position: relative;
  cursor: pointer;
  transition: transform 0.15s ease;
  transform-origin: center;

  &:hover {
    transform: scale(1.08);
  }

  @media screen and (max-width: 768px) {
    font-size: 22px;
  }
`;

const Dim = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.3);
  height: 100%;
  word-break: keep-all;
  padding: 0 25px;
`;
