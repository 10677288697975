import React, { useEffect, useMemo, useRef, useState } from 'react';

const formattedTime = (time) => (time < 10 ? `0${time}` : time);

function CountDown({ remainTime, afterTimerEnd, timerNo }) {
  const intervalId = useRef();
  const [timer, setTimer] = useState(remainTime);

  const formatRemainTime = useMemo(() => {
    const minutes = Math.floor((timer / 60) % 60);
    const seconds = Math.floor(timer % 60);

    return `${formattedTime(minutes)}:${formattedTime(seconds)}`;
  }, [timer]);

  const startTimer = () => {
    intervalId.current = setInterval(() => {
      setTimer((prev) => prev - 1);
    }, 1000);
  };

  const clearTimer = () => {
    if (intervalId && intervalId.current) {
      clearInterval(intervalId.current);
    }
  };

  useEffect(() => {
    startTimer();
    return () => {
      clearTimer();
    };
  }, []);

  useEffect(() => {
    if (timer <= 0) {
      clearTimer();
      afterTimerEnd && afterTimerEnd();
    }
  }, [timer, afterTimerEnd]);

  useEffect(() => {
    if (timerNo) {
      clearTimer();
      setTimer(remainTime);
      startTimer();
    }
  }, [remainTime, timerNo]);

  return (
    <div>
      <span style={{ fontSize: 14, color: '#999' }}>{formatRemainTime}</span>
    </div>
  );
}

export default React.memo(CountDown);
