import Logo from 'assets/images/dolbom-logo.svg';
import React, { useEffect, useMemo, useState } from 'react';
import { Navbar } from 'react-bulma-components';
import { Link, useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { parseQuery } from 'utils/parseQuery';

import Button from '../Button';
import Nav from './Nav';

const redirectPathMap = {
  '/program/video/[0-9]+$': 'needAuth',
  '/program/quiz/[0-9]+$': 'needAuth',
  '/teacher/board/[0-9]+$': 'needAuth',
};

export default function NonAuthorizedHeader() {
  const [visible, setVisible] = useState(false);
  const location = useLocation();
  const history = useHistory();
  const pathname = location.pathname;

  const toggleMobileMenu = () => {
    setVisible((prevState) => !prevState);
  };

  const isSignUpPage = pathname === '/signup';
  const isLoginPage = pathname === '/login';
  const isLandingPage = pathname === '/';
  const showMenu = !isSignUpPage && !isLoginPage && !isLandingPage;

  const redirectTo = useMemo(
    () =>
      redirectPathMap[
        Object.keys(redirectPathMap).find((key) => {
          const reg = new RegExp(key);
          return reg.test(pathname);
        })
      ],
    [pathname]
  );

  const query = useMemo(() => parseQuery(window.location.search), []);

  const activeKey = useMemo(() => {
    if (query.year && query.month && query.date) {
      return 'monthly-program';
    }
    return pathname.replace('/', '');
  }, [pathname, query]);

  useEffect(() => {
    if (redirectTo) {
      if (redirectTo === 'back') {
        history.goBack();
      }
      if (redirectTo === 'needAuth') {
        alert('로그인이 필요한 페이지입니다.');
        history.goBack();
      }
    }
  }, [history, redirectTo]);

  return (
    <>
      <Navbar.Brand>
        <LogoItem renderAs={Link} to="/">
          <img src={Logo} alt="Logo" />
        </LogoItem>
        <Navbar.Burger
          onClick={() => {
            toggleMobileMenu();
          }}
        />
      </Navbar.Brand>

      <Navbar.Menu className={`${visible ? 'is-active' : ''}`}>
        {showMenu && (
          <Nav activeKey={activeKey}>
            <Navbar.Item className="monthly-program-step" key="monthly-program" renderAs={Link} to="/monthly-program">
              월간 프로그램
            </Navbar.Item>
            <Navbar.Item className="video-step" key="video" renderAs={Link} to="/video/category">
              돌봄 영상
            </Navbar.Item>
            <Navbar.Item className="quiz-step" key="quiz" renderAs={Link} to="/quiz/category">
              5분 퀴즈
            </Navbar.Item>
            <Navbar.Item className="teacher-step" key="teacher/board" renderAs={Link} to="/teacher/board">
              선생님 공간
            </Navbar.Item>
          </Nav>
        )}
        <Navbar.Container align={`${visible ? 'left' : 'right'}`}>
          {isSignUpPage ? null : (
            <JoinNavBarItem renderAs={Link} to="/signup">
              <NavbarButton rounded>회원가입</NavbarButton>
            </JoinNavBarItem>
          )}
          {isLoginPage ? null : (
            <LoginNavBarItem renderAs={Link} to="/login">
              <NavbarButton rounded>로그인</NavbarButton>
            </LoginNavBarItem>
          )}
        </Navbar.Container>
      </Navbar.Menu>
    </>
  );
}

const LogoItem = styled(Navbar.Item)`
  min-width: 125px;
  & img {
    height: 100%;
  }
`;

const NavItem = styled(Navbar.Item)`
  &:hover {
    background-color: transparent !important;
  }
`;

const JoinNavBarItem = styled(NavItem)`
  @media screen and (min-width: 1024px) {
    padding-right: 0;
  }
`;
const LoginNavBarItem = styled(NavItem)`
  @media screen and (min-width: 1024px) {
    padding-left: 8px;
  }
`;

const NavbarButton = styled(Button)`
  width: 120px;
  color: #000;
  border: none;
  &:hover {
    border: none;
  }
  @media screen and (max-width: 1023px) {
    justify-content: flex-start;

    &:hover {
      background-color: transparent;
    }
  }
`;
