import bgImage from 'assets/landing/footer-background.jpg';
import ButtonWithSignUpModal from 'pages/LandingPage/components/ButtonToExplore';
import { Heading } from 'react-bulma-components';
import styled from 'styled-components';

export default function JoinNowSection() {
  return (
    <JoinNowSectionWrapper bgImage={bgImage}>
      <Dim>
        <JoinNowHeading>지금 가입하면?</JoinNowHeading>
        <Subtitle subtitle size={3}>
          <Bold>3개월 무료</Bold> 체험부터 <Bold>다양한 혜택</Bold>까지!
        </Subtitle>
        <ButtonWithSignUpModal noBorder />
      </Dim>
    </JoinNowSectionWrapper>
  );
}

const JoinNowSectionWrapper = styled.section`
  text-align: center;
  background-image: url(${({ bgImage }) => bgImage});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`;
const Dim = styled.div`
  background-color: rgba(0, 0, 0, 0.7);
  padding-top: 136px;
  padding-bottom: 80px;
`;

const JoinNowHeading = styled(Heading)`
  padding-bottom: 40px;
  margin-bottom: 0 !important;
  font-weight: bold;
  font-size: 64px;
  color: ${({ theme }) => theme.yellow};

  @media screen and (max-width: 768px) {
    font-size: 48px;
  }
`;

const Subtitle = styled(Heading)`
  color: #fff;
  margin-top: 0 !important;
  margin-bottom: 56px !important;
`;
const Bold = styled.span`
  font-weight: 800 !important;
`;
