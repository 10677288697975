/**
 * camel case로 변환
 * @example
 * str.camalize('abcd_efg'); => abcdEfg
 */

export const camelcase = (str) => {
  return str
    .replace(/[\s|_|-](.)/g, ($1) => $1.toUpperCase())
    .replace(/[\s|_|-]/g, '')
    .replace(/^(.)/, ($1) => $1.toLowerCase());
};

/**
 * snake case로 변환
 * @example
 * str.snakecase('abcdEfg'); => abcd_efg
 */
export const snakecase = (str) => {
  return str
    .toString()
    .replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);
};

export const autoHyphen = (str) => {
  return str
    .replace(/[^0-9]/g, '')
    .replace(
      /(^02|^0505|^1[0-9]{3}|^0[0-9]{2})([0-9]+)?([0-9]{4})$/,
      '$1-$2-$3'
    )
    .replace('--', '-');
};
