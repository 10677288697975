import { Route, Switch } from 'react-router-dom';

import EditEmail from './pages/EditEmail';
import EditInterestContents from './pages/EditInterestContents';
import EditPassword from './pages/EditPassword';
import EditPhone from './pages/EditPhone';
import ProfileMain from './pages/ProfileMain';

export default function ProfileRoutes() {
  return (
    <>
      <Switch>
        <Route exact={true} path="/mypage/profile" component={ProfileMain} />
        <Route exact={true} path="/mypage/profile/edit-email" component={EditEmail} />
        <Route exact={true} path="/mypage/profile/edit-pw" component={EditPassword} />
        <Route exact={true} path="/mypage/profile/edit-hp" component={EditPhone} />
        <Route exact={true} path="/mypage/profile/interest-contents" component={EditInterestContents} />
      </Switch>
    </>
  );
}
