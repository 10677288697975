import {
  requestFindPwSMSVerify,
  requestFindPwSMSVerifyCode,
  requestSignUpSMSVerify,
  requestSignUpSMSVerifyCode,
} from 'api';
import ERROR_REQUEST from 'constants/errorMessages';
import { useCallback, useState } from 'react';
import { phoneRegex } from 'utils/validate';

export function useSignUpSMSRequest() {
  const [isPhoneVerifyRequested, setPhoneVerifyRequested] = useState(false);
  const [smsAuthToken, setSMSAuthToken] = useState('');
  const onRequestSMSVerify = useCallback(async (hp, callback) => {
    if (!phoneRegex.test(hp)) {
      alert('올바른 핸드폰 번호를 입력해주세요.');
      return;
    }
    const { error, requestName } = await requestSignUpSMSVerify({ hp });

    if (error) {
      alert(ERROR_REQUEST[requestName][error.response.status]);
      return;
    }

    setPhoneVerifyRequested(true);
    callback();
    return;
  }, []);

  const onRequestSMSVerifyCode = useCallback((hpAndCode, callback) => {
    (async () => {
      const { data, error, requestName } = await requestSignUpSMSVerifyCode(hpAndCode);

      if (error) {
        alert(ERROR_REQUEST[requestName][error.response.status]);
        return;
      }

      if (data) {
        setSMSAuthToken(data.smsAuthToken);
      }
    })();
  }, []);

  return { isPhoneVerifyRequested, smsAuthToken, onRequestSMSVerify, onRequestSMSVerifyCode };
}

export function useFindPwSMSRequest() {
  const [isPhoneVerifyRequested, setPhoneVerifyRequested] = useState(false);
  const [smsAuthToken, setSMSAuthToken] = useState('');
  const onRequestSMSVerify = useCallback((hp, callback) => {
    if (!phoneRegex.test(hp)) {
      alert('올바른 핸드폰 번호를 입력해주세요.');
      return;
    }

    (async () => {
      const { error, requestName } = await requestFindPwSMSVerify({ hp });

      if (error) {
        alert(ERROR_REQUEST[requestName][error.response.status]);
      }

      setPhoneVerifyRequested(true);
      callback();
      return;
    })();
  }, []);

  const onRequestSMSVerifyCode = useCallback((hpAndCode, callback) => {
    (async () => {
      const { data, error, requestName } = await requestFindPwSMSVerifyCode(hpAndCode);

      if (error) {
        alert(ERROR_REQUEST[requestName][error.response.status]);
        return;
      }

      if (data) {
        setSMSAuthToken(data.smsAuthToken);
      }
    })();
  }, []);

  return { isPhoneVerifyRequested, smsAuthToken, onRequestSMSVerify, onRequestSMSVerifyCode };
}
