import { requestBoardDelete, requestBoardPost } from 'api';
import BodyTitle from 'components/BodyTitle/index';
import Button from 'components/Button';
import { CommentsProvider } from 'context/CommentsContextProvider';
import { useFetch } from 'hooks/useFetch';
import { Container, Content } from 'react-bulma-components';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';

import CommentSection from '../components/CommentSection';
import PostAuthor from '../components/PostAuthor';

const cacheOptions = {
  useCache: true,
};

export default function TeacherPost() {
  const params = useParams();
  const history = useHistory();

  const { data, error } = useFetch(requestBoardPost, params?.id, cacheOptions);

  if (error) {
    alert('존재하지 않는 게시글입니다.');
    return <Redirect to="/teacher/board" />;
  }
  const handleEditButtonClick = () => {
    history.push(`/teacher/board-edit/${params?.id}`, data);
  };

  const handleDeleteButtonClick = () => {
    (async () => {
      const { data: response, error } = await requestBoardDelete({ id: params?.id });
      if (error) {
        console.error(error.response);
      }

      if (response) {
        alert('게시글이 삭제되었습니다.');
        history.replace('/teacher/board');
      }
    })();
  };

  return (
    <PostWrapper breakpoint="desktop" max={true}>
      <BodyTitle paths={['홈', '선생님 공간']} title={data?.title} />
      <PostAuthor
        author={data?.accountId}
        created={data?.createdAt}
        viewCount={data?.viewCount}
        commentCount={data?.comment.length}
        isWriter={data?.isWriter}
        onEditButtonClick={handleEditButtonClick}
        onDeleteButtonClick={handleDeleteButtonClick}
      />
      <StyledContent className={'bootstrap-style'}>
        <div dangerouslySetInnerHTML={{ __html: data?.content }} />
      </StyledContent>
      <CommentsProvider>
        <CommentSection boardId={params?.id || ''} comments={data?.comment || []} />
      </CommentsProvider>
      <BottomButtonGroup>
        <Button
          onClick={() => {
            history.push('/teacher/board');
          }}
        >
          목록으로
        </Button>
      </BottomButtonGroup>
    </PostWrapper>
  );
}

const PostWrapper = styled(Container)`
  padding: 40px 0 80px;
`;

const StyledContent = styled(Content)`
  padding: 35px 0;
  min-height: 400px;
  border-top: 1px solid #e9e9e9;
  border-bottom: 1px solid #e9e9e9;
`;

const BottomButtonGroup = styled.div`
  margin-top: 15px;
  text-align: left;
`;
