import axios from 'axios';
import { cacheAdapterEnhancer } from 'axios-extensions';
import * as R from 'ramda';

import axiosMiddlewares from './axiosMiddlewares';
import config from './config';

const fetch = axiosMiddlewares(
  axios.create({
    baseURL: config.host,
    adapter: cacheAdapterEnhancer(axios.defaults.adapter, {
      enabledByDefault: false,
    }),
  })
);

const preOpen = axios.create({
  baseURL: 'http://13.209.13.154:5001',
});

async function api(request, options = {}, pre = false) {
  const token = window.localStorage.getItem('__TOKEN__') || null;

  const Authorization = token ? { headers: { Authorization: `Bearer ${token.replace(/"/g, '')}` } } : {};

  let url = `/v1${request.url}`;

  const params = {
    ...request,
    url,
  };

  try {
    const fetcher = pre ? preOpen : fetch;
    const res = await fetcher(R.mergeDeepLeft(params, Authorization));

    return { data: res.data };
  } catch (error) {
    return { error };
  }
}

export * from './requests';

export default api;
