import { Form } from 'react-bulma-components';
import styled from 'styled-components';

const { Label } = Form;

export default function FormLabel({ required, ...restProps }) {
  return <StyledLabel required={required} {...restProps} />;
}

const StyledLabel = styled(({ required, ...restProps }) => <Label {...restProps} />)`
  ${({ required }) =>
    required &&
    `&::after {
    content: '*';
    color: red;
    padding-left: 4px;
  }`}

  ${({ required }) => !required && `font-weight: 400;`}
`;
