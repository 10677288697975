import BgImage1 from 'assets/landing/middle-background1.svg';
import BgImage2 from 'assets/landing/middle-background2.svg';
import BgImage3 from 'assets/landing/middle-background3.svg';
import Image1 from 'assets/landing/middle-image1.png';
import Image2 from 'assets/landing/middle-image2.png';
import Image3 from 'assets/landing/middle-image3.png';
import styled from 'styled-components';

import HalfSection from '../components/HalfSection';
import TextContent from '../components/TextContent';
// import VideoSection from './VideoSection';

export default function MiddleSection() {
  return (
    <MiddleSectionWrapper>
      <HalfSection
        heading="마이돌봄이 특별한 이유!"
        left={<ImgContainer style={{ backgroundImage: `url('${Image1}')` }} />}
        right={
          <TextContent
            title={
              <>
                이젠 유튜브가
                <br />
                필요 없어요
              </>
            }
            description={
              <>
                매월 돌봄 주제에 맞추어 제공되는
                <br />
                70여개의 영상, 퀴즈 콘텐츠
              </>
            }
            align="left"
          />
        }
        background={BgImage1}
      />
      <HalfSection
        left={
          <TextContent
            title={
              <>
                준비물 찾아
                <br />
                헤매지 마세요
              </>
            }
            description={
              <>
                매월 배송되는 1인 패키지,
                <br />
                마이 돌봄키트
              </>
            }
            align="right"
          />
        }
        right={<ImgContainer style={{ backgroundImage: `url('${Image2}')` }} />}
        reverse={true}
        background={BgImage2}
      />
      <HalfSection
        left={<ImgContainer style={{ backgroundImage: `url('${Image3}')` }} />}
        right={
          <TextContent
            title={
              <>
                집중도 최고!
                <br />
                신나는 5분 퀴즈
              </>
            }
            description={
              <>
                자투리 시간에 딱맞는 그림 넌센스,
                <br />
                초성게임등이 한 곳에!
              </>
            }
            align="left"
          />
        }
        background={BgImage3}
      />
      {/* <VideoSection /> */}
    </MiddleSectionWrapper>
  );
}

const MiddleSectionWrapper = styled.div`
  padding-top: 180px;
`;

const ImgContainer = styled.div`
  @media screen and (max-width: 768px) {
    width: 75%;
    max-width: 350px;
    margin: auto;
  }
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  padding-bottom: 100%;
  margin: 0 75px;
`;
