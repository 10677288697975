import api from 'api';
import { camelize, snakize } from 'utils/obj';

export const getSchedule = async (params) => {
  const { data, error } = await api({
    method: 'get',
    url: '/schedule',
    params: snakize(params),
  });

  return {
    data: data ? camelize(data) : null,
    error,
    requestName: 'GET_SCHEDULE',
  };
};

export const getRecomendContentsByDate = async (params) => {
  const { data, error } = await api({
    method: 'get',
    url: '/schedule/recommend',
    params: snakize(params),
  });

  return {
    data: data ? camelize(data) : null,
    error,
    requestName: 'GET_RECOMMENDED',
  };
};

export const postLike = async ({ id }) => {
  const { data, error } = await api({
    method: 'post',
    url: `/contents/${id}/like`,
  });

  return {
    data: data ? camelize(data) : null,
    error,
    requestName: 'PATCH_LIKE',
  };
};

export const deleteLike = async ({ id }) => {
  const { data, error } = await api({
    method: 'delete',
    url: `/contents/${id}/like`,
  });

  return {
    data: data ? camelize(data) : null,
    error,
    requestName: 'PATCH_LIKE',
  };
};

export const requestContents = async (params) => {
  const { data, error } = await api({
    method: 'get',
    url: `/contents`,
    params: snakize(params),
  });

  return {
    data: data ? camelize(data) : null,
    error,
    requestName: 'GET_CONTENTS',
  };
};

export const requestContent = async (params) => {
  const { data, error } = await api({
    method: 'get',
    url: `/contents/${params.id}`,
  });

  return {
    data: data ? camelize(data) : null,
    error,
    requestName: 'GET_CONTENTS',
  };
};

export const getSearch = async (params) => {
  const { data, error } = await api({
    method: 'get',
    url: `/search`,
    params,
  });

  return {
    data: data ? camelize(data) : null,
    error,
    requestName: 'GET_SEARCH',
  };
};

export const getSearchKeyword = async (params) => {
  const { data, error } = await api({
    method: 'get',
    url: `/auto-complete`,
    params,
  });

  return {
    data: data ? camelize(data) : null,
    error,
    requestName: 'GET_SEARCH_KEYWORD',
  };
};

export const getRecomendContentsForMain = async () => {
  const { data, error } = await api({
    method: 'get',
    url: `/recommend`,
  });

  return {
    data: data ? camelize(data) : null,
    error,
    requestName: 'GET_RECOMMEND',
  };
};

export const getContentsLike = async () => {
  const { data, error } = await api({
    method: 'get',
    url: `/contents/like`,
  });

  return {
    data: data ? camelize(data) : null,
    error,
    requestName: 'GET_CONTENTS_LIKE',
  };
};
