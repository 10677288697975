import { requestChangeHp, requestChangeHpSMSVerify } from 'api';
import BodyTitle from 'components/BodyTitle';
import Button from 'components/Button';
import Countdown from 'components/Countdown';
import { ForwardedInput } from 'components/Form';
import FormLabel from 'components/Form/FormLabel';
import ERROR_REQUEST from 'constants/errorMessages';
import { AuthActions } from 'context/AuthContextProvider';
import { useAuthDispatch } from 'hooks/useAuthContext';
import { useState } from 'react';
import { Box, Columns, Container, Form } from 'react-bulma-components';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { autoHyphen } from 'utils/str';

const { Field, Help, Control } = Form;

export default function EditPhone() {
  const dispatch = useAuthDispatch();
  const history = useHistory();
  const methods = useForm();
  const [isCodeRequested, setCodeRequested] = useState('');
  const { register, formState, watch, getValues } = methods;
  const { errors } = formState;

  const handleRequestCodeClick = () => {
    const hp = getValues('hp');
    (async () => {
      const { error, requestName } = await requestChangeHpSMSVerify({ hp });

      if (error) {
        console.error(error.response);
        alert(ERROR_REQUEST[requestName][error.response.status]);
        return;
      }

      setCodeRequested(true);
    })();
  };

  const handleSubmit = (data) => {
    (async () => {
      const { error, requestName } = await requestChangeHp(data);

      if (error) {
        alert(ERROR_REQUEST[requestName][error.response.status]);
        return;
      }

      dispatch({
        type: AuthActions.SET_PROFILE,
        payload: {
          hp: data.hp,
        },
      });
      history.replace('/mypage/profile');
    })();
  };

  return (
    <StyledContainer py={6} breakpoint="desktop" max={true}>
      <BodyTitle paths={['홈', '마이페이지', '프로필편집', '휴대폰 번호 변경']} title="휴대폰 번호 변경" />

      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(handleSubmit)}>
          <ResponsiveBox mt={5}>
            <Field>
              <FormLabel required>새로운 휴대폰 번호</FormLabel>
              <PhoneColumns vCentered>
                <Columns.Column size={8}>
                  <Control>
                    <ForwardedInput
                      {...register('hp', {
                        required: '휴대폰 번호는 필수 입력사항입니다.',
                        pattern: {
                          value: /^01{1}[016789]{1}-\d{3,4}-\d{4}$/,
                          message: '올바르지 않은 형식입니다.',
                        },
                      })}
                      type="text"
                      value={autoHyphen(watch('hp') || '')}
                      placeholder="새로운 휴대폰 번호를 입력해주세요."
                      color={errors?.hp && 'danger'}
                    />
                  </Control>
                </Columns.Column>
                <Columns.Column>
                  <Button block disabled={isCodeRequested} onClick={handleRequestCodeClick} type="button" color="">
                    인증번호발송
                  </Button>
                </Columns.Column>
              </PhoneColumns>

              {errors?.hp && <Help color="danger">{errors?.hp?.message}</Help>}
            </Field>
            {isCodeRequested && (
              <Field>
                <Columns vCentered>
                  <Columns.Column size={8}>
                    <Control>
                      <ForwardedInput
                        placeholder="핸드폰에 전송된 인증번호를 입력해주세요."
                        {...register('code', {
                          required: '인증번호를 입력해주세요.',
                          maxLength: 6,
                        })}
                      />
                    </Control>
                  </Columns.Column>

                  <Columns.Column>
                    <Countdown remainTime={180} />
                  </Columns.Column>
                </Columns>
              </Field>
            )}
            <EditButton block={true} noBorder={true} type="submit">
              변경하기
            </EditButton>
          </ResponsiveBox>
        </form>
      </FormProvider>
    </StyledContainer>
  );
}

const StyledContainer = styled(Container)`
  @media screen and (min-width: 1024px) {
    max-width: 30rem;
  }
`;

const ResponsiveBox = styled(Box)`
  width: 30rem;
  margin: 0 auto;
  .field .label {
    padding-top: 16px;
  }

  @media screen and (max-width: 768px) {
    box-shadow: none;
    width: 100%;
  }
`;

const PhoneColumns = styled(Columns)`
  margin-bottom: 0 !important;
`;

const EditButton = styled(Button)`
  margin-top: 12px;
  background-color: ${({ theme }) => theme.yellow};
`;
