import { Heading } from 'react-bulma-components';
import styled from 'styled-components';

export default function NoSearchResult({ keyword }) {
  return (
    <NoSearchResultWrapper>
      <Heading size={3}>입력하신 검색어 '{keyword}' (와)과 일치하는 결과가 없습니다.</Heading>
      <SubtitleWrapper>
        <Heading size={5} subtitle>
          <p>• 다른 키워드를 입력해보세요.</p>
          <p>• 만들기, 안전과 같은 주제명으로 검색해보세요.</p>
          <p>• 광복절, 식목일과 같은 법정 기념일 키워드로 검색해보세요.</p>
        </Heading>
      </SubtitleWrapper>
    </NoSearchResultWrapper>
  );
}

const NoSearchResultWrapper = styled.div`
  padding: 50px 15px;
  height: 300px;
  text-align: center;
  color: ${({ theme }) => theme.gray};
`;

const SubtitleWrapper = styled.div`
  margin-top: 30px;
  text-align: left;
  display: inline-block;
  opacity: 0.8;
  color: ${({ theme }) => theme.gray};
  & p {
    line-height: 1.8;
  }
`;
