import axios from 'axios';
import * as R from 'ramda';

const cancelToken = (instance) => {
  instance.interceptors.request.use(
    function (request) {
      const source = axios.CancelToken.source();
      return { ...request, cancelToken: source.token };
    },

    function (error) {
      return Promise.reject(error);
    }
  );
  return instance;
};

const unAuthorized = (instance) => {
  instance.interceptors.response.use(
    function (response) {
      return response;
    },

    function (error) {
      const { response } = error;

      const token = window.localStorage.getItem('__TOKEN__') || null;

      const redirect = response?.config?.headers['Content-Redirect'] !== 'disable';
      if (response?.config?.url === '/v1/account/login') {
        return Promise.reject(error);
      }
      // LOGOUT
      if (redirect && response && response.status === 401) {
        if (!!token) {
          window.location.href = '/logout';
        }
      }
      return Promise.reject(error);
    }
  );

  return instance;
};

const axiosMiddlewares = (instance) => {
  return R.pipe(cancelToken, unAuthorized)(instance);
};

export default axiosMiddlewares;
