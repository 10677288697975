import { ForwardedCheckbox } from 'components/Form';
import * as R from 'ramda';
import { Form } from 'react-bulma-components';
import { useFormContext } from 'react-hook-form';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const { Field, Control, Label, Help } = Form;

export default function Terms() {
  const { setValue, register, getValues, watch, formState } = useFormContext();

  const handleCheckbox = (e) => {
    const { checked } = e.target;
    setValue(e.target.name, checked);
  };

  const checkboxes = [
    {
      name: 'agreeAllTerms',
      label: <span>전체 약관에 동의합니다.</span>,
      handler: (e) => {
        const { checked } = e.target;

        R.forEach((checkbox) => {
          setValue(checkbox.name, checked);
        }, checkboxes);
      },
      required: false,
    },
    {
      name: 'termsOfService',
      label: (
        <span>
          돌봄365 서비스
          <Link to="/terms" target="_blank">
            <b style={{ fontWeight: 700, textDecoration: 'underline' }}>이용 약관</b>
          </Link>
          에 동의합니다.(필수)
        </span>
      ),
      handler: handleCheckbox,
      required: true,
    },
    {
      name: 'privacy',
      label: (
        <span>
          <Link to="/terms/private" target="_blank">
            <b style={{ fontWeight: 700, textDecoration: 'underline' }}>개인정보 수집 및 이용에 대한 안내</b>
          </Link>
          에 동의합니다.(필수)
        </span>
      ),
      handler: handleCheckbox,
      required: true,
    },
    {
      name: 'privacyOther',
      label: (
        <span>
          <Link to="/terms/private-third-party" target="_blank">
            <b style={{ fontWeight: 700, textDecoration: 'underline' }}>개인정보의 제 3자 제공</b>
          </Link>
          에 동의합니다. (필수)
        </span>
      ),
      handler: handleCheckbox,
      required: true,
    },
    {
      name: 'eventReception',
      label: <span>SMS 및 이메일을 통한 이벤트 정보 수신에 동의합니다.(선택)</span>,
      handler: handleCheckbox,
      required: false,
    },
  ];

  watch(R.map(R.prop(['name']), checkboxes));

  return (
    <TermWrapper>
      <Label>약관 동의</Label>
      <TermBox>
        {R.map((checkbox) => {
          return (
            <Field key={checkbox.name} pb={2}>
              <Control>
                <ForwardedCheckbox
                  {...register(checkbox.name, {
                    required: {
                      value: checkbox.required,
                      message: '필수 선택사항입니다.',
                    },
                  })}
                  name={checkbox.name}
                  onChange={checkbox.handler}
                  checked={!!getValues(checkbox.name)}
                >
                  {checkbox.label}
                </ForwardedCheckbox>
              </Control>
              {formState.errors[checkbox.name] && <Help color="danger">{formState.errors[checkbox.name].message}</Help>}
            </Field>
          );
        }, checkboxes)}
      </TermBox>
    </TermWrapper>
  );
}

const TermWrapper = styled.div`
  margin-top: 40px;
`;

const TermBox = styled.div`
  border: 1px solid #e9e9e9;
  border-radius: 6px;
  padding: 16px;

  .field span {
    padding-left: 8px;
  }
  .field:not(:first-child) label {
    font-size: 0.8rem;
  }
  .field:first-child {
    border-bottom: 2px solid #e9e9e9;
    padding-bottom: 0.75rem;
  }
  .field:first-child label {
    font-size: 1.125rem;
    font-weight: bold;
  }
`;
