import Aos from 'aos';
import { useEffect } from 'react';

import HeroSection from './sections/HeroSection';
import JoinNowSection from './sections/JoinNowSection';
import MiddleSection from './sections/MiddleSection';
import ReviewSection from './sections/ReviewSection';

export default function LandingPage() {
  useEffect(() => {
    Aos.init({
      once: true,
    });
  }, []);

  return (
    <>
      <HeroSection />
      <MiddleSection />
      <ReviewSection />
      <JoinNowSection />
    </>
  );
}
