import { getEvents } from 'api/requests/eventRequests';
import BodyTitle from 'components/BodyTitle';
import { useFetch } from 'hooks/useFetch';
import { Container } from 'react-bulma-components';
import styled from 'styled-components';

import EventList from './EventList';

const params = { type: 'internal' };

export default function EventListPage() {
  const { data } = useFetch(getEvents, params);
  const list = data?.list || [];

  return (
    <EventListPageWrapper breakpoint="desktop" max={true}>
      <BodyTitle title={'지금 진행 중인 이벤트'} paths={['홈', '이벤트']} />
      <EventList list={list} />
    </EventListPageWrapper>
  );
}

const EventListPageWrapper = styled(Container)`
  padding-top: 40px;
`;
