import { AuthDispatchContext, AuthStateContext } from 'context/AuthContextProvider';
import { useContext } from 'react';

export function useAuthState() {
  const context = useContext(AuthStateContext);
  if (typeof context === 'undefined') {
    throw new Error('AuthStateContext must be used within a AuthProvider');
  }

  return context;
}

export function useAuthDispatch() {
  const context = useContext(AuthDispatchContext);

  if (typeof context === 'undefined') {
    throw new Error('useAuthDispatch must be used within a AuthProvider');
  }

  return context;
}
