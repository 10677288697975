import ContentList from 'components/ContentList';
import styled from 'styled-components';

export default function LikeResult({ title, data, type }) {
  return (
    <LikeResultWrapper>
      <Title>{title}</Title>
      <ContentList contents={data} type={type} />
    </LikeResultWrapper>
  );
}
const Title = styled.div`
  font-size: 1.3em;
  font-weight: bold;
  color: ${({ theme }) => theme.gray};
  margin-bottom: -23px;
`;

const LikeResultWrapper = styled.div`
  margin-bottom: 30px;
`;
