import ContentList from 'components/ContentList';
import styled from 'styled-components';

export default function SearchResult({ title, data, type }) {
  return (
    <SearchResultWrapper>
      <Title>{title}</Title>
      <ContentList contents={data} useTitleHighlight={true} type={type} />
    </SearchResultWrapper>
  );
}
const Title = styled.div`
  font-size: 1.3em;
  font-weight: bold;
  color: ${({ theme }) => theme.gray};
  margin-bottom: -23px;
`;

const SearchResultWrapper = styled.div`
  margin-bottom: 30px;
`;
