import { Columns } from 'react-bulma-components';
import styled from 'styled-components';

import CategoryItem from './CategoryItem';

const { Column } = Columns;

export default function CategoryRow({ type, category2, name, child }) {
  return (
    <CategoryRowWrapper>
      {name && <Name>{name}</Name>}
      <Columns>
        {child.map((item, index) => (
          <ColumnInline
            key={index}
            mobile={{
              size: 6,
            }}
            tablet={{
              only: true,
              size: 6,
            }}
            desktop={{
              only: true,
              size: 4,
            }}
            widescreen={{
              size: 3,
            }}
          >
            <CategoryItem
              type={type}
              {...(type === 'video' ? { category2, category3: name } : { category2: item.name })}
              {...item}
            />
          </ColumnInline>
        ))}
      </Columns>
    </CategoryRowWrapper>
  );
}

const CategoryRowWrapper = styled.div`
  flex: 1;
  margin-top: 100px;

  &:first-child {
    margin-top: 40px;
  }
  &:last-child {
    margin-bottom: 100px;
  }
`;
const Name = styled.div`
  font-size: 20px;
  font-weight: bold;
  color: #1d1d1f;
  margin-bottom: 10px;
`;
const ColumnInline = styled(Column)`
  display: inline-block;
`;
