import Comment from './Comment';

export default function CommentList({ comments, boardId }) {
  return (
    <>
      {comments.map((comment, index) => (
        <Comment key={index} index={index} {...comment} boardId={boardId} />
      ))}
    </>
  );
}
