import styled from 'styled-components';

import MenuItem from './MenuItem';

export default function SideBar({ data, currentCategory, type, initState }) {
  return (
    <SideBarWrapper>
      {data.map((item, index) => (
        <MenuItem key={index} {...item} currentCategory={currentCategory} type={type} initState={initState} />
      ))}
    </SideBarWrapper>
  );
}

const SideBarWrapper = styled.div`
  width: 175px;
`;
