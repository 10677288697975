import { Columns } from 'react-bulma-components';
import styled, { keyframes } from 'styled-components';

export default function SkeletonTable() {
  const skeletons = [];
  for (let i = 0; i < 11; i++) {
    skeletons.push(
      <Columns key={i}>
        <StyledColumn>
          <SkeletonCell style={{ width: 48 }} />
        </StyledColumn>
        <StyledColumn>
          <SkeletonCell style={{ width: 140 }} />
        </StyledColumn>
        <StyledColumn>
          <SkeletonCell />
        </StyledColumn>
        <StyledColumn>
          <SkeletonCell />
        </StyledColumn>
        <StyledColumn>
          <SkeletonCell />
        </StyledColumn>
      </Columns>
    );
  }

  return <>{skeletons}</>;
}

const shineLines = keyframes`
  0% {
    background-position: -100px
  }
  40%, 100% {
    background-position: 140px
  }

`;

const StyledColumn = styled(Columns.Column)`
  height: 60px;
  display: flex;
  align-items: center;
`;

const SkeletonCell = styled.div`
  display: inline-block;
  width: 80px;
  margin: auto;
  height: 16px;
  border-radius: 8px;
  background-color: #ddd;
  background-image: linear-gradient(90deg, #ddd 0px, #e8e8e8 40px, #ddd 80px);
  background-size: 600px;
  animation: ${shineLines} 1.6s infinite linear;
`;
