import api from 'api';
import { camelize, snakize } from 'utils/obj';

export const requestNoticeBoardList = async (params, options) => {
  const { data, error } = await api({
    method: 'get',
    url: params?.myUrl ?? '/board-notice',
    params: snakize(params),
    ...options,
  });

  return {
    data: camelize(data),
    error,
    requestName: 'GET_BOARD_NOTICE',
  };
};

export const requestBoardList = async (params, options) => {
  const { data, error } = await api({
    method: 'get',
    url: params?.myUrl ?? '/dolbom-board',
    params: snakize(params),
    ...options,
  });

  return {
    data: camelize(data),
    error,
    requestName: 'GET_BOARD_LIST',
  };
};

export const requestBoardPost = async (id, options) => {
  const { data, error } = await api({
    method: 'get',
    url: `/dolbom-board/${id}`,
    ...options,
  });

  return {
    data: data ? camelize(data) : null,
    error,
    requestName: 'GET_BOARD',
  };
};

export const requestBoardWrite = async (params) => {
  const { data, error } = await api({
    method: 'post',
    url: '/dolbom-board',
    data: snakize(params),
  });

  return {
    data,
    error,
    requestName: 'POST_BOARD',
  };
};

export const requestBoardEdit = async ({ boardId, ...params }) => {
  const { data, error } = await api({
    method: 'patch',
    url: `/dolbom-board/${boardId}`,
    data: snakize(params),
  });

  return {
    data,
    error,
    requestName: 'EDIT_BOARD',
  };
};

export const requestBoardDelete = async (params) => {
  const { data, error } = await api({
    method: 'delete',
    url: `/dolbom-board/${params?.id}`,
  });

  return {
    data,
    error,
    requestName: 'DELETE_BOARD',
  };
};

/* Comment */
export const requestPostComment = async ({ boardId, parentId = null, content }) => {
  const { data, error } = await api({
    method: 'post',
    url: `/dolbom-board/${boardId}/comment`,
    data: {
      parent_id: parentId,
      content,
    },
  });

  return {
    data,
    error,
    requestName: 'POST_BOARD_COMMENT',
  };
};

export const requestEditComment = async ({ id, content }) => {
  const { data, error } = await api({
    method: 'patch',
    url: `/dolbom-board/comment/${id}`,
    data: {
      content,
    },
  });

  return {
    data,
    error,
    requestName: 'EDIT_BOARD_COMMENT',
  };
};

export const requestDeleteComment = async ({ id }) => {
  const { data, error } = await api({
    method: 'delete',
    url: `/dolbom-board/comment/${id}`,
  });

  return {
    data,
    error,
    requestName: 'DELETE_BOARD_COMMENT',
  };
};
