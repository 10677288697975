import { requestCategories } from 'api';
import BodyTitle from 'components/BodyTitle';
import CategoryList from 'components/CategoryList';
import SideBar from 'components/SideBar';
import { useFetch } from 'hooks/useFetch';
import { Container } from 'react-bulma-components';
import styled from 'styled-components';

const params = { category1: '퀴즈' };

export default function QuizCategoryPage() {
  const { data } = useFetch(requestCategories, params);
  const { list: categoryList = [] } = data || {};

  const categoryMenuList = [
    { child: categoryList, id: 21, name: '5분 퀴즈', parent_id: 1, priority: 1, thumbnail_url: null },
  ];

  const subCategoryList = [{ name: '', child: categoryList }] || [];

  return (
    <QuizCategoryPageWrapper>
      <SideBar data={categoryMenuList} currentCategory={'5분 퀴즈'} type={'quiz'} />
      <Body>
        <BodyTitle title={'5분 퀴즈'} paths={['홈', '5분 퀴즈']} />
        <CategoryList type={'quiz'} data={subCategoryList} />
      </Body>
    </QuizCategoryPageWrapper>
  );
}

const QuizCategoryPageWrapper = styled(Container)`
  width: 100%;
  display: flex;
  padding-top: 40px;
`;
const Body = styled.div`
  flex: 1;
  padding: 0 30px;
`;
