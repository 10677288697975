import { Columns } from 'react-bulma-components';
import styled from 'styled-components';

import EventItem from './EventItem';

const { Column } = Columns;

export default function EventList({ list }) {
  return (
    <EventListWrapper>
      <Columns>
        {list.map((item, index) => (
          <ColumnInline key={index} size={6}>
            <EventItem event={item} />
          </ColumnInline>
        ))}
      </Columns>
    </EventListWrapper>
  );
}

const EventListWrapper = styled.div`
  margin-top: 30px;
`;

const ColumnInline = styled(Column)`
  display: inline-block;
`;
