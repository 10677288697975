import styled from 'styled-components';

export const ActionButton = styled.div`
  cursor: pointer;
  font-size: 14px;
  text-decoration: underline;
`;

export const EditButton = styled(ActionButton)`
  margin-right: 20px;
`;
export const DeleteButton = styled(ActionButton)``;
