export function formatDate(date, format = 'yyyy-MM-dd hh:mm') {
  var yyyy = `${date.getFullYear()}`;
  var MM = `${date.getMonth() + 1}`.padStart(2, '0');
  var dd = `${date.getDate()}`.padStart(2, '0');
  var hh = `${date.getHours()}`.padStart(2, '0');
  var mm = `${date.getMinutes()}`.padStart(2, '0');
  var ss = `${date.getSeconds()}`.padStart(2, '0');

  return format
    .replace('yyyy', yyyy)
    .replace('MM', MM)
    .replace('dd', dd)
    .replace('hh', hh)
    .replace('mm', mm)
    .replace('ss', ss);
}

export function getWeek(dayjsDate) {
  //토, 일 을 제외한 달력배열 생성
  const cal = [
    [0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0],
  ];
  let first_day = dayjsDate.date(1);
  let last_day = dayjsDate.daysInMonth();
  let row = 0;
  let col = first_day.get('day');

  // 시작일을 1일으로 하되
  let start_day = 1;

  if (col === 0) {
    // 일요일이면 월요일부터 시작하도록 2일로 조정
    start_day = 2;
  } else if (col === 6) {
    // 시작일이 토요일이면 2일 미뤄서 월요일부터 시작
    col = 0;
    start_day = 3;
  } else {
    //시작일이 평일이면 -1 해줘서 일요일이 없는 달력을 만든다.
    col = col - 1;
  }
  // 1~ 마지막날 까지 돌면서 토요일을 만나면 강제로 다음주로 바꿔버린다.
  for (var i = start_day; i <= last_day; i++) {
    if (dayjsDate.date(i).get('day') === 6) {
      row = row + 1;
      col = 0;
    } else if (dayjsDate.date(i).get('day') === 0) {
      // 일요일을 만나면 아무것도 하지 않고 ++ 만 하므로 넘어간다.
    } else {
      // 평일을 만나면 달력을 쓰고 다음 요일로 옮겨간다.
      cal[row][col] = i;
      col = col + 1;
    }
  }
  //배열은 최대 5이고 최소4이므로, 마지막 배열이 모두 0이면 삭제한다.
  if (cal[cal.length - 1].every((a) => a === 0)) {
    cal.splice(0, -1);
  }

  //* 여기서부터는 주어진날 짜를 찾는다. 달력을순회하면서
  let break_flag = false;
  let week_num = 0;
  for (var r = 0; r < 5; r++) {
    for (var c = 0; c < 5; c++) {
      // 현재날짜가 달력보다 커지는 순간의 ROW를 가져온다.
      if (cal[r][c] >= dayjsDate.get('date')) {
        break_flag = true;
        week_num = r;
        break;
      }
    }
    if (break_flag === true) {
      break;
    }
  }
  //결과
  return week_num;
}
