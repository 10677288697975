import { requestBoardWrite } from 'api';
import BodyTitle from 'components/BodyTitle/index';
import ERROR_REQUEST from 'constants/errorMessages';
import { Container } from 'react-bulma-components';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import BoardForm from '../components/BoardForm';

export default function TeacherBoardWrite() {
  const history = useHistory();
  const handleSubmit = (data) => {
    if (data?.content.length < 1) {
      alert('내용을 입력해주세요.');
      return;
    }

    (async () => {
      const {
        data: response,
        error,
        requestName,
      } = await requestBoardWrite({
        ...data,
      });

      if (error) {
        alert(ERROR_REQUEST[requestName][error.response.status]);
        return;
      }

      if (response) {
        history.push('/teacher/board');
      }
    })();
  };

  return (
    <TeacherBoardWriteWrapper breakpoint="desktop" max={true}>
      <BodyTitle paths={['홈', '선생님 공간']} title="글쓰기" />
      <BoardForm onSubmit={handleSubmit} />
    </TeacherBoardWriteWrapper>
  );
}

const TeacherBoardWriteWrapper = styled(Container)`
  padding: 40px 0 80px;
`;
