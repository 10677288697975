import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { requestEditUser } from 'api';
import BodyTitle from 'components/BodyTitle';
import Button from 'components/Button';
import EventReceptionModal from 'components/EventReceptionModal';
import { ForwardedInput } from 'components/Form';
import SchoolSearchModal from 'components/SchoolSearchModal';
import ERROR_REQUEST from 'constants/errorMessages';
import { AuthActions } from 'context/AuthContextProvider';
import { useAuthDispatch, useAuthState } from 'hooks/useAuthContext';
import * as R from 'ramda';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Block, Box, Columns, Container, Form, Icon, Tag } from 'react-bulma-components';
import { FormProvider, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { autoHyphen } from 'utils/str';

const { Field, Label, Control, Help } = Form;

export default function ProfileMain() {
  const [isEventModalVisible, setEventModalVisible] = useState(false);
  const [isSchoolSearchModalVisible, setSchoolSearchModalVisible] = useState(false);
  const user = useAuthState();
  const dispatch = useAuthDispatch();
  const usingFormDatas = useMemo(() => {
    const pickedDatas = R.pick(['name', 'hp', 'schoolName', 'schoolClassName', 'mailling', 'sms'], user);

    const booleaned = {
      ...pickedDatas,
      mailling: !!pickedDatas.mailling,
      sms: !!pickedDatas.sms,
    };

    return R.evolve(pickedDatas, booleaned);
  }, [user]);

  const methods = useForm({
    mode: 'onBlur',
    defaultValues: { ...usingFormDatas },
  });
  const { register, watch, formState, setValue, reset } = methods;
  const { errors } = formState;

  const openEventModal = useCallback(() => {
    setEventModalVisible(true);
  }, []);

  const closeEventModal = useCallback(() => {
    setEventModalVisible(false);
  }, []);

  const openSchoolModal = useCallback(() => {
    setSchoolSearchModalVisible(true);
  }, []);

  const closeSchoolModal = useCallback(() => {
    setSchoolSearchModalVisible(false);
  }, []);

  const handleFocus = () => {
    openSchoolModal();
  };

  const handleReceptionChange = (e) => {
    if (!!e.target.checked === false) {
      openEventModal();
    }
  };

  const editUser = (data) => {
    (async () => {
      const { data: response, error, requestName } = await requestEditUser(data);

      if (error) {
        alert(ERROR_REQUEST[requestName][error.response.status]);
        return;
      }

      if (response) {
        dispatch({
          type: AuthActions.SET_PROFILE,
          payload: response,
        });
        alert('프로필 수정이 완료되었습니다.');
      }
    })();
  };

  const handleSubmit = (data) => {
    editUser(data);
  };

  const handleOk = () => {
    closeEventModal();
    setValue('mailling', true);
    setValue('sms', true);
  };

  useEffect(() => {
    reset(usingFormDatas);
  }, [usingFormDatas, reset]);

  const interestContents = [user.interest1, user.interest2, user.interest3, user.interest4, user.interest5].filter(
    Boolean
  );

  return (
    <StyledContainer py={6} breakpoint="desktop" max={true}>
      <BodyTitle paths={['홈', '마이페이지', '프로필편집']} title="프로필 편집" />
      <FormProvider {...methods}>
        <ResponsiveBox>
          <form onSubmit={methods.handleSubmit((data) => handleSubmit(data))}>
            <Field>
              <Label>이메일</Label>
              <Columns vCentered>
                <Columns.Column size={9}>
                  <Control>
                    <ForwardedInput disabled value={user.email} />
                  </Control>
                </Columns.Column>
                <Columns.Column size={3}>
                  <Link to="/mypage/profile/edit-email">
                    <Button type="button" block>
                      변경하기
                    </Button>
                  </Link>
                </Columns.Column>
              </Columns>
            </Field>
            <Field>
              <Label>비밀번호</Label>
              <Control fullwidth>
                <Columns>
                  <Columns.Column size={3}>
                    <Link to="/mypage/profile/edit-pw">
                      <Button type="button" block>
                        변경하기
                      </Button>
                    </Link>
                  </Columns.Column>
                </Columns>
              </Control>
            </Field>
            <Field>
              <Label>이름</Label>
              <Control>
                <ForwardedInput
                  {...register('name', {
                    required: '이름은 필수 입력사항입니다.',
                  })}
                  defaultValue={user.name}
                  placeholder="이름을 입력해주세요."
                />
              </Control>
              {errors.name && <Help color="danger">{errors.name?.message}</Help>}
            </Field>
            <Field>
              <Label>휴대폰 번호</Label>
              <Columns vCentered>
                <Columns.Column size={9}>
                  <Control>
                    <ForwardedInput value={autoHyphen(watch('hp') || '')} disabled />
                  </Control>
                </Columns.Column>
                <Columns.Column size={3}>
                  <Link to="/mypage/profile/edit-hp">
                    <Button block type="button">
                      변경하기
                    </Button>
                  </Link>
                </Columns.Column>
              </Columns>
            </Field>
            <Field>
              <Label>학교</Label>
              <DoubleControl fullwidth>
                <ForwardedInput
                  {...register('schoolName', {
                    required: '학교명은 필수 입력사항입니다.',
                  })}
                  defaultValue={user.schoolName}
                  type="text"
                  placeholder="학교명을 입력해주세요."
                  onFocus={handleFocus}
                  readOnly
                />
                <Icon align="right" size="small" color="black">
                  <FontAwesomeIcon icon={faSearch} style={{ color: '#999' }} />
                </Icon>
              </DoubleControl>
              <Control fullwidth>
                <ForwardedInput
                  {...register('schoolClassName', {
                    required: '반은 필수 입력사항입니다.',
                  })}
                  defaultValue={user.schoolClassName}
                  type="text"
                  placeholder="반을 입력해주세요."
                />
              </Control>
            </Field>
            <Field>
              <Label pb={4}>정보 수신 여부</Label>

              <Control pb={3} display="flex" justify-content="space-around">
                <label style={{ paddingRight: 4 }}>이메일</label>
                <input
                  {...register('mailling')}
                  onChange={handleReceptionChange}
                  type="checkbox"
                  style={{ marginTop: 3, marginRight: 16 }}
                />
                <label style={{ paddingRight: 4 }}>SMS</label>
                <input style={{ marginTop: 3 }} {...register('sms')} onChange={handleReceptionChange} type="checkbox" />
              </Control>
            </Field>
            <Field>
              <Label>관심 있는 콘텐츠</Label>
              <Block display="flex" alignItems="top" justifyContent="space-between">
                <div
                  style={{
                    lineHeight: 2.4,
                  }}
                >
                  {renderInterestContents(interestContents)}
                </div>
                <div>
                  <Link to="/mypage/profile/interest-contents">
                    <Button ml={4} size="small" type="button">
                      수정
                    </Button>
                  </Link>
                </div>
              </Block>
            </Field>
            <Columns pt={6}>
              <Columns.Column>
                <Button
                  block
                  type="button"
                  onClick={() => {
                    reset(usingFormDatas);
                  }}
                >
                  취소하기
                </Button>
              </Columns.Column>
              <Columns.Column>
                <EditButton block noBorder type="submit">
                  변경하기
                </EditButton>
              </Columns.Column>
            </Columns>
          </form>
        </ResponsiveBox>

        {isEventModalVisible && (
          <EventReceptionModal
            onClose={closeEventModal}
            onOk={handleOk}
            visible={isEventModalVisible}
            closeText="닫기"
          />
        )}
        {isSchoolSearchModalVisible && (
          <SchoolSearchModal inputName={'schoolName'} visible={isSchoolSearchModalVisible} onClose={closeSchoolModal} />
        )}
      </FormProvider>
    </StyledContainer>
  );
}

const renderInterestContents = (interestContents) =>
  interestContents.length === 0 ? (
    <span>등록된 관심 콘텐츠가 없습니다.</span>
  ) : (
    interestContents.map((interest) => (
      <Tag mr={4} color="primary" size="medium" key={interest}>
        {interest}
      </Tag>
    ))
  );

const StyledContainer = styled(Container)`
  @media screen and (min-width: 1024px) {
    max-width: 600px;
  }
`;

const ResponsiveBox = styled(Box)`
  width: 600px;
  margin: 30px auto 60px;
  padding: 40px 80px;
  .field .label {
    padding-top: 16px;
  }
  @media screen and (max-width: 1023px) {
    width: 100%;
    box-shadow: none;
    border: none;
    padding: 0;
  }
`;

const DoubleControl = styled(Control)`
  padding-bottom: 10px;
`;

const EditButton = styled(Button)`
  background-color: ${({ theme }) => theme.yellow};
`;
