import ArrowDownIcon from 'assets/landing/arrow-down.svg';
import HeroBackground from 'assets/landing/hero-background.jpg';
import ButtonWithSignUpModal from 'pages/LandingPage/components/ButtonToExplore';
import React, { useEffect, useState } from 'react';
import { Container, Heading, Hero } from 'react-bulma-components';
import styled, { keyframes } from 'styled-components';
import { getAosAnimation } from 'utils/animation-util';

const aosAnimation = getAosAnimation('fade-up', 600, 'ease');

export default function HeroSection() {
  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    setAnimate(true);
  }, []);

  return (
    <HeroHeight800 size={'fullheight'}>
      <Background bgImage={HeroBackground} animate={animate}></Background>
      <HeroBody>
        <CenterContainer breakpoint="desktop" max={true}>
          <WhiteHeading subtitle {...aosAnimation}>
            돌봄교실에 <BrUnderTablet /> 필요한 모든 것
          </WhiteHeading>
          <KeyColorHeading weight={'bold'} {...aosAnimation} data-aos-delay="100">
            마이돌봄
          </KeyColorHeading>
          <div {...aosAnimation} data-aos-delay="200">
            <ButtonWithSignUpModal noBorder />
          </div>
        </CenterContainer>
      </HeroBody>
      <ArrowDownWrapper>
        <ArrowDown src={ArrowDownIcon} alt="arrow-down" />
      </ArrowDownWrapper>
    </HeroHeight800>
  );
}

const HeroHeight800 = styled(Hero)`
  position: relative;
  background-color: #000;
  overflow: hidden;
`;

const Background = styled.div`
  position: absolute;
  background-image: url(${({ bgImage }) => bgImage});
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  transition: all 8s ease-out;

  ${({ animate }) =>
    animate
      ? `
    transform: scale(1.0);
  `
      : `
    transform: scale(1.15);
  `}
`;
const HeroBody = styled(Hero.Body)`
  padding-top: 36px;
  padding-bottom: 36px;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 1;
`;
const BrUnderTablet = styled.br`
  display: none;
  @media screen and (max-width: 1023px) {
    display: inline;
  }
`;
const BigText = styled(Heading)`
  white-space: nowrap;

  @media screen and (max-width: 1023px) {
    font-size: 4.5rem !important;
  }
  @media screen and (max-width: 768px) {
    font-size: 3.2rem !important;
  }
`;
const WhiteHeading = styled(BigText)`
  color: #fff;
  padding-bottom: 1.8rem;
  font-size: 52px;
  font-weight: normal;
`;
const KeyColorHeading = styled(BigText)`
  font-size: 92px;
  font-weight: bold;
  color: ${(props) => props.theme.yellow};
  padding-bottom: 3rem;
`;
const CenterContainer = styled(Container)`
  text-align: center;
`;

const DownAnimation = keyframes`
  0% {
    transform: translateY(-2px);
  }
  50% {
    transform: translateY(5px);
  }
  100% {
    transform: translateY(-2px);
  }
`;
const ArrowDownWrapper = styled.div`
  position: absolute;
  bottom: 80px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  z-index: 2;

  animation: ${DownAnimation} 1.5s ease infinite;
`;

const ArrowDown = styled.img``;
