import { CommentsDispatchContext, CommentsStateContext } from 'context/CommentsContextProvider';
import { useContext } from 'react';

export function useCommentsState() {
  const context = useContext(CommentsStateContext);
  if (typeof context === 'undefined') {
    throw new Error('CommentsStateContext must be used within a CommentsProvider');
  }

  return context;
}

export function useCommentsDispatch() {
  const context = useContext(CommentsDispatchContext);

  if (typeof context === 'undefined') {
    throw new Error('useCommentsDispatch must be used within a CommentsProvider');
  }

  return context;
}
