import { requestCategories } from 'api';
import BodyTitle from 'components/BodyTitle';
import CategoryList from 'components/CategoryList';
import SideBar from 'components/SideBar';
import { useFetch } from 'hooks/useFetch';
import { Container } from 'react-bulma-components';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

const params = { category1: '영상' };

export default function VideoCategoryPage() {
  const { category2 = '프로그램' } = useParams();
  const { data } = useFetch(requestCategories, params);
  const { list: categoryList = [] } = data || {};

  const subCategoryList = categoryList.find((item) => item.name === category2)?.child || [];

  return (
    <VideoCategoryPageWrapper>
      <SideBar data={categoryList} currentCategory={category2} type={'video'} />
      <Body>
        <BodyTitle title={category2} paths={['홈', '돌봄 영상']} />
        <CategoryList type={'video'} category2={category2} data={subCategoryList} />
      </Body>
    </VideoCategoryPageWrapper>
  );
}

const VideoCategoryPageWrapper = styled(Container)`
  width: 100%;
  display: flex;
  padding-top: 40px;
`;
const Body = styled.div`
  flex: 1;
  padding: 0 30px;
`;
