import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'dayjs/locale/ko';

import ChromeDownloadModal from 'components/ChromeDownloadModal';
import theme from 'constants/theme';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from 'styled-components';

import App from './App';
import GlobalStyle from './GlobalStyle';

dayjs.locale('ko');
dayjs.extend(relativeTime);

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <App />
      <ChromeDownloadModal />
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
