import Button from 'components/Button';
import { ForwardedInput } from 'components/Form';
import { lazy, Suspense, useRef } from 'react';
import { Form } from 'react-bulma-components';
import { FormProvider, useForm } from 'react-hook-form';
import styled from 'styled-components';

const { Field, Help, Control } = Form;

const LazySummerNote = lazy(() => import('components/DolbomSummerNote'));

export default function BoardForm({ title, content, isEditMode = false, onSubmit }) {
  const summernoteRef = useRef(null);
  const methods = useForm();
  const { register, formState } = methods;
  const { errors } = formState;

  const handleSubmit = (data) => {
    const content = summernoteRef.current.editor.summernote('code');

    onSubmit &&
      onSubmit({
        title: data.title,
        content,
      });
  };

  return (
    <BoardFormWrapper>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(handleSubmit)}>
          <Field>
            <Control>
              <ForwardedInput
                {...register('title', {
                  required: '제목은 필수입력사항입니다.',
                })}
                placeholder="제목을 입력해주세요."
                defaultValue={title}
                color={errors?.title && 'danger'}
                maxLength={50}
              />
            </Control>
            {errors?.title && <Help color="danger">{errors?.title?.message}</Help>}
          </Field>
          <Suspense fallback="loading...">
            <LazySummerNote content={content} summernoteRef={summernoteRef} />
          </Suspense>
          <AlignCenter>
            <Button type="submit">{isEditMode ? '수정하기' : '글쓰기'}</Button>
          </AlignCenter>
        </form>
      </FormProvider>
    </BoardFormWrapper>
  );
}

const BoardFormWrapper = styled.div`
  padding: 40px 0 0;
`;

const AlignCenter = styled.div`
  text-align: center;
`;
