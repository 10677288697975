import Footer from 'components/Footer';
import Header from 'components/Header';
import { Route } from 'react-router-dom';
import styled from 'styled-components';

export default function ServiceLayout({
  children: propsChildren,
  path,
  component: Component,
  componentProps,
  onlyAuth,
  ...rest
}) {
  return (
    <Route path={path} {...rest}>
      {(props) => (
        <Wrapper>
          <Header onlyAuth={onlyAuth} />
          <ComponentWrapper>
            <Component {...props} {...componentProps} />
          </ComponentWrapper>
          <Footer />
        </Wrapper>
      )}
    </Route>
  );
}

const Wrapper = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

const ComponentWrapper = styled.div`
  margin-top: 53px;
  flex: 1;
`;
