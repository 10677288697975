import api from 'api';
import { camelize } from 'utils/obj';

export async function requestCategories(params) {
  const { data, error } = await api({
    method: 'get',
    url: '/category',
    params,
  });

  return { data, error, requestName: 'GET_CATEGORY' };
}

export async function requestInterestCategories(params) {
  const { data, error } = await api({
    method: 'get',
    url: '/category/interest',
    params,
  });

  return { data: data ? camelize(data) : null, error, requestName: 'GET_INTEREST_CATEGORIES' };
}
