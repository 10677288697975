import { Heading } from 'react-bulma-components';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export default function EventItem({ event }) {
  const { id, title, thumbnail_url, start_date, end_date } = event;

  return (
    <EventItemWrapper>
      <Link to={`/event/${id}`}>
        <Thumbnail src={thumbnail_url} />
        <Title size={5}>{title}</Title>
        <Period>
          {start_date} ~ {end_date > '9999-01-01' ? '' : end_date}
        </Period>
      </Link>
    </EventItemWrapper>
  );
}

const EventItemWrapper = styled.div``;
const Thumbnail = styled.div`
  background: #dddddd url('${({ src }) => src}') no-repeat center center;
  background-size: cover;
  padding-bottom: 50%;
`;
const Title = styled(Heading)`
  padding-left: 5px;
  margin-top: 16px;
  margin-bottom: 0 !important;
`;
const Period = styled.div`
  padding-left: 5px;
  color: ${({ theme }) => theme.gray};
  margin-top: 7px;
`;
