import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getRecomendContentsByDate, requestContent, requestContents } from 'api/requests/contentsRequests';
import BodyTitle from 'components/BodyTitle';
import ContentList from 'components/ContentList';
import Modal from 'components/Modal';
import { useFetch } from 'hooks/useFetch';
import * as R from 'ramda';
import { useMemo, useState } from 'react';
import { Columns, Container, Icon, Tag } from 'react-bulma-components';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { parseQuery } from 'utils/parseQuery';

import VisualContents from './components/VisualContents';

const DownloadIcon = () => (
  <Icon style={{ width: '1rem', height: '1rem' }}>
    <FontAwesomeIcon icon={faDownload} style={{ color: '#fff', width: 9, height: 11 }} />
  </Icon>
);

export default function VideoDetailPage({ match }) {
  const location = useLocation();
  const [showTutorialModal, setShowTutorialModal] = useState(false);
  const query = useMemo(() => parseQuery(window.location.search), []);
  const isQueryEmpty = R.keys(query)[0].length === 0;
  const id = useMemo(() => parseInt(match?.params?.id, 10), [match]);
  const params = useMemo(
    () => ({
      id,
    }),
    [id]
  );

  const { data: contentsData } = useFetch(requestContent, params);
  const contentsQuery = useMemo(() => {
    const existCategory = R.dropLastWhile(R.isNil, [
      contentsData?.category1,
      contentsData?.category2,
      contentsData?.category3,
      contentsData?.category4,
    ]);

    return {
      category: R.isEmpty(existCategory) ? null : existCategory[existCategory.length - 1],
      ...(contentsData && { offset: 0, limit: 8 }),
    };
  }, [contentsData]);
  const { data: recomendedData } = useFetch(
    isQueryEmpty ? requestContents : getRecomendContentsByDate,
    isQueryEmpty ? contentsQuery : query
  );

  const type = location.pathname.includes('video') ? 'video' : 'quiz';

  return (
    <>
      {contentsData && (
        <Body breakpoint="desktop" max={true}>
          <BodyTitle
            title={contentsData?.title}
            paths={[
              '홈',
              isQueryEmpty ? (type === 'video' ? '돌봄 영상' : '5분 퀴즈') : '월간 프로그램',
              contentsData?.title,
            ]}
          />
          <ProgramDetail>
            <Columns style={{ paddingBottom: 100 }}>
              <VisualContents contentsData={contentsData} />
              <Columns.Column>
                <ProgramContents>
                  <Columns pb={4}>
                    <Columns.Column>
                      <Paragraph>{contentsData?.content}</Paragraph>
                    </Columns.Column>
                  </Columns>
                  <div style={{ paddingBottom: 40 }}>
                    {contentsData?.activityDoc && (
                      <YellowTag
                        mr={5}
                        onClick={() => {
                          window.open(contentsData.activityDoc);
                        }}
                      >
                        <DownloadIcon />
                        <span>활동지</span>
                      </YellowTag>
                    )}
                    {contentsData?.guideDoc && (
                      <YellowTag
                        mr={5}
                        onClick={() => {
                          window.open(contentsData.guideDoc);
                        }}
                      >
                        <DownloadIcon />
                        <span>지도서</span>
                      </YellowTag>
                    )}
                    {contentsData?.designDoc && (
                      <YellowTag
                        mr={5}
                        onClick={() => {
                          window.open(contentsData.designDoc);
                        }}
                      >
                        <DownloadIcon />
                        <span>도안</span>
                      </YellowTag>
                    )}
                    {contentsData?.quizDoc && (
                      <YellowTag
                        mr={5}
                        onClick={() => {
                          window.open(contentsData.quizDoc);
                        }}
                      >
                        <DownloadIcon />
                        <span>퀴즈</span>
                      </YellowTag>
                    )}
                  </div>
                  <Materials>
                    {(contentsData?.sellMaterial || contentsData?.selfMaterial) && (
                      <MaterialsHeading>
                        <GiftEmoji>🎁</GiftEmoji>
                        <span style={{ verticalAlign: 'middle' }}>필요한 준비물</span>
                      </MaterialsHeading>
                    )}
                    <table>
                      <tbody>
                        {contentsData?.sellMaterial && (
                          <MaterialsContents>
                            <MaterialTitle>구매 준비물</MaterialTitle>
                            <MaterialSubTitle>
                              <div>{contentsData?.sellMaterial}</div>
                              <a
                                style={{ color: 'blue' }}
                                rel="noreferrer"
                                href={contentsData?.sellMaterialUrl}
                                target="_blank"
                              >
                                &gt;&gt; 준비물 구매하러 가기
                              </a>
                            </MaterialSubTitle>
                          </MaterialsContents>
                        )}
                        {contentsData?.selfMaterial && (
                          <MaterialsContents>
                            <MaterialTitle>개별 준비물</MaterialTitle>
                            <MaterialSubTitle>{contentsData?.selfMaterial}</MaterialSubTitle>
                          </MaterialsContents>
                        )}
                      </tbody>
                    </table>
                  </Materials>
                  {type === 'quiz' && (
                    <HowTo
                      onClick={() => {
                        setShowTutorialModal(true);
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        id="Layer_1"
                        enableBackground="new 0 0 496 496"
                        height="512"
                        viewBox="0 0 496 496"
                        width="512"
                      >
                        <path
                          fill="#efc238"
                          d="m247.04 109c-77.87 0-142.006 63.76-142.005 142.001 0 52.349 28.664 100.291 74.803 125.116 5.056 2.721 8.197 8.071 8.197 13.964v54.919c0 26.467 21.533 48 48 48h22c26.467 0 48-21.533 48-48v-54.918c0-5.874 3.276-11.298 8.55-14.155 45.922-24.876 74.45-72.746 74.45-124.927 0-78.487-63.527-142-141.995-142zm10.995 352h-22c-8.822 0-16-7.178-16-16v-39h54v39c0 8.822-7.178 16-16 16zm41.308-113.209c-10.679 5.785-18.588 15.197-22.521 26.209h-59.539c-3.888-11.012-11.713-20.375-22.283-26.062-35.754-19.237-57.965-56.381-57.965-96.937 0-60.472 49.876-110.428 110.845-109.999 60.188.452 109.155 49.796 109.155 109.997 0 40.426-22.107 77.514-57.692 96.792zm-60.841-161.179c3.375 8.167-.51 17.523-8.677 20.897-11.982 4.951-21.531 14.715-26.198 26.787-2.453 6.346-8.509 10.235-14.927 10.235-11.199 0-18.977-11.28-14.92-21.773 7.811-20.205 23.784-36.542 43.825-44.823 8.168-3.377 17.522.51 20.897 8.677zm-168.502 64.353c0 8.836-7.164 16-16 16h-38c-8.836 0-16-7.164-16-16s7.164-16 16-16h38c8.836 0 16 7.164 16 16zm52.11 125.876c6.249 6.248 6.249 16.379 0 22.627l-26.87 26.87c-3.124 3.125-7.219 4.687-11.313 4.687-14.125 0-21.423-17.205-11.313-27.313l26.87-26.87c6.247-6.25 16.378-6.25 22.626-.001zm109.925-319.841v-38c0-8.836 7.164-16 16-16s16 7.164 16 16v38c0 8.836-7.164 16-16 16s-16-7.164-16-16zm-159.373 41.24c-6.249-6.248-6.249-16.379 0-22.627 6.248-6.249 16.379-6.249 22.627 0l26.87 26.87c10.112 10.111 2.809 27.313-11.313 27.313-4.095 0-8.189-1.562-11.313-4.687zm301.228 4.292 26.87-26.87c6.248-6.249 16.379-6.249 22.627 0 6.249 6.248 6.249 16.379 0 22.627l-26.87 26.87c-3.124 3.125-7.219 4.687-11.313 4.687-14.126 0-21.424-17.205-11.314-27.314zm122.11 148.503c0 8.836-7.164 16-16 16h-38c-8.836 0-16-7.164-16-16s7.164-16 16-16h38c8.836 0 16 7.163 16 16zm-72.662 152.725c6.249 6.248 6.249 16.379 0 22.627-6.247 6.248-16.379 6.249-22.627 0l-26.87-26.87c-6.249-6.248-6.249-16.379 0-22.627 6.248-6.249 16.379-6.249 22.627 0z"
                        />
                      </svg>
                      <QuizGuideButton>돌봄 게임 200% 활용하기!</QuizGuideButton>
                    </HowTo>
                  )}
                </ProgramContents>
              </Columns.Column>
            </Columns>
            {!!recomendedData?.list?.length && (
              <>
                <h6 style={{ fontWeight: 'bold' }}>
                  {isQueryEmpty
                    ? '관련 콘텐츠를 확인해보세요'
                    : `${query.month}월 ${query.date}일 돌봄 연간 프로그램을 확인하세요!`}
                </h6>
                <ContentList
                  contents={recomendedData?.list.filter((item) => item.id !== id) || []}
                  type={type}
                  search={location.search}
                  noTag={isQueryEmpty}
                />
              </>
            )}
          </ProgramDetail>
          <Modal visible={showTutorialModal} onClose={() => setShowTutorialModal(false)}>
            <iframe
              title="tutorial"
              src="https://player.vimeo.com/video/584814775?h=47d554e1a4&color=ff9933&title=0&byline=0&portrait=0"
              width="640"
              height="480"
              frameBorder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              allowFullScreen
            ></iframe>
          </Modal>
        </Body>
      )}
    </>
  );
}

const Body = styled(Container)`
  padding-top: 40px;
`;

const ProgramDetail = styled.div`
  padding-top: 55px;
`;

const ProgramContents = styled.div``;

const Paragraph = styled.p`
  font-size: 18px;
  line-height: 1.4;
  color: #1d1d1f;
  @media screen and (max-width: 768px) {
    font-size: 16px;
  }
`;

const YellowTag = styled(Tag)`
  cursor: pointer;
  background-color: ${({ theme }) => theme.yellow} !important;
  border-radius: 14px !important;
  span:not(.icon) {
    color: #fff;
    font-weight: bold;
  }
`;

const Materials = styled.div``;

const MaterialsHeading = styled.div`
  padding-bottom: 20px;
  span:not(.icon) {
    font-weight: bold;
    padding-left: 4px;
  }
`;

const MaterialsContents = styled.tr`
  font-size: 14px;
  padding-bottom: 10px;
  padding-left: 5px;
`;

const MaterialTitle = styled.td`
  line-height: 1.3;
  width: 100px;
  padding-right: 18px;
  font-weight: bold;
  padding-bottom: 10px;
  vertical-align: top;
`;

const MaterialSubTitle = styled.td`
  line-height: 1.3;
  padding-bottom: 10px;
  vertical-align: top;
`;

const HowTo = styled.div`
  font-size: 1.2rem;
  font-weight: 500;
  font-color: #666;
  cursor: pointer;

  svg {
    width: 30px;
    height: auto;
    margin-right: 10px;
    vertical-align: middle;
  }
`;

const GiftEmoji = styled.span`
  margin-right: 5px;
  font-size: 1.3rem;
  vertical-align: middle;
`;

const QuizGuideButton = styled.span`
  font-style: italic;
  text-decoration: underline;
  vertical-align: middle;

  &:hover {
    color: ${({ theme }) => theme.hoveredYellow};
  }
`;
