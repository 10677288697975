const ERROR_REQUEST = {
  // userRequests.js
  FIND_ID: {
    404: '계정이 존재하지 않습니다.',
  },
  RESET_PW: {
    409: 'SMS 인증정보가 올바르지 않습니다.',
    404: '일치하는 아이디가 없습니다.',
  },
  LOGIN: {}, //프론트에서 ALERT이 아닌 밑에 표시하는 방법이라 확인필요
  GET_SCHOOL: {}, //없음
  SIGN_UP: {
    400: 'SMS 인증정보가 올바르지 않습니다.',
    410: '추천인이 존재하지 않습니다.',
    409: '이메일 또는 아이디가 이미 존재합니다.',
  },
  EDIT_INTEREST: {}, //없음
  DUPLICATION_CHECK_ID: {
    409: '이미 존재하는 아이디 입니다.',
  },
  RECOMMENDED_BY_CHECK: {
    404: '일치하는 회원이 존재하지 않습니다.',
  },
  GET_USER: {},
  EDIT_USER: {},
  EDIT_EMAIL: {
    409: '이메일이 이미 존재합니다.',
  },
  EDIT_PASSWORD: {
    409: '현재 비밀번호와 일치하지 않습니다.',
  },
  JOIN_SMS_REQUEST: {
    409: '이미 등록된 번호입니다.',
  },
  JOIN_SMS_REQUEST_VERIFY: {
    404: '인증번호가 잘못되었습니다.',
    409: '인증 시간이 지났습니다. 다시 인증 요청해주세요.',
  },
  RESET_PW_SMS_REQUEST: {},
  RESET_PW_SMS_REQUEST_VERIFY: {
    404: '인증번호가 잘못되었습니다.',
    409: '인증 시간이 지났습니다. 다시 인증 요청해주세요.',
  },
  // contentsRequest.js
  GET_SCHEDULE: {},
  GET_RECOMMENDED: {},
  GET_CONTENTS: {},
  // eventRequests.js
  GET_EVENT_LIST: {},
  GET_EVENT: {},
  // boardRequests.js
  GET_BOARD_LIST: {},
  GET_BOARD: {},
  POST_BOARD: {},
  EDIT_BOARD: {
    403: '수정 권한이 없습니다. 본인의 글만 수정할 수 있습니다.',
  },
  DELETE_BOARD: {
    403: '삭제 권한이 없습니다. 본인의 글만 삭제할 수 있습니다.',
  },
  POST_BOARD_COMMENT: {},
  EDIT_BOARD_COMMENT: {
    403: '수정 권한이 없습니다. 본인의 글만 수정할 수 있습니다.',
  },
  DELETE_BOARD_COMMENT: {
    403: '삭제 권한이 없습니다. 본인의 글만 삭제할 수 있습니다.',
  },
  // categoryRequests.js
  GET_CATEGORY: {},
  GET_INTEREST_CATEGORIES: {},
};

export default ERROR_REQUEST;
