import styled from 'styled-components';

import CategoryRow from './CategoryRow';

export default function CategoryList({ type, category2, data }) {
  return (
    <CategoryListWrapper>
      {data.map((item, index) => (
        <CategoryRow key={index} type={type} category2={category2} {...item} />
      ))}
    </CategoryListWrapper>
  );
}

const CategoryListWrapper = styled.div``;
