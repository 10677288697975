import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { requestCheckDuplicationId, requestCheckRecommendedBy, requestSignUp } from 'api';
import Button from 'components/Button';
import Countdown from 'components/Countdown';
import EventReceptionModal from 'components/EventReceptionModal';
import { ForwardedInput } from 'components/Form';
import FormLabel from 'components/Form/FormLabel';
import SchoolSearchModal from 'components/SchoolSearchModal';
import ERROR_REQUEST from 'constants/errorMessages';
import { AuthActions } from 'context/AuthContextProvider';
import { useAuthDispatch } from 'hooks/useAuthContext';
import { useSignUpSMSRequest } from 'hooks/useSMSRequest';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { Box, Columns, Form, Heading, Icon } from 'react-bulma-components';
import { FormProvider, useForm } from 'react-hook-form';
import { Prompt } from 'react-router-dom';
import styled from 'styled-components';
import { autoHyphen } from 'utils/str';
import { idRegex } from 'utils/validate';

import { SIGN_UP_INPUTS, SIGN_UP_STEP } from '../constants';
import Terms from './Terms';

const { Field, Control, Help: BulmaHelp } = Form;

export default function CreateForm({ onNext }) {
  const [isEventModalVisible, setEventModalVisible] = useState(false);
  const [isSchoolSearchModalVisible, setSchoolSearchModalVisible] = useState(false);
  const [isDuplicationChecked, setDuplicationChecked] = useState(false);
  const [isRecommendedChecked, setRecommendedChecked] = useState(false);
  const [schoolSearchModalType, setSchoolSearchModalType] = useState('');
  const { isPhoneVerifyRequested, smsAuthToken, onRequestSMSVerify, onRequestSMSVerifyCode } = useSignUpSMSRequest();
  const [timerNo, setTimerNo] = useState(0);

  const methods = useForm({
    mode: 'onBlur',
  });

  const { register, watch, getValues, setValue, setFocus } = methods;

  const dispatch = useAuthDispatch();

  const idRef = useRef({});
  const recommendedNameRef = useRef({});
  // passwordConfirm 시 값 비교하기 위함
  const password = useRef({});
  password.current = watch('password', '');

  const openEventModal = useCallback(() => {
    setEventModalVisible(true);
  }, []);

  const closeEventModal = useCallback(() => {
    setEventModalVisible(false);
  }, []);

  const openSchoolModal = useCallback(() => {
    setSchoolSearchModalVisible(true);
  }, []);

  const closeSchoolModal = useCallback(() => {
    setSchoolSearchModalVisible(false);
  }, []);

  const handleDuplicationCheck = useCallback(() => {
    const loginId = getValues(SIGN_UP_INPUTS.id.name);

    if (idRegex.test(loginId)) {
      (async () => {
        const { data, error, requestName } = await requestCheckDuplicationId({
          loginId,
        });

        if (error) {
          setDuplicationChecked(false);
          alert(ERROR_REQUEST[requestName][error.response.status]);
          return;
        }

        if (data) {
          idRef.current = loginId;
          setDuplicationChecked(true);
        }
      })();
    }
  }, [getValues]);

  const handleRequestCodeClick = useCallback(async () => {
    const hp = getValues(SIGN_UP_INPUTS.hp.name);

    await onRequestSMSVerify(hp, () => {
      setFocus(SIGN_UP_INPUTS.hpVerify.name);
    });

    setTimerNo((no) => no + 1);
  }, [getValues, setFocus, onRequestSMSVerify]);

  const handlePhoneVerifyCode = useCallback(() => {
    const [hp, code] = getValues([SIGN_UP_INPUTS.hp.name, SIGN_UP_INPUTS.hpVerify.name]);

    onRequestSMSVerifyCode({
      hp,
      code,
    });
  }, [onRequestSMSVerifyCode, getValues]);

  const openModalByType = (type) => {
    setSchoolSearchModalType(type);
    openSchoolModal();
  };

  const signUp = useCallback(
    (data) => {
      closeEventModal();

      const validDatas = R.omit(
        ['privacyOther', 'termsOfService', 'domain', 'hpVerify', 'eventReception', 'privacy', 'agreeAllTerms'],
        data
      );

      (async () => {
        const {
          data: res,
          error,
          requestName,
        } = await requestSignUp({
          recommendedBySchool: '',
          recommendedByName: '',
          recommendedById: '',
          ...validDatas,
          ip: '',
          nick: '',
          zip1: '',
          zip2: '',
          addr1: '',
          addr2: '',
          addr3: '',
          addr_jibeon: '',
          ...(data.eventReception
            ? {
                mailling: true,
                sms: true,
              }
            : {
                mailling: false,
                sms: false,
              }),
          accountType: '',
          schoolTel: '',
          schoolFax: '',
          smsAuthToken,
        });

        if (error) {
          alert(ERROR_REQUEST[requestName][error.response.status]);
          return;
        }
        if (res) {
          dispatch({
            type: AuthActions.SET_AUTH,
            payload: res,
          });
          onNext(SIGN_UP_STEP.INTEREST_CONTENTS);
        }
      })();
    },
    [onNext, closeEventModal, dispatch, smsAuthToken]
  );

  const handleCheckRecommendedClick = () => {
    const [recommendedBySchool, recommendedByName] = getValues(['recommendedBySchool', 'recommendedByName']);
    (async () => {
      const { data, error } = await requestCheckRecommendedBy({
        recommendedBySchool,
        recommendedByName,
      });

      if (error) {
        alert('일치하는 회원이 없습니다.');
        setRecommendedChecked(false);
        return;
      }

      if (data) {
        recommendedNameRef.current = recommendedByName;
        setValue('recommendedById', data.id);
        setRecommendedChecked(true);
      }
    })();
  };

  const handleSubmit = (data, eventReceptionAgree) => {
    if (!isDuplicationChecked) {
      alert('아이디 중복확인을 먼저 해주세요.');
      return;
    }

    if (eventReceptionAgree) {
      const eventReceptionChangedData = {
        ...data,
        eventReception: eventReceptionAgree === 'true' ? true : false,
      };
      signUp(R.evolve(eventReceptionChangedData, data));

      return;
    }

    if (!data.eventReception) {
      openEventModal();
    } else {
      signUp(data);
    }
  };

  const { errors, isDirty } = methods.formState;

  const errorMessages = {
    id: errors.id?.message,
    email: errors.email?.message,
    password: errors.password
      ? errors.password.message
      : errors.passwordConfirm
      ? errors.passwordConfirm.message
      : '8자 이상을 입력해주세요.',
    name: errors.name?.message,
    hp: errors.hp ? errors.hp.message : '※ 타인의 휴대번호를 무단으로 도용 시 민, 형사상의 책임을 부담합니다.',
  };

  if (isDuplicationChecked) {
    if (idRef.current !== watch(SIGN_UP_INPUTS.id.name)) {
      setDuplicationChecked(false);
    }
  }
  if (isRecommendedChecked) {
    if (recommendedNameRef.current !== watch(SIGN_UP_INPUTS.recommendedByName.name)) {
      setRecommendedChecked(false);
    }
  }

  const checkKeyDown = (e) => {
    if (e.code === 'Enter') e.preventDefault();
  };

  const hasSmsAuthToken = useMemo(() => smsAuthToken.length > 0, [smsAuthToken]);

  return (
    <>
      <Prompt when={isDirty} message="저장되지 않은 정보가 있습니다. 정말 나가시겠습니까?" />
      <FormProvider {...methods}>
        <HeadingWrapper>
          <Heading>무료로 가입하기</Heading>
        </HeadingWrapper>
        <form onSubmit={methods.handleSubmit((data) => handleSubmit(data))} onKeyDown={checkKeyDown}>
          <ResponsiveBox>
            <Field>
              <FormLabel required>{SIGN_UP_INPUTS.id.label}</FormLabel>
              <Columns vCentered>
                <Columns.Column size={9}>
                  <Control>
                    <ForwardedInput
                      {...register(SIGN_UP_INPUTS.id.name, SIGN_UP_INPUTS.id.registerOptions)}
                      placeholder={SIGN_UP_INPUTS.id.placeholder}
                      type="text"
                      color={errors.id && 'danger'}
                    />
                  </Control>
                </Columns.Column>
                <Columns.Column size={3}>
                  <Button
                    block
                    onClick={handleDuplicationCheck}
                    type="button"
                    disabled={isDuplicationChecked}
                    color={isDuplicationChecked && 'success'}
                  >
                    {isDuplicationChecked ? '인증완료' : '중복확인'}
                  </Button>
                </Columns.Column>
              </Columns>
              {errors.id && <Help color="danger">{errorMessages[SIGN_UP_INPUTS.id.name]}</Help>}
            </Field>
            <Field>
              <FormLabel required>{SIGN_UP_INPUTS.email.label}</FormLabel>
              <Control fullwidth>
                <ForwardedInput
                  {...register(SIGN_UP_INPUTS.email.name, SIGN_UP_INPUTS.email.registerOptions)}
                  placeholder={SIGN_UP_INPUTS.email.placeholder}
                  type="text"
                  color={errors.email && 'danger'}
                />
              </Control>
              <Help color={errors.email && 'danger'}>{errorMessages[SIGN_UP_INPUTS.email.name]}</Help>
            </Field>

            {/* 비밀번호 */}
            <Field>
              <FormLabel required>{SIGN_UP_INPUTS.password.label}</FormLabel>
              <Control fullwidth>
                <ForwardedInput
                  {...register(SIGN_UP_INPUTS.password.name, SIGN_UP_INPUTS.password.registerOptions)}
                  placeholder={SIGN_UP_INPUTS.password.placeholder}
                  type="password"
                  color={errors.password && 'danger'}
                />
              </Control>
              <Help color={errors.email && 'danger'}>{errorMessages[SIGN_UP_INPUTS.email.name]}</Help>
            </Field>

            {/* 비밀번호 확인 */}
            <Field>
              <FormLabel required>{SIGN_UP_INPUTS.passwordConfirm.label}</FormLabel>
              <Control fullwidth>
                <ForwardedInput
                  {...register(SIGN_UP_INPUTS.passwordConfirm.name, {
                    required: '비밀번호를 한번 더 입력해주세요',
                    validate: (value) => password.current === value || '입력한 비밀번호와 다릅니다.',
                  })}
                  placeholder={SIGN_UP_INPUTS.passwordConfirm.placeholder}
                  type="password"
                  color={errors.passwordConfirm && 'danger'}
                />
              </Control>
              <Help color={(errors.password || errors.passwordConfirm) && 'danger'}>
                {errorMessages[SIGN_UP_INPUTS.password.name]}
              </Help>
            </Field>

            <Field>
              <FormLabel required>{SIGN_UP_INPUTS.name.label}</FormLabel>
              <Control>
                <ForwardedInput
                  {...register(SIGN_UP_INPUTS.name.name, SIGN_UP_INPUTS.name.registerOptions)}
                  placeholder={SIGN_UP_INPUTS.name.placeholder}
                  type="text"
                  color={errors.name && 'danger'}
                />
              </Control>
              {errors.name && <Help color="danger">{errorMessages[SIGN_UP_INPUTS.name.name]}</Help>}
            </Field>
            <Field>
              <FormLabel required>{SIGN_UP_INPUTS.hp.label}</FormLabel>
              <PhoneColumns vCentered>
                <Columns.Column size={8}>
                  <Control>
                    <PhoneInput
                      {...register(SIGN_UP_INPUTS.hp.name, SIGN_UP_INPUTS.hp.registerOptions)}
                      placeholder={SIGN_UP_INPUTS.hp.placeholder}
                      type="text"
                      // readOnly={isPhoneVerifyRequested}
                      value={autoHyphen(watch(SIGN_UP_INPUTS.hp.name) || '')}
                      color={errors.hp && 'danger'}
                    />
                  </Control>
                </Columns.Column>
                <Columns.Column>
                  <Button
                    block
                    // disabled={isPhoneVerifyRequested}
                    onClick={handleRequestCodeClick}
                    type="button"
                    color={hasSmsAuthToken && 'primary'}
                  >
                    {hasSmsAuthToken ? '인증 완료' : isPhoneVerifyRequested ? '재전송' : '인증번호발송'}
                  </Button>
                </Columns.Column>
              </PhoneColumns>
              {isPhoneVerifyRequested ? (
                !hasSmsAuthToken ? (
                  <Columns vCentered>
                    <Columns.Column size={8}>
                      <Control>
                        <ForwardedInput
                          placeholder={SIGN_UP_INPUTS.hpVerify.placeholder}
                          {...register(SIGN_UP_INPUTS.hpVerify.name, SIGN_UP_INPUTS.hpVerify.registerOptions)}
                        />
                      </Control>
                    </Columns.Column>
                    <Columns.Column>
                      <Button type="button" block onClick={handlePhoneVerifyCode}>
                        확인
                      </Button>
                    </Columns.Column>
                    <Columns.Column style={{ paddingLeft: 0 }}>
                      <Countdown remainTime={180} timerNo={timerNo} />
                    </Columns.Column>
                  </Columns>
                ) : null
              ) : null}
            </Field>

            {/* 학교명 */}
            <Field>
              <FormLabel required>{SIGN_UP_INPUTS.schoolName.label}</FormLabel>
              <Control fullwidth>
                <ForwardedInput
                  {...register(SIGN_UP_INPUTS.schoolName.name, SIGN_UP_INPUTS.schoolName.registerOptions)}
                  placeholder={SIGN_UP_INPUTS.schoolName.placeholder}
                  type="text"
                  onClick={() => openModalByType(SIGN_UP_INPUTS.schoolName.name)}
                  readOnly
                  color={errors.schoolName && 'danger'}
                />
                <Icon align="right" size="small" color="black">
                  <FontAwesomeIcon icon={faSearch} style={{ color: '#999' }} />
                </Icon>
              </Control>
              {errors.schoolName && <Help color={errors.schoolName && 'danger'}>{errors.schoolName?.message}</Help>}
            </Field>

            {/* 반 정보 */}
            <Field>
              <FormLabel>{SIGN_UP_INPUTS.schoolClassName.label}</FormLabel>
              <Control fullwidth>
                <ForwardedInput
                  {...register(SIGN_UP_INPUTS.schoolClassName.name, SIGN_UP_INPUTS.schoolClassName.registerOptions)}
                  placeholder={SIGN_UP_INPUTS.schoolClassName.placeholder}
                  type="text"
                />
              </Control>
            </Field>

            {/* 추천인 학교 */}
            <Field>
              <FormLabel>{SIGN_UP_INPUTS.recommendedBySchool.label}</FormLabel>
              <Control fullwidth>
                <ForwardedInput
                  {...register(SIGN_UP_INPUTS.recommendedBySchool.name)}
                  placeholder={SIGN_UP_INPUTS.recommendedBySchool.placeholder}
                  onClick={() => openModalByType(SIGN_UP_INPUTS.recommendedBySchool.name)}
                  readOnly
                  type="text"
                />
                <Icon align="right" size="small" color="black">
                  <FontAwesomeIcon icon={faSearch} style={{ color: '#999' }} />
                </Icon>
              </Control>
            </Field>

            {/* 추천인 이름 */}
            <Field>
              <FormLabel>{SIGN_UP_INPUTS.recommendedByName.label}</FormLabel>
              <Columns>
                <Columns.Column size={9}>
                  <Control fullwidth>
                    <ForwardedInput
                      {...register(SIGN_UP_INPUTS.recommendedByName.name)}
                      placeholder={SIGN_UP_INPUTS.recommendedByName.placeholder}
                      type="text"
                    />
                  </Control>
                </Columns.Column>
                <Columns.Column size={3}>
                  <Button
                    block
                    onClick={handleCheckRecommendedClick}
                    type="button"
                    disabled={isRecommendedChecked}
                    color={isRecommendedChecked && 'success'}
                  >
                    {isRecommendedChecked ? '확인완료' : '조회하기'}
                  </Button>
                </Columns.Column>
              </Columns>
            </Field>

            <Terms />
            <JoinButton rounded type="submit">
              무료 회원 가입하기
            </JoinButton>
          </ResponsiveBox>
        </form>
        {isEventModalVisible && (
          <EventReceptionModal
            visible={isEventModalVisible}
            onClose={methods.handleSubmit((data) => handleSubmit(data, 'false'))}
            onOk={methods.handleSubmit((data) => handleSubmit(data, 'true'))}
            closeText="혜택 없이 가입"
          />
        )}
        {isSchoolSearchModalVisible && (
          <SchoolSearchModal
            inputName={schoolSearchModalType}
            visible={isSchoolSearchModalVisible}
            onClose={closeSchoolModal}
          />
        )}
      </FormProvider>
    </>
  );
}

CreateForm.propTypes = {
  onNext: PropTypes.func,
};

const PhoneColumns = styled(Columns)`
  margin-bottom: 0 !important;
`;

const ResponsiveBox = styled(Box)`
  width: 650px;
  margin: 30px auto 60px;
  padding: 20px 80px;
  .field .label {
    padding-top: 16px;
  }
  @media screen and (max-width: 1023px) {
    width: 100%;
    box-shadow: none;
    border: none;
    padding: 0;
  }
`;

const HeadingWrapper = styled.div`
  width: 650px;
  margin: 15px auto 10px;
  padding-left: 80px;

  @media screen and (max-width: 1023px) {
    padding-left: 0;
    width: 100%;
    text-align: center;
  }
`;

const JoinButton = styled(Button)`
  display: block;
  margin: 40px auto;
  font-size: 24px;
  padding: 0 40px;
  background-color: ${({ theme }) => theme.yellow};
`;

const Help = styled(BulmaHelp)`
  margin-top: 10px;
  padding-left: 5px;
`;

const PhoneInput = styled(ForwardedInput)`
  &:read-only {
    cursor: not-allowed;
    background-color: whitesmoke;
    border-color: whitesmoke;
    color: #7a7a7a;
  }
`;
