import { faArrowDown, faArrowUp, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { requestCategories } from 'api';
import { requestContents } from 'api/requests/contentsRequests';
import BodyTitle from 'components/BodyTitle';
import ContentList from 'components/ContentList';
import SideBar from 'components/SideBar';
import { useFetch } from 'hooks/useFetch';
import { useMemo, useRef, useState } from 'react';
import { Container, Form, Icon, Pagination } from 'react-bulma-components';
import { Link, useParams } from 'react-router-dom';
import styled from 'styled-components';

const { Input } = Form;

const PAGE_SIZE = 12;
const categoryParams = { category1: '영상' };

export default function VideoListPage({ match }) {
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState(''); // view_count_desc, like_count_desc, difficulty_desc
  const [searchKeyword, setSearchKeyword] = useState('');
  const searchInputRef = useRef({});
  const { category2, category3, category4 } = useParams();
  const { data } = useFetch(requestCategories, categoryParams);
  const { list: categoryList = [] } = data || {};

  const contentsParams = useMemo(
    () => ({
      category: category4,
      ...(sort ? { sorted: sort } : {}),
      limit: PAGE_SIZE,
      offset: (page - 1) * PAGE_SIZE,
      ...(searchKeyword && { keyword: searchKeyword }),
    }),
    [category4, page, searchKeyword, sort]
  );
  const { data: contents, loading: contentsLoading } = useFetch(requestContents, contentsParams);

  const category4List = useMemo(
    () =>
      (categoryList.find((cate) => cate.name === category2)?.child || []).find((cate) => cate.name === category3)
        ?.child || [],
    [category2, category3, categoryList]
  );

  const sortLogic = (sortType) => {
    setPage(1);
    sort === `${sortType}_desc` ? setSort(`${sortType}_asc`) : setSort(`${sortType}_desc`);
  };

  const handlePaginationChange = (value) => {
    setPage(value);
  };

  const initState = () => {
    setSearchKeyword('');
    setPage(1);
    searchInputRef.current.value = '';
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      setPage(1);
      setSearchKeyword(e.target.value);
    }
  };

  const handleSearch = () => {
    setPage(1);
    setSearchKeyword(searchInputRef.current.value);
  };

  return (
    <VideoListPageWrapper>
      <SideBar data={categoryList} currentCategory={category3} type={'video'} initState={initState} />
      <Body>
        <BodyTitle title={category3} paths={['홈', '돌봄 영상']} />
        <Filters>
          <SearchInputWrapper>
            <SearchInputInner>
              <Input
                domRef={searchInputRef}
                type="text"
                onKeyPress={handleKeyPress}
                placeholder="검색어를 입력해주세요"
              />
              <SearchButton>
                <Icon align="right" size="small" color="black" onClick={handleSearch}>
                  <FontAwesomeIcon icon={faSearch} style={{ color: '#999' }} />
                </Icon>
              </SearchButton>
            </SearchInputInner>
          </SearchInputWrapper>
          <SubCategories>
            {category4List.map((cate) => (
              <SubCategoryButton
                to={`/video/list/${category2}/${category3}/${cate.name}`}
                onClick={() => {
                  setPage(1);
                }}
                selected={cate.name === category4}
                key={cate.name}
              >
                {cate.name}
              </SubCategoryButton>
            ))}
          </SubCategories>
          <SortButtons>
            <SortButton selected={sort === ''} onClick={() => setSort('')}>
              <SortText>기본순</SortText>
            </SortButton>
            <SortButton selected={sort.includes('difficulty')} onClick={() => sortLogic('difficulty')}>
              <SortText>난이도</SortText>
              {sort.includes('difficulty') && (sort === 'difficulty_desc' ? <ArrowDown /> : <ArrowUp />)}
            </SortButton>
            <SortButton selected={sort.includes('view_count')} onClick={() => sortLogic('view_count')}>
              <SortText>조회수</SortText>
              {sort.includes('view_count') && (sort === 'view_count_desc' ? <ArrowDown /> : <ArrowUp />)}
            </SortButton>
            <SortButton selected={sort.includes('like_count')} onClick={() => sortLogic('like_count')}>
              <SortText>좋아요</SortText>
              {sort.includes('like_count') && (sort === 'like_count_desc' ? <ArrowDown /> : <ArrowUp />)}
            </SortButton>
          </SortButtons>
        </Filters>
        {!!contents?.list?.length && !contentsLoading && (
          <>
            <ContentList contents={contents?.list} type={'video'} noTag />
            <StyledPagination
              autoHide={false}
              current={page}
              total={Math.ceil(contents?.total / PAGE_SIZE)}
              next="다음"
              previous="이전"
              align="center"
              showPrevNext={false}
              delta={2}
              showFirstLast={true}
              onChange={handlePaginationChange}
            />
          </>
        )}
        {!contents?.list?.length && !contentsLoading && <NoContents>콘텐츠가 없습니다.</NoContents>}
      </Body>
    </VideoListPageWrapper>
  );
}

const ArrowUp = ({ filled }) => {
  return (
    <Icon style={{ width: 15, height: 15 }}>
      <FontAwesomeIcon icon={faArrowUp} style={filled} />
    </Icon>
  );
};

const ArrowDown = ({ filled }) => {
  return (
    <Icon style={{ width: 15, height: 15 }}>
      <FontAwesomeIcon icon={faArrowDown} style={filled} />
    </Icon>
  );
};

const VideoListPageWrapper = styled(Container)`
  width: 100%;
  display: flex;
  padding: 40px 0 60px;
`;

const Body = styled.div`
  flex: 1;
  padding: 0 30px;
`;

const Filters = styled.div`
  padding-top: 40px;
  display: flex;
  justify-content: space-between;
  position: relative;
`;

const SearchInputWrapper = styled.div`
  width: 300px;
  position: absolute;
  bottom: 100%;
  right: 0;
`;

const SearchInputInner = styled.div`
  position: relative;

  & input {
    background-color: #f5f5f7;
    border: none;
    box-shadow: none;
    padding: 14px 35px 13px 20px;
  }
`;

const SearchButton = styled.div`
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  display: flex;
  padding: 0 10px;
  align-items: center;
`;

const SubCategories = styled.div`
  display: flex;
`;

const SubCategoryButton = styled(Link)`
  padding: 10px;
  margin-right: 10px;
  border-radius: 6px;
  background-color: #ececec;
  transition: background 0.2s ease, color 0.1s ease;
  font-size: 0.95rem;
  font-weight: bold;
  line-height: 1.25;

  ${({ selected, theme }) =>
    selected
      ? `
        color: #ffffff !important;
        background-color: ${theme.yellow};`
      : 'color: #4a4a4c;'}

  &:hover {
    color: #ffffff;
    background-color: ${({ theme }) => theme.yellow};
  }
`;

const SortButtons = styled.div`
  display: flex;
  align-items: center;
`;

const SortButton = styled.div`
  display: flex;
  align-items: center;
  padding: 6px 16px;
  margin-right: 10px;
  border-radius: 20px;
  background-color: #f5f5f7;
  transition: background 0.2s ease, color 0.1s ease;
  font-size: 0.85rem;
  line-height: 1.25;
  cursor: pointer;

  ${({ selected, theme }) =>
    selected
      ? `
        color: #ffffff !important;
        font-weight: bold;
        background-color: ${theme.yellow};`
      : 'color: #4a4a4c;'}
`;

const SortText = styled.span`
  margin-bottom: 2px;
  margin-right: 5px;

  &:last-child {
    margin-right: 0;
  }
`;

const NoContents = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
  font-size: 1.2rem;
  opacity: 0.5;
  color: ${({ theme }) => theme.gray};
`;

const StyledPagination = styled(Pagination)`
  .pagination-link.is-current {
    background-color: ${({ theme }) => theme.yellow};
    border: none;
    font-weight: bold;
  }
`;
