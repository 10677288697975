import { Heading } from 'react-bulma-components';
import styled from 'styled-components';

import LoginForm from './components/LoginForm';

export default function LoginPage() {
  return (
    <LoginPageWrapper>
      <HeadingWrapper>
        <Heading>로그인</Heading>
      </HeadingWrapper>
      <LoginForm />
    </LoginPageWrapper>
  );
}

const LoginPageWrapper = styled.div`
  padding: 50px 0;
`;

const HeadingWrapper = styled.div`
  width: 24rem;
  margin: 0 auto 30px;
  padding-left: 20px;
  @media screen and (max-width: 768px) {
    width: 100%;
    margin-bottom: 10px;
    padding-left: 0;
    text-align: center;
  }
`;
