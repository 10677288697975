import Button from 'components/Button';
import { Box, Heading } from 'react-bulma-components';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export default function FindIdResult({ data }) {
  return (
    <ResponsiveBox>
      <Heading>아이디 찾기</Heading>
      <p>개인정보 도용에 대한 피해방지를 위하여 아이디 끝 두자리는 **처리 합니다.</p>
      <ResultBox>
        <p>
          회원님의 아이디는 <Red>{data.email} </Red>로 등록되어 있습니다.
        </p>
        <p>
          가입일자는 <Red>{data.createdAt}</Red>입니다.
        </p>
      </ResultBox>
      <ButtonsWrap>
        <Link to="/login">
          <Button rounded>로그인</Button>
        </Link>
        <Link to="/find-pw">
          <Button rounded>비밀번호 찾기</Button>
        </Link>
      </ButtonsWrap>
    </ResponsiveBox>
  );
}

const ResponsiveBox = styled(Box)`
  width: 36rem;
  margin: 0 auto;
  @media screen and (max-width: 768px) {
    box-shadow: none;
    width: 100%;
  }
`;

const ResultBox = styled.div`
  margin: 32px auto;
  text-align: center;
  padding: 28px;
  border-top: 1px solid #e9e9e9;
  border-bottom: 1px solid #e9e9e9;
  p {
    padding-bottom: 12px;
  }
`;

const Red = styled.span`
  color: red;
  font-weight: bold;
`;

const ButtonsWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  a:first-child {
    margin-right: 20px;
  }
`;
