import { produce } from 'immer';
import { createContext, useReducer } from 'react';

export const CommentsStateContext = createContext();
export const CommentsDispatchContext = createContext();

export const CommentsActions = {
  SET_COMMENTS: 'SET_COMMENTS',
  ADD_COMMENT: 'ADD_COMMENT',
  REPLY_COMMENT: 'REPLY_COMMENT',
  EDIT_COMMENT: 'EDIT_COMMENT',
  DELETE_COMMENT: 'DELETE_COMMENT',
};

const initialState = {
  comments: [],
};

const commentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case CommentsActions.SET_COMMENTS: {
      return produce(state, (draft) => {
        draft.comments = action.payload.comments;
      });
    }
    case CommentsActions.ADD_COMMENT: {
      return produce(state, (draft) => {
        draft.comments.push(action.payload.comment);
      });
    }
    case CommentsActions.REPLY_COMMENT: {
      return produce(state, (draft) => {
        const parentComment = draft.comments.find((comment) => comment.id === action.payload.parentId);
        if (!parentComment) {
          return;
        }
        if (!parentComment.comment) {
          parentComment.comment = [];
        }
        parentComment.comment.push(action.payload.comment);
      });
    }
    case CommentsActions.EDIT_COMMENT: {
      return produce(state, (draft) => {
        if (action.payload.parentIndex) {
          draft.comments[action.payload.parentIndex].comment[action.payload.index] = {
            ...action.payload.comment,
          };
        } else {
          draft.comments[action.payload.index] = { ...draft.comments[action.payload.index], ...action.payload.comment };
        }
      });
    }
    case CommentsActions.DELETE_COMMENT: {
      return produce(state, (draft) => {
        if (action.payload.parentIndex) {
          draft.comments[action.payload.parentIndex].comment[action.payload.index] = {
            ...draft.comments[action.payload.parentIndex].comment[action.payload.index],
            isDeleted: true,
          };
        } else {
          draft.comments[action.payload.index] = { ...draft.comments[action.payload.index], isDeleted: true };
        }
      });
    }
    default: {
      return state;
    }
  }
};

export const CommentsProvider = ({ children }) => {
  const [comments, dispatch] = useReducer(commentsReducer, initialState);

  return (
    <CommentsDispatchContext.Provider value={dispatch}>
      <CommentsStateContext.Provider value={comments.comments}>{children}</CommentsStateContext.Provider>
    </CommentsDispatchContext.Provider>
  );
};
