import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { requestBoardList, requestNoticeBoardList } from 'api';
import BodyTitle from 'components/BodyTitle';
import Button from 'components/Button';
import { useAuthState } from 'hooks/useAuthContext';
import { useFetch } from 'hooks/useFetch';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Columns, Container, Form, Heading, Icon, Message, Pagination, Table } from 'react-bulma-components';
import { Link, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { formatDate } from 'utils/date';

import SkeletonTable from '../components/SkeletonTable';

const { Field, Control, Input, Select } = Form;

const PAGE_SIZE = 25;

const cacheOptions = {
  useCache: true,
};

const renderTableBody = (datas, rowHandlers, isNotice = false) => {
  const today = new Date();

  return datas?.length > 0 ? (
    datas.map((data, i) => (
      <tr
        key={i}
        className={'hoverable'}
        onClick={() => rowHandlers.onClick(data.id)}
        style={isNotice ? { fontWeight: 500, backgroundColor: '#fbfbfb' } : {}}
      >
        <td>{isNotice ? <>공지</> : data.id}</td>
        <td style={{ position: 'relative', paddingRight: 22 }}>
          {data.title} {!!data.commentCount && <CommentCount>[{data.commentCount}]</CommentCount>}
        </td>
        <td>{data.accountId}</td>
        <td>
          {formatDate(today, 'yyyyMMdd') === formatDate(new Date(data.createdAt), 'yyyyMMdd')
            ? formatDate(new Date(data.createdAt), 'hh:mm')
            : formatDate(new Date(data.createdAt), 'yyyy.MM.dd')}
        </td>
        <td>{data.viewCount}</td>
      </tr>
    ))
  ) : (
    <tr style={{ marginBottom: '30px', cursor: 'auto' }}>
      <td style={{ width: '100%', textAlign: 'center', padding: '124px 0' }} colSpan={5}>
        게시된 글이 없습니다.
      </td>
    </tr>
  );
};

export default function TeacherBoard() {
  useEffect(() => {
    /* 글쓰기 폼 모듈 preload */
    import('components/DolbomSummerNote');
  }, []);

  const history = useHistory();
  const user = useAuthState();
  const [page, setPage] = useState(1);
  const [sorted] = useState('created_at_desc');
  const [searchType, setSearchType] = useState('title');
  const [searchKeyword, setSearchKeyword] = useState('');
  const [isMyBoardListOnly, setMyBoardListOnly] = useState(false);

  const searchInputRef = useRef({});

  const params = useMemo(
    () => ({
      offset: (page - 1) * PAGE_SIZE,
      limit: PAGE_SIZE,
      sorted,
      searchType: searchKeyword ? searchType : '',
      searchKeyword,
      myUrl: isMyBoardListOnly ? '/dolbom-board/my' : null,
    }),
    [page, sorted, searchType, searchKeyword, isMyBoardListOnly]
  );

  const noticeParams = useMemo(
    () => ({
      myUrl: isMyBoardListOnly ? '/dolbom-board/my' : null,
    }),
    [isMyBoardListOnly]
  );

  const { data, error, loading } = useFetch(requestBoardList, params, cacheOptions);
  const {
    data: noticeData,
    error: noticeError,
    loading: noticeLoading,
  } = useFetch(requestNoticeBoardList, noticeParams, cacheOptions);

  const handlePaginationChange = (value) => {
    setPage(value);
  };

  const rowHandlers = {
    onClick: (id) => {
      history.push(`/teacher/board/${id}`);
    },
  };

  const toggleMyPostOnly = () => {
    setPage(1);
    setMyBoardListOnly((prevState) => !prevState);
  };

  const handleSearchTypeChange = (e) => {
    if (searchKeyword) {
      setPage(1);
    }
    setSearchType(e.target.value);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      setPage(1);
      setSearchKeyword(e.target.value);
    }
  };

  const handleSearch = () => {
    setPage(1);
    setSearchKeyword(searchInputRef.current.value);
  };

  // TODO: 에러 핸들링
  if (error || noticeError) {
    alert(error);
  }

  return (
    <TeacherBoardWrapper breakpoint="desktop" max={true}>
      <BodyTitle paths={['홈', '선생님 공간']} title="선생님 공간" />
      <BoardNotice>
        <Heading>돌봄 교실부터 나만의 일상까지 무엇이든 자유롭게 이야기 해보세요.</Heading>
        <p>돌봄 선생님들의 의견을 자유롭게 교환하는 익명 게시판입니다.</p>
        <p>타인을 비방하는 글 혹은 악의적인 글들은 삭제될 수 있습니다.</p>
        <p>허위 사실이나 타인의 명예를 훼손하는 내용이 포함된 글은 삭제될 수 있습니다.</p>
      </BoardNotice>
      <div>
        <Flex>
          <FlexItem onClick={toggleMyPostOnly}>{isMyBoardListOnly ? '전체 글 보기' : '내가 쓴 글 보기'}</FlexItem>
        </Flex>
        {loading || noticeLoading ? (
          <SkeletonTable />
        ) : (
          <div style={{ overflow: 'auto' }}>
            <StyledTable size="fullwidth">
              <colgroup>
                <col width="16%" />
                <col width="34%" />
                <col />
                <col />
                <col />
              </colgroup>
              <thead>
                <StyledTr>
                  <StyledTh>번호</StyledTh>
                  <StyledTh>제목</StyledTh>
                  <StyledTh>글쓴이</StyledTh>
                  <StyledTh>작성일</StyledTh>
                  <StyledTh>조회수</StyledTh>
                </StyledTr>
              </thead>
              <tbody>
                {page === 1 && renderTableBody(noticeData?.list, rowHandlers, true)}
                {renderTableBody(data?.list, rowHandlers)}
              </tbody>
            </StyledTable>
          </div>
        )}
        {data?.total ? (
          <TableBottom>
            <StyledPagination
              autoHide={false}
              current={page}
              total={Math.ceil(data?.total / PAGE_SIZE)}
              next="다음"
              previous="이전"
              align="center"
              showPrevNext={false}
              delta={2}
              showFirstLast={true}
              onChange={handlePaginationChange}
            />
            {user.id && (
              <Link to="/teacher/board-write">
                <Button>글쓰기</Button>
              </Link>
            )}
          </TableBottom>
        ) : (
          user.id && (
            <Link to="/teacher/board-write">
              <Button>글쓰기</Button>
            </Link>
          )
        )}
        <SearchInputWrapper>
          <Field>
            <Columns>
              <Columns.Column size={3}>
                <Control>
                  <FullWidthSelect onChange={handleSearchTypeChange}>
                    <option value="title">제목</option>
                    <option value="content">내용</option>
                    <option value="title+content">제목+내용</option>
                  </FullWidthSelect>
                </Control>
              </Columns.Column>
              <Columns.Column size={9}>
                <SearchInputInner>
                  <Input
                    domRef={searchInputRef}
                    type="text"
                    onKeyPress={handleKeyPress}
                    placeholder="검색어를 입력해주세요"
                  />
                  <SearchButton onClick={handleSearch}>
                    <Icon align="right" size="small" color="black">
                      <FontAwesomeIcon icon={faSearch} style={{ color: '#999' }} />
                    </Icon>
                  </SearchButton>
                </SearchInputInner>
              </Columns.Column>
            </Columns>
          </Field>
        </SearchInputWrapper>
      </div>
    </TeacherBoardWrapper>
  );
}

const TeacherBoardWrapper = styled(Container)`
  padding: 40px 0 80px;
`;

const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top: 6px;
  padding-bottom: 6px;
`;

const FlexItem = styled.div`
  font-size: 14px;
  cursor: pointer;
  padding: 8px;
  margin-right: 12px;
  &:hover {
    font-weight: bold;
  }
`;

const BoardNotice = styled(Message.Body)`
  padding: 35px;
  margin: 40px auto;
  border-color: ${({ theme }) => theme.yellow};
  background-color: #fff9e8;
  color: ${({ theme }) => theme.gray};
  line-height: 1.2rem;

  & h1.title {
    color: ${({ theme }) => theme.gray};
  }
`;

const StyledTable = styled(Table)`
  text-align: center;
  th:nth-child(2),
  td:nth-child(2) {
    text-align: left;
  }
  tbody {
    tr.hoverable:hover {
      background-color: #fffcf4;
    }
    tr {
      cursor: pointer;
      td {
        padding-top: 1.325em;
        padding-bottom: 1.325em;
      }
    }
  }

  @media screen and (max-width: 768px) {
    font-size: 0.825rem;
  }
`;

const StyledTr = styled.tr`
  background: #fbfbfb;
`;

const StyledTh = styled.th`
  body tr & {
    padding-top: 17px;
    padding-bottom: 17px;
    border-width: 2px 0 1px;
  }
`;

const CommentCount = styled.span`
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #999;
`;

const StyledPagination = styled(Pagination)`
  .pagination-link.is-current {
    background-color: ${({ theme }) => theme.yellow};
    border: none;
    font-weight: bold;
  }
`;

const TableBottom = styled.div`
  display: flex;
  margin-top: 30px;
  nav {
    flex: 2;
    /* button width 만큼 margin-left */
    margin-left: 75px;
  }
`;

const SearchInputWrapper = styled.div`
  width: 500px;
  margin: 0 auto;
  margin-top: 20px;
  @media screen and (max-width: 1023px) {
    width: 100%;
  }
`;

const FullWidthSelect = styled(Select)`
  width: 100%;
  select {
    width: 100%;
  }
`;

const SearchInputInner = styled.div`
  position: relative;
`;

const SearchButton = styled.div`
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  display: flex;
  padding: 0 10px;
  align-items: center;
`;
