import { Heading } from 'react-bulma-components';
import styled from 'styled-components';

function BorderBottomHeading({ text, borderColor, ...args }) {
  return (
    <HeadingWrapper {...args}>
      <HeadingText borderColor={borderColor}>{text}</HeadingText>
    </HeadingWrapper>
  );
}

const HeadingWrapper = styled(Heading)`
  text-align: center;
`;

const HeadingText = styled.span`
  display: inline-block;
  padding-bottom: 16px;
  margin-bottom: 55px;
  border-bottom: 4px solid ${({ borderColor }) => (borderColor ? borderColor : '#f5df4d')};
  color: ${({ theme }) => theme.gray};

  @media screen and (max-width: 768px) {
    font-size: 1.75rem;
  }
`;

export default BorderBottomHeading;
