import { requestFindId } from 'api';
import Button from 'components/Button';
import { ForwardedInput } from 'components/Form';
import FormLabel from 'components/Form/FormLabel';
import ERROR_REQUEST from 'constants/errorMessages';
import React from 'react';
import { Box, Form, Heading } from 'react-bulma-components';
import { FormProvider, useForm } from 'react-hook-form';
import styled from 'styled-components';
import { autoHyphen } from 'utils/str';

import { FIND_ID_STEP } from '../constants';

const { Field, Help, Control } = Form;

export default function FindIdForm({ onNext }) {
  const methods = useForm();
  const { register, watch, formState } = methods;
  const { errors } = formState;

  const handleSubmit = (data) => {
    (async () => {
      const { data: response, error, requestName } = await requestFindId(data);

      if (error) {
        alert(ERROR_REQUEST[requestName][error.response.status]);
        return;
      }

      if (response) {
        onNext(FIND_ID_STEP.RESULT, response);
      }
    })();
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(handleSubmit)}>
        <ResponsiveBox>
          <Heading>아이디 찾기</Heading>
          <p>회원정보에 등록된 정보로 아이디를 찾습니다.</p>
          <p>회원가입 정보와 다를 경우 조회가 되지 않습니다.</p>
          <Field color="yellow">
            <FormLabel required>이름</FormLabel>
            <Control>
              <ForwardedInput
                {...register('name', { required: '이름을 입력해주세요.' })}
                type="text"
                placeholder="이름을 입력해주세요."
                color={errors?.name && 'danger'}
              />
            </Control>
            {errors?.name && <Help color="danger">{errors?.name?.message}</Help>}
          </Field>
          <Field>
            <FormLabel required>핸드폰 번호</FormLabel>
            <Control>
              <ForwardedInput
                {...register('hp', {
                  required: '핸드폰 번호를 입력해주세요 ("-" 없이)',
                })}
                type="text"
                placeholder="핸드폰 번호를 입력해주세요. (번호만 입력)"
                value={autoHyphen(watch('hp') || '')}
                color={errors?.hp && 'danger'}
              />
            </Control>
            {errors?.hp && <Help color="danger">{errors?.hp?.message}</Help>}
          </Field>
          <ConfirmButton block={true} noBorder={true} type="submit">
            확인
          </ConfirmButton>
        </ResponsiveBox>
      </form>
    </FormProvider>
  );
}

const ResponsiveBox = styled(Box)`
  width: 24rem;
  margin: 0 auto;
  .field .label {
    padding-top: 16px;
  }
  @media screen and (max-width: 768px) {
    box-shadow: none;
    width: 100%;
  }
`;

const ConfirmButton = styled(Button)`
  margin-top: 12px;
  background-color: ${({ theme }) => theme.yellow};
`;
