import Logo from 'assets/images/dolbom-logo-mono.svg';
import { Columns, Container, Footer as BulmaFooter } from 'react-bulma-components';
import styled from 'styled-components';

const { Column } = Columns;

export default function Footer() {
  return (
    <StyledFooter>
      <Container breakpoint="desktop" max={true}>
        <Columns
          gap={1}
          vCentered
          style={{
            marginTop: 0,
            marginBottom: 0,
          }}
        >
          <Column size={8} style={{ paddingTop: 0, paddingBottom: 0 }}>
            <FooterButtonGroup>
              <FooterButtonWrapper>
                <FooterButton href="/">마이돌봄 소개</FooterButton>
              </FooterButtonWrapper>
              <FooterButtonWrapper>
                <FooterButton href="/terms">서비스 이용약관</FooterButton>
              </FooterButtonWrapper>
              <FooterButtonWrapper>
                <FooterButton href="/policy">개인정보 처리방침</FooterButton>
              </FooterButtonWrapper>
              <FooterButtonWrapper>
                <FooterButton
                  onClick={() => {
                    window.ChannelIO('showMessenger');
                  }}
                >
                  문의하기
                </FooterButton>
              </FooterButtonWrapper>
            </FooterButtonGroup>
            <div>
              <address>주식회사 케이앤비에듀 | 대표 유준배 | 서울특별시 서대문구 충정로7길 19-7</address>
            </div>
            <div style={{ lineHeight: 3 }}>COPYRIGHT© 2021 K&B Edu ALL RIGHTS RESERVED.</div>
          </Column>
          <Column size={4} style={{ paddingTop: 0, paddingBottom: 0 }}>
            <LogoImg src={Logo} alt="Logo" />
          </Column>
        </Columns>
      </Container>
    </StyledFooter>
  );
}

const StyledFooter = styled(BulmaFooter)`
  background-color: #f5f5f7;
  padding: 30px 20px 30px;
  font-size: 0.9rem;
  color: ${({ theme }) => theme.gray};

  @media screen and (max-width: 768px) {
    font-size: 15px;
    line-height: 20px;
  }
`;

const LogoImg = styled.img`
  @media screen and (max-width: 768px) {
    margin-top: 15px;
    width: 70%;
    max-width: 300px;
  }
`;

const FooterButtonGroup = styled.div`
  font-size: 1rem;
  font-weight: 600;
  color: #111;
  margin-bottom: 25px;
`;

const FooterButtonWrapper = styled.div`
  display: inline-block;

  &:last-child {
    margin-right: 0;
  }

  @media screen and (max-width: 768px) {
    width: 50%;
    margin-bottom: 15px;

    a {
      background: none !important;
      color: #111 !important;
      padding: 0 !important;
      border-radius: 0 !important;
    }
  }
`;

const FooterButton = styled.a`
  display: inline-block;
  margin-right: 30px;
  cursor: pointer;
`;
