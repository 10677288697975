import { Section } from 'react-bulma-components';
import styled from 'styled-components';

const AvoidFixedHeaderSection = styled(Section)`
  margin-top: 57px;
  min-height: calc(100vh - (53px + 396.75px));
  @media screen and (min-width: 769px) {
    min-height: calc(100vh - (57px + 238px));
  }
`;

export default AvoidFixedHeaderSection;
