import '@egjs/react-flicking/dist/flicking.css';
import '@egjs/flicking-plugins/dist/arrow.css';

import { Arrow, AutoPlay } from '@egjs/flicking-plugins';
import Flicking, { ViewportSlot } from '@egjs/react-flicking';
import { getEvents } from 'api/requests/eventRequests';
import { useFetch } from 'hooks/useFetch';
import { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

const plugins = [new AutoPlay({ duration: 4000 }), new Arrow()];

export default function RollingBanner() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const history = useHistory();
  const { data } = useFetch(getEvents);
  const list = data?.list || [];

  const handleFlickingChanged = useCallback((e) => {
    setCurrentIndex(e.index);
  }, []);

  const filteredList = list.filter((item) => item.banner_url);

  return (
    filteredList?.length !== 0 && (
      <FlickingWrapper>
        <StyledFlicking
          renderOnlyVisible
          circular
          deceleration={1}
          interruptable={true}
          threshold={300}
          preventClickOnDrag={true}
          onChanged={handleFlickingChanged}
          plugins={plugins}
        >
          {filteredList.map((item, index) => (
            <FlickingItem
              className="flicking-panel"
              key={index}
              image={item.banner_url}
              onClick={() => {
                if (item.type === 'internal') {
                  history.push(`/event/${item.id}`);
                } else {
                  window.open(item?.link || '', '_blank');
                }
              }}
            />
          ))}
          <ViewportSlot>
            <FlickingArrow className="flicking-arrow-prev" />
            <FlickingArrow className="flicking-arrow-next" />
          </ViewportSlot>
        </StyledFlicking>
        <FlickingDots>
          {filteredList.map((_, index) => (
            <Dot key={index} active={currentIndex === index} />
          ))}
        </FlickingDots>
      </FlickingWrapper>
    )
  );
}

const FlickingWrapper = styled.div`
  position: relative;
  padding-bottom: min(27%, 350px);
  background: #dddddd;
`;

const StyledFlicking = styled(Flicking)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const FlickingItem = styled.div`
  width: 100%;
  height: 100%;
  display: block;
  background: #dddddd url('${({ image }) => image}') no-repeat center center;
  background-size: cover;
  cursor: pointer;
`;

const FlickingArrow = styled.span`
  &:before {
    background-color: #ffffff;
  }
  &:after {
    background-color: #ffffff;
  }
`;

const FlickingDots = styled.ul`
  position: absolute;
  text-align: center;
  right: 0;
  left: 0;
  bottom: 20px;
  z-index: 3;
`;

const Dot = styled.li`
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: ${({ active }) => (active ? '#fff' : '#c5c5c5')};
  margin-right: 12px;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.5);
  &:last-child {
    margin-right: 0;
  }
`;
