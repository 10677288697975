import reviewImage1 from 'assets/landing/review1.svg';
import reviewImage2 from 'assets/landing/review2.svg';
import reviewImage3 from 'assets/landing/review3.svg';
import BorderBottomHeading from 'pages/LandingPage/components/BorderBottomHeading';
import { Columns, Container } from 'react-bulma-components';
import styled from 'styled-components';
import { getAosAnimation } from 'utils/animation-util';

import LandingReview from '../components/LandingReview';

const aosAnimation = getAosAnimation('fade-up', 600, 'ease', 180);

const { Column } = Columns;

export default function ReviewSection() {
  return (
    <ReviewSectionWrapper>
      <AbsoluteBorderBottomHeading borderColor="#F5DF4D" text="마이돌봄 생생 후기" />
      <ReviewSectionContainer breakpoint="desktop" max={true}>
        <Columns breakpoint="tablet" desktop={{ gap: 5 }} touch={{ gap: 3 }} centered={true}>
          {/* tablet 사이즈 인 경우에도 3개를 한 줄에 넣고 싶은데... */}
          <Column>
            <div {...aosAnimation} data-aos-delay="0">
              <LandingReview
                imageUrl={reviewImage1}
                reviewer="서울 N초등학교 강**선생님"
                content="항상 수업 준비 때문에 고민도 많고, 준비물이나 교구를 준비하는데 시간이 오래 걸렸는데, 이젠 너무 편리해요."
              />
            </div>
          </Column>
          <Column>
            <div {...aosAnimation} data-aos-delay="300">
              <Gap mobile={{ display: 'hidden' }} />
              <LandingReview
                imageUrl={reviewImage2}
                reviewer="천안 A초등학교 김**선생님"
                content="초성게임이나 다섯 고개 같은 게임이 많아서 아이들이 좋아해요. 직접 게임을 만들거나 찾을 필요가 없으니 선생님도 편하고 아이들 집중도도 최고네요."
              />
            </div>
          </Column>
          <Column>
            <div {...aosAnimation} data-aos-delay="200">
              <LandingReview
                imageUrl={reviewImage3}
                reviewer="창원 S초등학교 이**선생님"
                content="돌봄 선생님이 된지 얼마 안되어서 수업을 어떻게 짜야 할지 걱정이 많았어요. 매일 프로그램을 짜주고 준비물까지 편리하게 구매할 수 있어 좋아요."
              />
            </div>
          </Column>
        </Columns>
      </ReviewSectionContainer>
    </ReviewSectionWrapper>
  );
}

const ReviewSectionWrapper = styled.section`
  text-align: center;
  padding-top: 291px;
  padding-bottom: 164px;
  position: relative;
  background: #fffdf0;
  overflow: hidden;
`;

const AbsoluteBorderBottomHeading = styled(BorderBottomHeading)`
  position: absolute;
  top: 180px;
  left: 0;
  right: 0;
`;

const ReviewSectionContainer = styled(Container)`
  padding-bottom: 30px;

  @media screen and (max-width: 1023px) {
    padding-left: 50px !important;
    padding-right: 50px !important;
  }
  @media screen and (max-width: 768px) {
    padding-left: 0;
    padding-right: 0;
  }
`;

const Gap = styled(Container)`
  height: 40px;
`;
