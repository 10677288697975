import { Heading } from 'react-bulma-components';
import styled from 'styled-components';

export default function NoLikeContents({ keyword }) {
  return (
    <NoLikeContentsWrapper>
      <Heading size={3}>찜한 콘텐츠가 없습니다.</Heading>
      {/* <SubtitleWrapper>
        <Heading size={5} subtitle>
          <p>• 다른 키워드를 입력해보세요.</p>
          <p>• 만들기, 안전과 같은 주제명으로 검색해보세요.</p>
          <p>• 광복절, 식목일과 같은 법정 기념일 키워드로 검색해보세요.</p>
        </Heading>
      </SubtitleWrapper> */}
    </NoLikeContentsWrapper>
  );
}

const NoLikeContentsWrapper = styled.div`
  padding: 50px 15px;
  height: 300px;
  text-align: center;
  color: ${({ theme }) => theme.gray};
`;
