import ScrollToTop from 'components/ScrollToTop';
import LandingLayout from 'layouts/LandingLayout';
import ServiceLayout from 'layouts/ServiceLayout';
import EventDetailPage from 'pages/EventDetailPage';
import EventListPage from 'pages/EventListPage';
import FindIdPage from 'pages/FindIdPage';
import FindPwPage from 'pages/FindPwPage';
import LandingPage from 'pages/LandingPage';
import LoginPage from 'pages/LoginPage';
import Logout from 'pages/Logout';
import Main from 'pages/Main';
import MonthlyProgram from 'pages/MonthlyProgramPage';
import MyLikeContentsPage from 'pages/MyLikeContentsPage';
import PolicyPage from 'pages/PolicyPage';
import ProfileRoutes from 'pages/ProfileRoutes';
import QuizCategoryPage from 'pages/quiz/QuizCategoryPage';
import QuizListPage from 'pages/quiz/QuizListPage';
import SearchPage from 'pages/SearchPage';
import SignUpPage from 'pages/SignUpPage';
import TeacherRoutes from 'pages/TeacherRoutes';
import PrivateTerms from 'pages/TermsPage/PrivateTerms';
import PrivateThirdParty from 'pages/TermsPage/PrivateThirdParty';
import TermsOfUse from 'pages/TermsPage/TermsOfUse';
import VideoCategoryPage from 'pages/video/VideoCategoryPage';
import VideoDetailPage from 'pages/video/VideoDetailPage';
import VideoListPage from 'pages/video/VideoListPage';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

export default function MainRouter() {
  const apiHost = process.env.REACT_APP_API_HOST;
  return (
    <Router>
      <Switch>
        <LandingLayout exact={true} path="/" component={LandingPage} noMarginTop />
        <Route exact={true} path="/terms" component={TermsOfUse} />
        <Route exact={true} path="/terms/private" component={PrivateTerms} />
        <Route exact={true} path="/terms/private-third-party" component={PrivateThirdParty} />
        <Route exact={true} path="/policy" component={PolicyPage} />
        <LandingLayout
          exact={true}
          path="/signup"
          getUserConfirmation={(message, callback) => {
            const allowTransition = window.confirm(message);
            callback(allowTransition);
          }}
          component={SignUpPage}
        />

        <ServiceLayout exact={true} path="/login" component={LoginPage} />
        <ServiceLayout exact={true} path="/find-id" component={FindIdPage} />
        <ServiceLayout exact={true} path="/find-pw" component={FindPwPage} />
        <Route path="/logout">
          <Logout />
        </Route>

        <ServiceLayout exact={true} path="/main" component={Main} />
        <ServiceLayout exact={true} path="/monthly-program" component={MonthlyProgram} />

        <ServiceLayout exact={true} path="/video/category/:category2?" component={VideoCategoryPage} />
        <ServiceLayout exact={true} path="/video/list/:category2/:category3/:category4" component={VideoListPage} />
        <ServiceLayout exact={true} path="/video/detail/:id" component={VideoDetailPage} onlyAuth />

        <ServiceLayout exact={true} path="/quiz/category" component={QuizCategoryPage} />
        <ServiceLayout exact={true} path="/quiz/list/:category2" component={QuizListPage} />
        <ServiceLayout exact={true} path="/quiz/detail/:id" component={VideoDetailPage} onlyAuth />

        <ServiceLayout path="/teacher" component={TeacherRoutes} />
        <ServiceLayout path="/mypage/profile" component={ProfileRoutes} onlyAuth />
        <ServiceLayout path="/mypage/like" component={MyLikeContentsPage} onlyAuth />

        <ServiceLayout exact={true} path="/search/:searchText" component={SearchPage} />
        <ServiceLayout exact={true} path="/event/list" component={EventListPage} />
        <ServiceLayout exact={true} path="/event/:id" component={EventDetailPage} />

        <Route path="/version">
          <div style={{ height: '100vh' }}>
            <p>NODE_DEV: {process.env.NODE_ENV}</p>
            <p>REACT_APP_API_HOST: {apiHost}</p>
          </div>
        </Route>
        <Route path="*" to="/404">
          <div style={{ height: '100vh' }}>404 Not Found</div>
        </Route>
      </Switch>
      <ScrollToTop />
    </Router>
  );
}
