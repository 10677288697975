import { requestLogin } from 'api';
import Button from 'components/Button';
import { ForwardedInput } from 'components/Form';
import { AuthActions } from 'context/AuthContextProvider';
import { useAuthDispatch } from 'hooks/useAuthContext';
import React, { useState } from 'react';
import { Box, Form, Notification } from 'react-bulma-components';
import { FormProvider, useForm } from 'react-hook-form';
import { Link, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { emailRegex } from 'utils/validate';

const { Field, Control, Help, Checkbox } = Form;

export default function LoginForm() {
  const storageId = window.localStorage.getItem('_2_') || '';
  const storagePw = window.localStorage.getItem('_3_') || '';

  const hasDataInStorage = !!storageId || !!storagePw;

  const [errorMessage, setErrorMessage] = useState('');
  const [isKeepLogin, setIsKeepLogin] = useState(hasDataInStorage);
  const methods = useForm();
  const dispatch = useAuthDispatch();
  const history = useHistory();

  const { register, formState } = methods;
  const { errors } = formState;

  const login = (data) => {
    (async () => {
      const { data: res, error } = await requestLogin(data);

      if (error) {
        setErrorMessage(error.response.data.description);
        return;
      }
      if (res) {
        setErrorMessage('');
        dispatch({
          type: AuthActions.SET_AUTH,
          payload: res,
        });
        history.push('/main');
      }
    })();
  };

  const handleSubmit = (data) => {
    const isEmail = emailRegex.test(data.email_loginId);

    login({
      ...(isEmail
        ? {
            email: data.email_loginId,
          }
        : { login_id: data.email_loginId }),
      password: data.password,
      ...(isKeepLogin && { is_keep_login: true }),
    });
  };

  const handleKeepLogin = (e) => {
    if (!isKeepLogin) {
      setIsKeepLogin(true);
    } else {
      setIsKeepLogin(false);
    }
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(handleSubmit)}>
        <ResponsiveBox>
          <Field>
            <Label>아이디</Label>
            <Control>
              <ForwardedInput
                {...register('email_loginId', {
                  required: '아이디를 입력해주세요.',
                })}
                placeholder="아이디를 입력해주세요."
                type="text"
                defaultValue={storageId}
                color={errors.email_loginId && 'danger'}
                size="medium"
              />
            </Control>
            {errors.email_loginId && <Help color="danger">{errors.email_loginId?.message}</Help>}
          </Field>
          <Field>
            <Label>비밀번호</Label>
            <Control>
              <ForwardedInput
                {...register('password', {
                  required: '비밀번호를 입력해주세요.',
                })}
                placeholder="비밀번호를 입력해주세요."
                type="password"
                defaultValue={storagePw}
                color={errors.password && 'danger'}
                size="medium"
              />
            </Control>
            {errors.password && <Help color="danger">{errors.password?.message}</Help>}
          </Field>
          {errorMessage && <Notification color="danger">{errorMessage}</Notification>}
          <LoginButton size={'medium'} block={true} type="submit">
            로그인
          </LoginButton>

          <SaveLoginInfoField>
            <Control>
              <Checkbox checked={isKeepLogin} onChange={handleKeepLogin}>
                로그인 상태 유지
              </Checkbox>
            </Control>
          </SaveLoginInfoField>

          <ExtraGroup>
            <Extra>
              <Link to="/find-id">아이디를 잊으셨나요?</Link>
            </Extra>
            <Extra>
              <Link to="/find-pw">또는 비밀번호를 잃어버리셨나요?</Link>
            </Extra>
            <Extra>
              <Link to="/signup">아직 마이돌봄 회원이 아닌가요? 지금 가입하세요.</Link>
            </Extra>
          </ExtraGroup>
        </ResponsiveBox>
      </form>
    </FormProvider>
  );
}

const ResponsiveBox = styled(Box)`
  width: 24rem;
  margin: 0 auto;
  padding: 30px;

  @media screen and (max-width: 768px) {
    box-shadow: none;
    width: 100%;
  }
`;

const LoginButton = styled(Button)`
  transition: background 0.3s ease-out;
  margin-top: 8px;
  background-color: ${({ theme }) => theme.yellow};
  border: none;
  font-weight: bold;
  color: ${({ theme }) => theme.gray};
  &:hover {
    background-color: ${(props) => props.theme.hoveredYellow} !important;
  }
`;

const Label = styled(Form.Label)`
  color: ${({ theme }) => theme.gray};
`;

const SaveLoginInfoField = styled(Field)`
  margin-top: 35px;
`;

const ExtraGroup = styled.div`
  margin-top: 20px;
`;

const Extra = styled.p`
  font-size: 0.825rem;
  margin-bottom: 12px;
  a {
    cursor: pointer;
  }
`;
