import { useMemo, useRef, useState } from 'react';
export const useMiddlewareReducer = (reducer, initialState, middlewares = []) => {
  const [state, setState] = useState(initialState);
  const stateRef = useRef(state);

  const dispatch = useMemo(() => {
    let dispatch = () => {
      throw new Error(
        `Dispatching while constructing your middleware is not allowed. ` +
          `Other middleware would not be applied to this dispatch.`
      );
    };

    const middlewareAPI = {
      getState: () => stateRef.current,
      dispatch: (action) => dispatch(action),
    };

    const localDispatch = (action) => {
      stateRef.current = reducer(stateRef.current, action);
      setState(stateRef.current);
    };

    dispatch = middlewares
      .map((middleware) => middleware(middlewareAPI))
      .reduceRight((acc, middleware) => middleware(acc), localDispatch);

    return dispatch;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [state, dispatch];
};

export default useMiddlewareReducer;
