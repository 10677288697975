import { useCallback } from 'react';
import styled from 'styled-components';

export default function WeekButtons({ selectedWeek, setSelectedWeek, weekList }) {
  const handleClickButton = useCallback(
    (weekNum) => {
      setSelectedWeek(weekNum);
    },
    [setSelectedWeek]
  );

  return (
    <WeekButtonsWrapper>
      {weekList.map((week, index) => (
        <WeekButton
          key={index}
          selected={week === selectedWeek}
          onClick={week === selectedWeek ? null : () => handleClickButton(week)}
        >
          {week}주차
        </WeekButton>
      ))}
    </WeekButtonsWrapper>
  );
}

const WeekButtonsWrapper = styled.div`
  width: 190px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const WeekButton = styled.button`
  width: 65px;
  height: 65px;
  background-color: #ffffff;
  cursor: pointer;
  outline: none;
  margin: 20px 0;
  border-radius: 20px;
  border: solid 2px ${({ theme }) => theme.yellow};
  font-size: 16px;
  font-weight: bold;
  color: ${({ theme }) => theme.yellow};
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.1);
  }

  ${({ selected, theme }) =>
    selected
      ? `
        background-color: ${theme.yellow}; 
        color: #ffffff;
      `
      : ''}
`;
