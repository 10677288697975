import { requestEditPassword } from 'api';
import BodyTitle from 'components/BodyTitle';
import Button from 'components/Button';
import { ForwardedInput } from 'components/Form';
import FormLabel from 'components/Form/FormLabel';
import ERROR_REQUEST from 'constants/errorMessages';
import { useRef } from 'react';
import { Box, Columns, Container, Form } from 'react-bulma-components';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { passwordRegex } from 'utils/validate';

const { Field, Help, Control } = Form;

export default function EditPassword() {
  const history = useHistory();
  const methods = useForm({
    mode: 'onBlur',
  });
  const { register, watch, formState } = methods;
  const { errors } = formState;

  const newPassword = useRef({});
  newPassword.current = watch('newPassword', '');

  const handleSubmit = (data) => {
    (async () => {
      const { data: response, error, requestName } = await requestEditPassword(data);

      if (error) {
        alert(ERROR_REQUEST[requestName][error.response.status]);
        return;
      }

      if (response) {
        history.replace('/mypage/profile');
      }
    })();
  };

  return (
    <StyledContainer py={6} breakpoint="desktop" max={true}>
      <BodyTitle paths={['홈', '마이페이지', '프로필편집', '비밀번호 변경']} title="비밀번호 변경" />

      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(handleSubmit)}>
          <ResponsiveBox mt={5}>
            <Field color="yellow">
              <FormLabel required>현재 비밀번호</FormLabel>
              <Control>
                <ForwardedInput
                  {...register('currentPassword', {
                    required: '현재 비밀번호를 입력해주세요.',
                    pattern: {
                      value: passwordRegex,
                      message: '영문자, 숫자, 특수문자의 조합으로 8자 이상으로 입력해주세요.',
                    },
                  })}
                  type="password"
                  placeholder="현재 비밀번호를 입력해주세요."
                  color={errors?.currentPassword && 'danger'}
                />
              </Control>
              {errors?.currentPassword && <Help color="danger">{errors?.currentPassword?.message}</Help>}
            </Field>
            <Field pt={5}>
              <FormLabel required>새 비밀번호</FormLabel>
              <Control>
                <ForwardedInput
                  {...register('newPassword', {
                    required: '새로운 비밀번호를 입력해주세요.',
                    pattern: {
                      value: passwordRegex,
                      message: '영문자, 숫자, 특수문자의 조합으로 8자 이상으로 입력해주세요.',
                    },
                  })}
                  type="password"
                  placeholder="새로운 비밀번호를 입력해주세요."
                  color={errors?.newPassword && 'danger'}
                />
              </Control>
              {errors?.newPassword && <Help color="danger">{errors?.newPassword?.message}</Help>}
            </Field>
            <Field>
              <FormLabel required>새 비밀번호 확인</FormLabel>
              <Control>
                <ForwardedInput
                  {...register('newPasswordConfirm', {
                    required: '새로운 비밀번호를 한번 더 입력해주세요.',
                    validate: (value) => newPassword.current === value || '입력한 비밀번호와 다릅니다.',
                  })}
                  type="password"
                  placeholder="새로운 비밀번호를 한번 더 입력해주세요."
                  color={errors?.newPasswordConfirm && 'danger'}
                />
              </Control>
              {errors?.newPasswordConfirm && <Help color="danger">{errors?.newPasswordConfirm?.message}</Help>}
            </Field>
            <Columns>
              <Columns.Column>
                <Button
                  block={true}
                  type="button"
                  onClick={() => {
                    history.goBack();
                  }}
                >
                  취소하기
                </Button>
              </Columns.Column>
              <Columns.Column>
                <EditButton block={true} noBorder={true} type="submit">
                  변경하기
                </EditButton>
              </Columns.Column>
            </Columns>
          </ResponsiveBox>
        </form>
      </FormProvider>
    </StyledContainer>
  );
}

const StyledContainer = styled(Container)`
  @media screen and (min-width: 1024px) {
    max-width: 24rem;
  }
`;

const ResponsiveBox = styled(Box)`
  width: 24rem;
  margin: 0 auto;
  .field .label {
    padding-top: 16px;
  }

  @media screen and (max-width: 768px) {
    box-shadow: none;
    width: 100%;
  }
`;

const EditButton = styled(Button)`
  background-color: ${({ theme }) => theme.yellow};
`;
