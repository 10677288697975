import styled from 'styled-components';

export default function LandingReview({ imageUrl = '', content = '', reviewer = '' }) {
  return (
    <LandingReviewWrapper>
      <ImageWrapper>
        <Image url={imageUrl} />
      </ImageWrapper>
      <ContentsWrapper>
        <Content>{content}</Content>
        <Reviewer>{reviewer}</Reviewer>
      </ContentsWrapper>
    </LandingReviewWrapper>
  );
}

const LandingReviewWrapper = styled.div`
  filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.25));
  border-radius: 16px;
  max-width: 320px;
  margin: auto;
  overflow: hidden;
  background: #fff;

  @media screen and (max-width: 768px) {
    width: 90%;
  }
`;

const ImageWrapper = styled.div`
  background-color: #fff;
`;

const Image = styled.div`
  width: 100%;
  height: auto;
  background-image: url(${({ url }) => url});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: block;
  position: relative;
  &::after {
    content: '';
    display: block;
    padding-bottom: 71.4%;
  }
`;

const ContentsWrapper = styled.div`
  text-align: left;
  background-color: #fff;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  padding: 24px 24px 15px;
  min-height: 226px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Content = styled.p`
  font-size: 18px;
  line-height: 30px;
`;

const Reviewer = styled.p`
  font-size: 15px;
  font-weight: bold;
  padding-top: 23px;
`;
