import styled from 'styled-components';

const Highlight = styled.span`
  color: rgb(255, 0, 68);
  font-weight: bold;
`;

export const steps = [
  {
    target: '.monthly-program-step',
    content: (
      <p>
        <Highlight>일 별</Highlight>로 활용 할 수 있는 프로그램과 클립이 <Highlight>캘린더</Highlight>로 제공돼요.
      </p>
    ),
    disableBeacon: true,
  },
  {
    target: '.video-step',
    content: (
      <p>
        다양한 주제의 <Highlight>영상</Highlight>을 볼 수 있어요
      </p>
    ),
    disableBeacon: true,
  },
  {
    target: '.quiz-step',
    content: (
      <p>
        <Highlight>초성 퀴즈, 그림 넌센스</Highlight> 등 <Highlight>신나는 퀴즈</Highlight>가 모여있어요.
      </p>
    ),
    disableBeacon: true,
  },
  {
    target: '.teacher-step',
    content: (
      <p>
        <Highlight>돌봄 선생님을</Highlight> 위한 <Highlight>게시판, 연수</Highlight> 등에 참여해보세요.
      </p>
    ),
    disableBeacon: true,
  },
  {
    target: '.event-shopping-step',
    content: (
      <p>
        <Highlight>이벤트</Highlight>를 확인하거나 <Highlight>쇼핑몰</Highlight>로 이동할 수 있어요.
      </p>
    ),
    disableBeacon: true,
  },
  {
    target: '.profile-step',
    content: (
      <p>
        <Highlight>프로필 편집</Highlight>을 하거나 <Highlight>나의 멤버십, 내가 찜한 콘텐츠</Highlight>을 확인해보세요.
      </p>
    ),
    disableBeacon: true,
  },
];
