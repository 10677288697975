import { Link } from 'react-router-dom';
import styled from 'styled-components';

export default function MenuItem({ name, child = [], currentCategory, type, initState = null }) {
  return (
    <MenuItemWrapper>
      <MenuMainItem
        $isSelected={name === currentCategory}
        $type={type}
        to={type === 'video' ? `/${type}/category/${name}` : '/quiz/category'}
      >
        {name}
      </MenuMainItem>
      <MenuSubItemList>
        {child.map((item, index) => (
          <MenuSubItem
            key={index}
            $isSelected={item.name === currentCategory}
            to={
              type === 'video'
                ? `/${type}/list/${name}/${item.name}/${item.child[0]?.name}`
                : `/${type}/list/${encodeURIComponent(item.name)}`
            }
            {...(initState && { onClick: () => initState() })}
          >
            {item.name}
          </MenuSubItem>
        ))}
      </MenuSubItemList>
    </MenuItemWrapper>
  );
}

const MenuItemWrapper = styled.div`
  width: 100%;
`;

const MenuMainItem = styled(Link)`
  display: block;
  background-color: #fff;
  padding: 10px;
  margin: 10px 0;
  color: #1d1d1f;
  cursor: pointer;

  ${({ $isSelected, theme }) =>
    $isSelected
      ? `
        font-weight: bold;
        font-size: 17px;`
      : `
        font-size: 16px;
      `}

  &:hover {
    font-weight: bold;
  }
`;

const MenuSubItemList = styled.div`
  background-color: #fafafb;
`;

const MenuSubItem = styled(Link)`
  display: block;
  padding: 10px 10px 10px 20px;
  font-size: 0.85rem;
  color: #4a4a4c;
  cursor: pointer;

  ${({ $isSelected, theme }) =>
    $isSelected
      ? `
        background-color: ${theme.yellow};
        color: #ffffff !important;
        font-weight: bold;
        font-size: 0.85rem;`
      : ''}

  &:hover {
    background-color: ${({ theme }) => theme.yellow};
    color: #ffffff !important;
    font-weight: bold;
    font-size: 0.85rem;
  }
`;
