import { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';
const GlobalStyle = createGlobalStyle`
  ${reset}
  html {
    font-size: 16px !important;
  }
  body {
    font-size: 1em !important;
    font-family: 'Noto Sans KR', sans-serif;
  }
  * {
    box-sizing: border-box;
  }
  .container {
    @media screen and (max-width: 1023px) {
      padding: 0 12px;
    }
  }
  a.navbar-item.is-active {
    color: ${(props) => props.theme.yellow};
    border-bottom: 1px solid ${(props) => props.theme.yellow};
    font-weight: bold;
  }
  a, a:visited, a:hover {
    color: inherit;
  }
  a.navbar-item:focus, a.navbar-item:focus-within, a.navbar-item:hover, a.navbar-item.is-active, .navbar-link:focus, .navbar-link:focus-within, .navbar-link:hover, .navbar-link.is-active {
    color: ${({ theme }) => theme.yellow};
  }
  b {
    font-weight: bold;
  }
`;

export default GlobalStyle;
