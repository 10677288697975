import { Breadcrumb } from 'react-bulma-components';
import styled from 'styled-components';

export default function BodyTitle({ paths = [], title }) {
  return (
    <BodyTitleWrapper>
      <BreadcrumbStyled separator="succeeds">
        {paths.map((path) => (
          <BreadcrumbItem key={path}>{path}</BreadcrumbItem>
        ))}
      </BreadcrumbStyled>
      {title && <Title>{title}</Title>}
    </BodyTitleWrapper>
  );
}

const BodyTitleWrapper = styled.div``;
const Title = styled.div`
  font-size: 36px;
  font-weight: bold;
  line-height: 1.11;
  color: #1d1d1f;
`;
const BreadcrumbStyled = styled(Breadcrumb)`
  font-size: 14px;
  line-height: 1.8;
  color: #8e8e8f;
  margin: 0 !important;
  padding-left: 3px;
`;

const BreadcrumbItem = styled(Breadcrumb.Item)`
  &:before {
    margin: 0 3px;
  }
`;
