import Flicking from '@egjs/react-flicking';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';
import { useAuthState } from 'hooks/useAuthContext';
import React, { useRef, useState } from 'react';
import { Icon } from 'react-bulma-components';
import { Heading } from 'react-bulma-components';
import styled from 'styled-components';

import Content from './Content';

export default function ContentsCarousel({
  index: carouselIndex,
  type,
  category,
  title: _title,
  titleSize = 5,
  list = [],
}) {
  const user = useAuthState();
  const flickingRef = useRef();
  const isPlaying = useRef(false);
  const [index, setIndex] = useState(0);
  const isSinglePage = list.length <= 5;

  const todayMonth = dayjs().get('month') + 1;
  const todayDate = dayjs().get('date');

  let title = _title;

  if (type === 'today') {
    title = `${todayMonth}월 ${todayDate}일, 오늘의 돌봄 프로그램`;
  } else if (type === 'category') {
    if (carouselIndex === 1) {
      title = `${user.name || ''} 선생님을 위한 ${category} 콘텐츠 추천!`;
    } else {
      title = `${category} 콘텐츠는 어떠세요?`;
    }
  }

  return list.length || type === 'today' ? (
    <ContentsCarouselWrapper>
      <Title size={titleSize}>{title}</Title>
      <FlickingWrapper>
        <IconWrapper
          onClick={
            !isSinglePage && index !== 0
              ? () => {
                  if (!isPlaying.current) {
                    isPlaying.current = true;
                    flickingRef.current?.prev().catch((err) => {
                      if (err.code !== 10) {
                        throw err;
                      }
                      isPlaying.current = false;
                    });
                  }
                }
              : null
          }
        >
          {!isSinglePage && index !== 0 && (
            <Icon style={{ fontSize: 30 }}>
              <FontAwesomeIcon icon={faChevronLeft} />
            </Icon>
          )}
        </IconWrapper>
        {list.length === 0 && type === 'today' ? (
          <NoContents>오늘은 돌봄 교실이 쉬는 날이에요!</NoContents>
        ) : (
          <Flicking
            ref={flickingRef}
            align="prev"
            circular={false}
            disableOnInit={isSinglePage}
            renderOnlyVisible={true}
            bound={true}
            onChanged={({ index }) => {
              setIndex(index);
              isPlaying.current = false;
            }}
            style={{ flex: 1, paddingTop: 20 }}
          >
            {list.map((item, index) => (
              <Content key={index} {...item} noTag={type === 'category'} />
            ))}
          </Flicking>
        )}
        <IconWrapper
          onClick={
            !isSinglePage && list.length - index > 5
              ? () => {
                  if (!isPlaying.current) {
                    isPlaying.current = true;
                    flickingRef.current?.next().catch((err) => {
                      if (err.code !== 10) {
                        throw err;
                      }
                      isPlaying.current = false;
                    });
                  }
                }
              : null
          }
        >
          {!isSinglePage && list.length - index > 5 && (
            <Icon style={{ fontSize: 30 }}>
              <FontAwesomeIcon icon={faChevronRight} />
            </Icon>
          )}
        </IconWrapper>
      </FlickingWrapper>
    </ContentsCarouselWrapper>
  ) : null;
}

const ContentsCarouselWrapper = styled.div`
  margin-bottom: 30px;
`;

const Title = styled(Heading)`
  margin-left: 50px;
  padding-top: 40px;
  margin-bottom: 3px !important;
`;

const FlickingWrapper = styled.div`
  display: flex;
  align-items: stretch;
  width: 100%;
`;

const IconWrapper = styled.div`
  width: 50px;
  margin-bottom: 2.3rem;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const NoContents = styled.div`
  background: #fafafa;
  width: 100%;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 15px 0;
  border-radius: 6px;
  font-size: 1.1rem;
  font-weight: 500;
`;
