import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { requestSchoolList } from 'api';
import Button from 'components/Button';
import Modal from 'components/Modal';
import ERROR_REQUEST from 'constants/errorMessages';
import { useCallback, useState } from 'react';
import { Form, Icon } from 'react-bulma-components';
import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';

const { Field, Control, Input } = Form;

export default function SchoolSearchModal({ inputName, visible, onClose }) {
  const [query, setQuery] = useState('');
  const [schoolList, setSchoolList] = useState([]);
  const { setValue, trigger } = useFormContext();

  const searchSchoolList = useCallback(
    (_keyword) => {
      const keyword = (_keyword || query).trim();

      if (!keyword) {
        setSchoolList([]);
        return;
      }

      (async () => {
        const { data, error, requestName } = await requestSchoolList({
          name: keyword,
          limit: 20,
        });

        if (error) {
          alert(ERROR_REQUEST[requestName][error.response.status]);
          return;
        }

        if (data) {
          setSchoolList(data.list);
          return;
        }
      })();
    },
    [query]
  );

  const handleSchoolNameClick = useCallback(
    (name) => {
      setValue(inputName, name);
      trigger(inputName);
      onClose();
    },
    [setValue, inputName, trigger, onClose]
  );

  const renderSchoolList = useCallback(() => {
    return schoolList.map(({ id, name }) => (
      <SchoolName key={id} onClick={() => handleSchoolNameClick(name)}>
        {name}
      </SchoolName>
    ));
  }, [schoolList, handleSchoolNameClick]);

  return (
    <Modal visible={visible} onClose={onClose}>
      <ModalContent>
        <Field>
          <Control fullwidth>
            <Input
              placeholder="학교명을 검색해주세요."
              onChange={(e) => {
                setQuery(e.target.value);
                searchSchoolList(e.target.value);
              }}
              value={query}
            />

            <Icon align="right" size="small" color="black" onClick={searchSchoolList}>
              <FontAwesomeIcon icon={faSearch} style={{ color: '#999' }} />
            </Icon>
          </Control>
        </Field>
        <ListBox>{schoolList.length > 0 ? renderSchoolList() : null}</ListBox>
        <div style={{ marginTop: 15 }}>
          <Button noHover onClick={onClose}>
            닫기
          </Button>
        </div>
      </ModalContent>
    </Modal>
  );
}

const ModalContent = styled.div`
  padding: 20px;
  border-radius: 8px;
  background-color: #fff;
  width: 90%;
  @media screen and (min-width: 769px) {
    width: 400px;
  }
`;

const ListBox = styled.div`
  padding-top: 4px;
  border: 1px solid #e9e9e9;
  height: 200px;
  overflow: auto;
`;

const SchoolName = styled.p`
  padding: 8px 12px;
  margin-bottom: 4px;
  cursor: pointer;
  &:hover {
    background-color: #e9e9e9;
  }
`;
