import { getContentsLike } from 'api/requests/contentsRequests';
import BodyTitle from 'components/BodyTitle';
import { useFetch } from 'hooks/useFetch';
import { Container } from 'react-bulma-components';
import styled from 'styled-components';

import LikeResult from './LikeResult';
import NoLikeContents from './NoLikeContents';

export default function MyLikeContentsPage() {
  const { data, loading } = useFetch(getContentsLike);

  const videoList = data?.list?.filter((item) => item.category1Name === '영상') || [];
  const quizList = data?.list?.filter((item) => item.category1Name === '퀴즈') || [];
  const existData = !!data?.list?.length;

  return (
    <MyLikeContentsPageWrapper breakpoint="desktop" max={true}>
      <BodyTitle title={'내가 찜한 콘텐츠'} paths={['홈', '마이페이지', '내가 찜한 콘텐츠']} />

      <ContentsList>
        {!!videoList.length && <LikeResult title={'돌봄 영상'} data={videoList} type={'video'} />}
        {!!quizList.length && <LikeResult title={'5분 퀴즈'} data={quizList} type={'quiz'} />}
        {!existData && !loading && <NoLikeContents />}
      </ContentsList>
    </MyLikeContentsPageWrapper>
  );
}

const MyLikeContentsPageWrapper = styled(Container)`
  padding-top: 40px;
`;

const ContentsList = styled.div`
  padding-top: 45px;
`;
