import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useRef } from 'react';
import { Container, Icon } from 'react-bulma-components';
import { useInView } from 'react-intersection-observer';
import styled from 'styled-components';

import Content from './Content';
import WeekButtons from './WeekButtons';

const categoryPriority = {
  프로그램: 1,
  클립: 2,
  퀴즈: 3,
};
const typeMap = {
  영상: 'video',
  퀴즈: 'quiz',
};
const day = ['월요일', '화요일', '수요일', '목요일', '금요일'];

export default function WeeklyCalendar({
  selectedMonth,
  selectedWeek,
  todayMonth,
  todayDate,
  list,
  setSelectedWeek,
  prevMonth,
  nextMonth,
}) {
  const highlightedElementRef = useRef();
  const scrollElementRef = useRef();
  const { ref: leftRef, inView: leftInView } = useInView({
    threshold: 0,
  });
  const { ref: rightRef, inView: rightInView } = useInView({
    threshold: 0,
  });

  const selectedData = list.find((item) => item.weekNum === selectedWeek);
  const dailyContents = selectedData?.daily ?? [];
  const weeklyContents = selectedData?.weekly ?? [];

  const weeklyHighlight = selectedMonth === todayMonth && dailyContents.map((item) => item.date).includes(todayDate);

  dailyContents.forEach((dailyContent) => {
    dailyContent.contents.sort((a, b) => {
      const aValue = a.category1Name === '영상' ? categoryPriority[a.category2Name] : categoryPriority[a.category1Name];
      const bValue = b.category1Name === '영상' ? categoryPriority[b.category2Name] : categoryPriority[b.category1Name];
      return aValue - bValue;
    });
  });

  useEffect(() => {
    if (window && highlightedElementRef?.current && scrollElementRef?.current) {
      scrollElementRef.current?.scrollTo(highlightedElementRef.current.offsetLeft, 0);
    }
  }, []);

  const weekList = list.map((item) => item.weekNum);

  return (
    <WeeklyCalendarWrapper>
      <MonthController>
        <MonthButton
          onClick={!!prevMonth ? prevMonth : null}
          style={{ opacity: !!prevMonth ? 1 : 0, cursor: !!prevMonth ? 'pointer' : 'default' }}
        >
          <Icon align="right" size="small" color="black">
            <FontAwesomeIcon icon={faChevronLeft} style={{ color: '#999' }} />
          </Icon>
        </MonthButton>
        <Month>{selectedMonth}월</Month>
        <MonthButton
          onClick={!!nextMonth ? nextMonth : null}
          style={{ opacity: !!nextMonth ? 1 : 0, cursor: !!nextMonth ? 'pointer' : 'default' }}
        >
          <Icon align="right" size="small" color="black">
            <FontAwesomeIcon icon={faChevronRight} style={{ color: '#999' }} />
          </Icon>
        </MonthButton>
      </MonthController>
      <ContentsWrapper>
        <WeekButtons selectedWeek={selectedWeek} setSelectedWeek={setSelectedWeek} weekList={weekList} />
        <RelativeBox>
          <Shadow shadow={leftInView} position={'left'} />
          <Shadow shadow={rightInView} position={'right'} />
          <DailyContentsWrapper ref={scrollElementRef}>
            <DailyContentsInner>
              <div ref={leftRef}></div>
              {dailyContents.map((dailyContent, index) => (
                <DailyContents
                  key={index}
                  noContents={!dailyContent.contents.length}
                  className={selectedMonth === todayMonth && todayDate === dailyContent.date ? 'highlighted' : ''}
                  {...(selectedMonth === todayMonth &&
                    todayDate === dailyContent.date && { ref: highlightedElementRef })}
                >
                  <DailyInfo>
                    <Day>{day[index]}</Day>
                    <Date>{dailyContent.date || ''}</Date>
                  </DailyInfo>
                  {dailyContent.contents.map((item, index) => (
                    <Content
                      key={index}
                      id={item.id}
                      title={item.title}
                      thumbnail={item.thumbnailUrl}
                      tag={item.category4Name || item.category2Name}
                      tagColor={
                        item.category2Name === '프로그램'
                          ? '#eb7eff'
                          : item.category2Name === '클립'
                          ? '#1ddb5a'
                          : '#ff352b'
                      }
                      query={{
                        type: typeMap[item.category1Name],
                        year: 2021,
                        month: selectedMonth,
                        date: dailyContent.date,
                      }}
                      sellMaterial={item.sellMaterial}
                      runningTime={item.runningTime}
                    />
                  ))}
                  {[0, 1, 2].slice(dailyContent.contents.length).map((i) => (
                    <Content key={'empty' + i} disabled />
                  ))}
                </DailyContents>
              ))}
              <div ref={rightRef} style={{ marginLeft: '-1px' }}></div>
            </DailyContentsInner>
          </DailyContentsWrapper>
        </RelativeBox>
        <WeeklyContentsWrapper>
          <WeeklyContents className={weeklyHighlight ? 'highlighted' : ''} noContents={!weeklyContents.length}>
            <WeeklyTitle>주간 프로그램</WeeklyTitle>
            {weeklyContents.map((item, index) => (
              <Content
                key={index}
                id={item.id}
                title={item.title}
                thumbnail={item.thumbnailUrl}
                tag={item.category4Name || item.category2Name}
                tagColor={'rgb(50, 50, 50, 0.75)'}
                query={{
                  type: typeMap[item.category1Name],
                  year: 2021,
                  month: selectedMonth,
                  date: todayDate,
                }}
                runningTime={item.runningTime}
              />
            ))}

            {[0, 1].slice(weeklyContents.length).map((i) => (
              <Content key={'empty' + i} disabled />
            ))}
          </WeeklyContents>
        </WeeklyContentsWrapper>
      </ContentsWrapper>
    </WeeklyCalendarWrapper>
  );
}

const WeeklyCalendarWrapper = styled.div`
  padding: 40px 0;
  color: #1d1d1f;
`;

const MonthController = styled.div`
  text-align: center;
  font-size: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Month = styled.div`
  padding: 10px;
`;

const MonthButton = styled.button`
  background: none;
  border: none;
  outline: none;
  font-size: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 25px 20px;
  margin: -10px 60px;
  height: 100%;
  cursor: pointer;
`;

const ContentsWrapper = styled(Container)`
  margin: 35px auto 50px;
  display: flex;
  align-items: center;
`;

const RelativeBox = styled.div`
  position: relative;
  overflow: hidden;
`;

const Shadow = styled.div`
  width: 30px;
  background: linear-gradient(
    to ${({ position }) => (position === 'left' ? 'right' : 'left')},
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 0)
  );
  position: absolute;
  opacity: 1;
  display: inline-block;
  top: 0;
  bottom: 0;
  height: 100%;
  z-index: 25;
  transition: opacity 0.3s ease;

  ${({ position }) => (position === 'left' ? 'left: 0;' : 'right: 0;')}
  ${({ shadow }) => (shadow ? 'opacity: 0;' : '')}
`;

const DailyContentsWrapper = styled.div`
  overflow: auto;
  position: relative;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const DailyContentsInner = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
`;

const DailyContents = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  min-width: 175px;
  background-color: #fafafb;
  padding: 15px 20px 40px;
  border-radius: 6px;
  margin-right: 20px;
  position: relative;

  &:nth-child(6) {
    margin-right: 0;
  }

  &.highlighted {
    color: #ffffff;
    background-color: ${({ theme }) => theme.yellow};
  }

  ${({ noContents }) =>
    noContents
      ? `
        border: 2px solid #fafafb;
        background-color: #ffffff;
        &:before {
          content: '프로그램이 없습니다.';
          color: ${({ theme }) => theme.gray};
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          opacity: 0.5;
          text-align: center;
        }
      `
      : ''}
`;

const DailyInfo = styled.div`
  text-align: center;
  line-height: 1.13;
  margin-bottom: 5px;
`;
const Day = styled.div`
  font-size: 16px;
  font-weight: 500;
`;
const Date = styled.div`
  display: inline-block;
  font-size: 32px;
  font-weight: bold;
  line-height: 1.15;
  min-height: 1.15em;
  padding-bottom: 7px;

  .highlighted & {
    padding-bottom: 5px;
    border-bottom: 2px solid #ffffff;
  }
`;

const WeeklyContentsWrapper = styled.div`
  width: 200px;
  padding-left: 20px;
  flex-shrink: 0;
`;

const WeeklyContents = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: #fafafb;
  padding: 15px 20px 40px;
  border-radius: 6px;
  margin-right: 20px;
  position: relative;

  &:last-child {
    margin-right: 0;
  }

  &.highlighted {
    color: #ffffff;
    background-color: ${({ theme }) => theme.yellow};
  }

  ${({ noContents }) =>
    noContents
      ? `
        border: 2px solid #fafafb;
        background-color: #ffffff;
        &:before {
          content: '프로그램이 없습니다.';
          color: ${({ theme }) => theme.gray};
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          opacity: 0.5;
          text-align: center;
        }
      `
      : ''}
`;

const WeeklyTitle = styled.div`
  font-size: 18px;
  font-weight: bold;
  padding: 15px 0;
  text-align: center;
  white-space: nowrap;

  .highlighted & {
    border-bottom: 2px solid #ffffff;
  }
`;
