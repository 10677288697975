import React from 'react';
import { Form } from 'react-bulma-components';

const { Input } = Form;

const ForwardedInput = React.forwardRef((props, ref) => {
  return <Input domRef={ref} {...props} />;
});

export default ForwardedInput;
