import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getSearchKeyword } from 'api/requests/contentsRequests';
import { useRef, useState } from 'react';
import { Container, Icon } from 'react-bulma-components';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

export default function Search() {
  const history = useHistory();
  const inputRef = useRef();
  const [autoCompleteKeywords, setAutoCompleteKeywords] = useState([]);
  const [isShownSearchBox, setIsShownSearchBox] = useState(false);

  const search = (keyword) => {
    const value = keyword || inputRef.current.value.trim();

    if (value) {
      setIsShownSearchBox(false);
      setAutoCompleteKeywords(false);
      history.push(`/search/${encodeURIComponent(value)}`);
    }
  };

  const handleKeyUp = (e) => {
    if (e.key === 'Enter') {
      search();
    } else {
      const keyword = inputRef.current.value.trim();

      if (!keyword) return;

      getSearchKeyword({ keyword }).then((result) => {
        if (result?.data?.list?.length) {
          setAutoCompleteKeywords(result.data.list);
        } else {
          setAutoCompleteKeywords([]);
        }
      });
    }
  };

  const handleClickSearchToggle = () => {
    setIsShownSearchBox((state) => !state);
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, 50);
  };

  return (
    <>
      {isShownSearchBox && (
        <SearchBox>
          <Container breakpoint="desktop" max={true}>
            <InputWrapper>
              <SearchIconWrapper>
                <SearchIcon>
                  <FontAwesomeIcon icon={faSearch} />
                </SearchIcon>
              </SearchIconWrapper>
              <Input ref={inputRef} type="text" onKeyUp={handleKeyUp} onBlur={() => setAutoCompleteKeywords(false)} />
              <SearchButtonWrapper>
                <SearchButton onClick={search}>검색</SearchButton>
              </SearchButtonWrapper>
            </InputWrapper>
            {!!autoCompleteKeywords.length && (
              <AutoComplateKeywords>
                {autoCompleteKeywords.map((keyword) => (
                  <Keyword
                    key={keyword}
                    onMouseDown={() => {
                      search(keyword);
                    }}
                  >
                    {keyword}
                  </Keyword>
                ))}
              </AutoComplateKeywords>
            )}
          </Container>
        </SearchBox>
      )}
      <YellowBgCircleIcon size="medium" color="white" onClick={handleClickSearchToggle}>
        <FontAwesomeIcon icon={faSearch} />
      </YellowBgCircleIcon>
    </>
  );
}

const SearchBox = styled.div`
  border-bottom: 1px solid #ebeaeb !important;
  position: fixed;
  top: 52px;
  left: 0;
  width: 100%;
  min-height: 100px;
  background: #ffffff;
  cursor: auto;
`;

const InputWrapper = styled.div`
  margin: 30px 0;
  position: relative;
  display: flex;
`;

const SearchIconWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 40px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const SearchIcon = styled(Icon)`
  width: 22px !important;
  height: 22px !important;
  color: #8e8e8f;
`;

const Input = styled.input`
  padding: 18px 20px 18px 54px;
  border-radius: 12px;
  background-color: #f5f5f7;
  color: ${({ theme }) => theme.gray};
  font-size: 1rem;
  width: 100%;
  border: none;
  outline: none;
`;

const SearchButtonWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  display: flex;
  align-items: center;
`;

const SearchButton = styled.div`
  padding-right: 25px;
  font-weight: bold;
  color: ${({ theme }) => theme.gray};
  cursor: pointer;
  transition: color 0.1s ease;
  &:hover {
    color: ${({ theme }) => theme.yellow};
  }
`;

const AutoComplateKeywords = styled.div`
  position: absolute;
  width: 100%;
  top: calc(100% + 3px);
  left: 0;
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 4px 10px 0 rgb(0 0 0 / 10%);
  padding: 2px;
`;

const Keyword = styled.div`
  margin: 0.3rem 0;
  padding: 0.3rem 1rem;
  font-size: 1.1rem;
  font-weight: 500;
  color: #333;
  cursor: pointer;
  &:hover {
    color: ${({ theme }) => theme.yellow};
  }
`;

const YellowBgCircleIcon = styled(Icon)`
  background-color: ${({ theme }) => theme.yellow};
  border-radius: 50%;
  margin-left: -0.25rem;
  margin-right: -0.25rem;
`;
