import * as R from 'ramda';
import { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

export function useFetch(requestFunction, params, cacheOptions, deps) {
  const history = useHistory();
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  const options = useMemo(
    () =>
      cacheOptions?.useCache
        ? R.mergeDeepRight(
            {
              cache: true,
              forceUpdate: history.action === 'PUSH' || history.action === 'REPLACE',
            },
            R.omit(['useCache'], cacheOptions)
          )
        : {
            cache: false,
          },
    [history.action, cacheOptions]
  );

  useEffect(() => {
    requestFunction(params, options).then(({ data: _data, error: _error }) => {
      setData(_data);
      setError(_error);
    });
  }, [requestFunction, params, options, deps]);

  return {
    data,
    error,
    loading: !data && !error,
  };
}
