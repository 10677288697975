import Footer from 'components/Footer';
import Header from 'components/Header';
import { Route } from 'react-router-dom';
import styled from 'styled-components';

export default function LandingLayout({ children, component: Component, componentProps, noMarginTop, ...rest }) {
  return (
    <Route {...rest}>
      <Header />
      <ComponentWrapper noMarginTop={noMarginTop}>
        <Component {...componentProps} />
      </ComponentWrapper>
      <Footer />
    </Route>
  );
}

const ComponentWrapper = styled.div`
  ${({ noMarginTop }) => (noMarginTop ? '' : 'margin-top: 53px;')}
  flex: 1;
`;
