export const getAosAnimation = (
  aos = 'fade-up',
  duration = 300,
  easing = 'linear',
  offset = 0
) => ({
  'data-aos': aos,
  'data-aos-duration': duration,
  'data-aos-easing': easing,
  'data-aos-offset': offset,
});
