import AvoidFixedHeaderSection from 'components/AvoidFixedHeaderSection';
import { useCallback, useState } from 'react';
import { Container } from 'react-bulma-components';

import FindIdForm from './components/FindIdForm';
import FindIdResult from './components/FindIdResult';
import { FIND_ID_STEP } from './constants';

export default function FindIdPage() {
  const [step, setStep] = useState(FIND_ID_STEP.FORM);
  const [data, setData] = useState({
    email: '',
    createdAt: '',
  });

  const handleNext = useCallback((value, payload) => {
    window.scrollTo(0, 0);
    setStep(value);
    if (payload) {
      setData(payload);
    }
  }, []);

  return (
    <AvoidFixedHeaderSection>
      <Container>
        {step === FIND_ID_STEP.FORM && <FindIdForm onNext={handleNext} />}
        {step === FIND_ID_STEP.RESULT && <FindIdResult onNext={handleNext} data={data} />}
      </Container>
    </AvoidFixedHeaderSection>
  );
}
