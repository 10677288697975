import React from 'react';
import { Form } from 'react-bulma-components';

const { Checkbox } = Form;

const ForwardedCheckbox = React.forwardRef((props, ref) => {
  return <Checkbox domRef={ref} {...props} />;
});

export default ForwardedCheckbox;
