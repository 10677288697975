import { Button as BulmaButton } from 'react-bulma-components';
import styled from 'styled-components';

export default function Button({ children, noBorder, block, noHover = false, ...restProps }) {
  return (
    <StyledButton noBorder={noBorder} block={block} $noHover={noHover} {...restProps}>
      {children}
    </StyledButton>
  );
}

const StyledButton = styled(({ noBorder, block, ...restProps }) => <BulmaButton {...restProps} />)`
  transition: background 0.3s ease;
  background-color: transparent;
  ${({ block }) => block && 'width: 100%;'}
  ${({ noBorder }) =>
    noBorder
      ? `
    border: 1px solid transparent;
  `
      : ''}

  ${({ $noHover, theme }) =>
    !$noHover
      ? `
        &:hover:not([disabled]) {
          background-color: ${theme.yellow};
          border: 1px solid transparent;
        }`
      : ''}
`;
