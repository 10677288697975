import { useCallback } from 'react';
import { Button } from 'react-bulma-components';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

export default function ButtonToExplore({
  className,
  onButtonClick,
  buttonStyle,
  buttonText = '지금 체험해보세요',
  noBorder,
  bgColor,
  hoveredBgColor,
}) {
  const history = useHistory();

  const handleButtonClick = useCallback(() => {
    history.push('/main');
  }, [history]);

  return (
    <>
      <StyledButton
        size={'large'}
        rounded
        onClick={onButtonClick || handleButtonClick}
        className={className}
        $noBorder={noBorder}
        $bgColor={bgColor}
        $hoveredBgColor={hoveredBgColor}
        style={buttonStyle}
      >
        {buttonText}
      </StyledButton>
    </>
  );
}

const StyledButton = styled((props) => <Button {...props} />)`
  transition: background 0.3s ease-out;
  background-color: ${(props) => props.$bgColor || props.theme.yellow};
  color: ${({ theme }) => theme.gray};
  font-weight: 500;
  ${({ $noBorder }) =>
    $noBorder
      ? `
    border: 1px solid transparent;
  `
      : ''}
  &:hover {
    background-color: ${(props) => props.$hoveredBgColor || props.theme.hoveredYellow};
    border: 1px solid transparent;
  }
`;
