import AvoidFixedHeaderSection from 'components/AvoidFixedHeaderSection';
import InterestContents from 'components/InterestContents';
import { useCallback, useState } from 'react';
import { Container, Heading, Section } from 'react-bulma-components';

import CreateForm from './components/CreateForm';
import Welcome from './components/Welcome';
import { SIGN_UP_STEP } from './constants';

export default function SignUpPage() {
  const [step, setStep] = useState(SIGN_UP_STEP.CREATE);

  const handleNext = useCallback((value) => {
    window.scrollTo(0, 0);
    setStep(value);
  }, []);

  return (
    <>
      <AvoidFixedHeaderSection>
        <Container breakpoint="desktop" max={true}>
          {step === SIGN_UP_STEP.CREATE && <CreateForm onNext={handleNext} />}
          {step === SIGN_UP_STEP.INTEREST_CONTENTS && (
            <Section>
              <Heading>어떤 활동에 관심 있으신가요?(최대 5개)</Heading>
              <Heading subtitle>
                선호하는 활동을 선택하면(최대 5개) 매일매일 관심 있는 콘텐츠를 추천 받을 수 있습니다.
              </Heading>
              <InterestContents
                onNext={() => {
                  handleNext(SIGN_UP_STEP.WELCOME);
                }}
              />
            </Section>
          )}
          {step === SIGN_UP_STEP.WELCOME && <Welcome onNext={handleNext} />}
        </Container>
      </AvoidFixedHeaderSection>
    </>
  );
}
