import { Button, Message, Modal } from 'react-bulma-components';
import styled from 'styled-components';

export default function EventReceptionModal({ visible, onClose, onOk, closeText, okText }) {
  return (
    <Modal show={visible} onClose={onClose}>
      <StyledMessage>
        <MessageHeader>
          <span>SMS 및 이메일 이벤트 정보 수신동의 안내</span>
        </MessageHeader>
        <MessageBody>
          <Content>
            SMS 및 이메일 수신에 동의하시면 마이돌봄의 다양한 이벤트 정보와 혜택을 받아보실 수 있습니다.
          </Content>
          <ButtonGroup>
            <StyledButton onClick={onClose}>{closeText ? closeText : '혜택 없이 가입'}</StyledButton>
            <KeyColorButton onClick={onOk}>{okText ? okText : '혜택받기'}</KeyColorButton>
          </ButtonGroup>
        </MessageBody>
      </StyledMessage>
    </Modal>
  );
}

const StyledMessage = styled(Message)`
  body & {
    width: 400px;
    z-index: 10;
    background: none;
    border-radius: 8px;
  }
`;

const MessageHeader = styled(Message.Header)``;

const MessageBody = styled(Message.Body)`
  body & {
    background-color: #fff;
  }
`;
const Content = styled.div`
  padding: 6px 10px;
  line-height: 1.2;
  font-size: 1.1rem;
  word-break: keep-all;
`;

const ButtonGroup = styled.div`
  margin-top: 20px;
  text-align: right;
`;

const StyledButton = styled(Button)`
  margin-left: 10px;
`;

const KeyColorButton = styled(StyledButton)`
  body & {
    background-color: ${({ theme }) => theme.yellow};
    border: none;
    transition: background-color 0.3s ease;
    &:hover {
      background-color: ${({ theme }) => theme.hoveredYellow};
    }
  }
`;
