import { faHeart as farHeart } from '@fortawesome/free-regular-svg-icons';
import { faHeart, faStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Vimeo from '@u-wave/react-vimeo';
import { deleteLike, postLike } from 'api/requests/contentsRequests';
import CopyrightType1 from 'assets/images/img_opentype01.jpg';
import CopyrightType2 from 'assets/images/img_opentype02.jpg';
import CopyrightType3 from 'assets/images/img_opentype03.jpg';
import CopyrightType4 from 'assets/images/img_opentype04.jpg';
import NoVisualContentsImage from 'assets/images/no-visual-contents.jpg';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Columns, Icon } from 'react-bulma-components';
import Youtube from 'react-youtube';
import styled from 'styled-components';

const getVisualContentsType = (url) => {
  if (url.includes('vimeo')) {
    return 'vimeo';
  }

  if (url.includes('youtu')) {
    return 'youtube';
  }

  if (url.includes('docs.google')) {
    return 'slide';
  }

  return '';
};

export default function VisualContents({ contentsData }) {
  const [like, setLike] = useState(false);

  useEffect(() => {
    setLike(!!contentsData?.isLike);
  }, [contentsData]);

  const url = contentsData?.url;
  const visualContentsType = useMemo(() => getVisualContentsType(url), [url]);

  const isVimeo = useMemo(() => visualContentsType === 'vimeo', [visualContentsType]);
  const isYoutube = useMemo(() => visualContentsType === 'youtube', [visualContentsType]);
  const isSlide = useMemo(() => visualContentsType === 'slide', [visualContentsType]);
  const renderVideoOrSlide = useCallback(() => {
    if (isVimeo) {
      const [videoId] = url.match(/(\d{9})/g);

      return <Vimeo video={videoId} />;
    } else if (isYoutube) {
      const [, videoId] = url.split('https://youtu.be/');
      return <Youtube videoId={videoId} />;
    } else if (isSlide) {
      return (
        <div style={{ minHeight: 200 }}>
          <iframe
            allowFullScreen={true}
            webkitallowfullscreen="true"
            mozallowfullscreen="true"
            style={{ width: '480px', height: '360px' }}
            title="google slide"
            src={url.includes('pub') ? url.replace('pub', 'embed') : url}
          />
        </div>
      );
    } else {
      return (
        <NoVisualContents>
          <img src={NoVisualContentsImage} alt="no-contents" />
        </NoVisualContents>
      );
    }
  }, [isVimeo, isYoutube, isSlide, url]);

  const renderCopyright = useCallback(() => {
    const type = contentsData?.referenceType;
    const reference = contentsData?.reference;
    const contentsFrom = reference && (
      <ReferenceWrapper>
        <ReferenceTitle>콘텐츠 출처: {reference}</ReferenceTitle>
        <ReferenceDesc>
          본 저작물은 공공누리 제 {type}유형에 따라 {reference}의 공공저작물을 이용하였습니다.
        </ReferenceDesc>
      </ReferenceWrapper>
    );

    switch (type) {
      case 1: {
        return (
          <CopyrightWrapper>
            <CopyrightImageWrapper>
              <img src={CopyrightType1} alt={contentsData?.reference} />
            </CopyrightImageWrapper>
            {contentsFrom}
          </CopyrightWrapper>
        );
      }
      case 2: {
        return (
          <CopyrightWrapper>
            <CopyrightImageWrapper>
              <img src={CopyrightType2} alt={contentsData?.reference} />
            </CopyrightImageWrapper>
            {contentsFrom}
          </CopyrightWrapper>
        );
      }
      case 3: {
        return (
          <CopyrightWrapper>
            <CopyrightImageWrapper>
              <img src={CopyrightType3} alt={contentsData?.reference} />
            </CopyrightImageWrapper>
            {contentsFrom}
          </CopyrightWrapper>
        );
      }
      case 4: {
        return (
          <CopyrightWrapper>
            <CopyrightImageWrapper>
              <img src={CopyrightType4} alt={contentsData?.reference} />
            </CopyrightImageWrapper>
            {contentsFrom}
          </CopyrightWrapper>
        );
      }
      default: {
        return <CopyrightWrapper>{contentsFrom}</CopyrightWrapper>;
      }
    }
  }, [contentsData]);

  const toggleLike = useCallback(() => {
    if (like) {
      deleteLike({ id: contentsData.id });
    } else {
      postLike({ id: contentsData.id });
    }
    setLike((like) => !like);
  }, [contentsData.id, like]);

  return (
    <VisualContentsColumn>
      <VisualContentsWrapper>
        <LikeButton onClick={toggleLike}>
          {like ? (
            <Icon>
              <FontAwesomeIcon icon={faHeart} style={{ color: '#ff6262' }} />
            </Icon>
          ) : (
            <Icon>
              <FontAwesomeIcon icon={farHeart} style={{ color: '#999' }} />
            </Icon>
          )}
          <span style={{ paddingBottom: 2, color: like ? '#ff6262' : '#999' }}>찜하기</span>
        </LikeButton>
        <Level>
          {renderStars(contentsData?.difficulty)}
          <ActiveTime>
            {isSlide
              ? `${contentsData?.numOfQuestion}문제`
              : `활동시간 ${Math.round(contentsData?.activityTime / 60)}분`}
          </ActiveTime>
        </Level>
        {renderVideoOrSlide()}
        {renderCopyright()}
      </VisualContentsWrapper>
    </VisualContentsColumn>
  );
}

const Star = ({ filled }) => {
  return (
    <Icon style={{ width: 20, height: 20 }}>
      <StyledFontAwesomeIcon icon={faStar} filled={filled} />
    </Icon>
  );
};

const StyledFontAwesomeIcon = styled(({ filled, ...rest }) => <FontAwesomeIcon {...rest} />)`
  color: ${({ filled, theme }) => (filled ? theme.yellow : '#e9e9e9')};
`;

const renderStars = (difficulty) => {
  const nodeList = [];
  for (let i = 0; i < 3; i += 1) {
    if (i < difficulty) {
      nodeList.push(<Star key={i} filled={true} />);
    } else {
      nodeList.push(<Star key={i} filled={false} />);
    }
  }

  return nodeList;
};

const VisualContentsColumn = styled(Columns.Column)`
  @media screen and (max-width: 768px) {
    text-align: center;
  }
`;

const VisualContentsWrapper = styled.div`
  width: 100%;
  position: relative;
  display: inline-block;
  border-radius: 8px;
  iframe {
    border-radius: 8px;
    max-width: 480px;
    max-height: 270px;
    @media screen and (max-width: 768px) {
      width: 100%;
    }
  }
`;

const LikeButton = styled.div`
  cursor: pointer;
  position: absolute;
  left: 0;
  top: -36px;
  display: flex;
  align-items: center;
  font-weight: 500;
`;

const Level = styled.div`
  position: absolute;
  right: 0;
  top: -36px;
  display: flex;
  align-items: center;
`;

const ActiveTime = styled.span`
  padding-left: 10px;
  font-size: 14px;
  color: #8e8e8f;
`;

const NoVisualContents = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #e9e9e9;
  background: #8e8e8e;
  @media screen and (max-width: 768px) {
    width: 100%;
    height: auto;
  }
`;

const CopyrightWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-top: 16px;
  font-size: 14px;
  @media screen and (max-width: 768px) {
    font-size: 12px;
  }
`;

const CopyrightImageWrapper = styled.div`
  width: 160px;
  margin-right: 16px;
  flex-shrink: 0;
  @media screen and (max-width: 768px) {
    width: 120px;
  }
`;
const ReferenceWrapper = styled.div`
  color: ${({ theme }) => theme.gray};
`;

const ReferenceTitle = styled.p`
  font-weight: 500;
  margin-bottom: 3px;
`;

const ReferenceDesc = styled.p`
  line-height: 1.1;
  font-size: 0.8rem;
`;
