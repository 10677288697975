import { requestEditInterestContents, requestInterestCategories } from 'api';
import Button from 'components/Button';
import ERROR_REQUEST from 'constants/errorMessages';
import * as R from 'ramda';
import { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

const MAX_CONTENTS_LENGTH = 5;

export default function InterestContents({ initialDatas, onNext }) {
  const [selectedContents, setSelectedContents] = useState([]);
  const [interestContentsFromApi, setInterestContentsFromApi] = useState([]);

  const handleBoxClick = useCallback(
    (content) => {
      if (selectedContents.includes(content)) {
        const indexOfExistContent = selectedContents.indexOf(content);
        setSelectedContents(selectedContents.filter((c, i) => i !== indexOfExistContent));
      } else {
        if (selectedContents.length >= MAX_CONTENTS_LENGTH) {
          return;
        }

        const newSelectedContents = selectedContents.concat(content);
        setSelectedContents(newSelectedContents);
      }
    },
    [selectedContents, setSelectedContents]
  );

  const handleSave = useCallback(() => {
    (async () => {
      const toParams = {};
      [null, null, null, null, null].forEach((value, index) => {
        toParams[`interest${index + 1}_id`] = R.isNil(selectedContents[index]) ? value : +selectedContents[index];
      });

      const { error, requestName } = await requestEditInterestContents(toParams);

      if (error) {
        alert(ERROR_REQUEST[requestName][error.response.status]);
        return;
      }
      const selectedContentsNames = [];
      selectedContents.forEach((id) => {
        interestContentsFromApi.forEach((contents) => {
          if (contents.id === id) {
            selectedContentsNames.push(contents.name);
          }
        });
      });
      onNext(selectedContentsNames);
    })();
  }, [onNext, selectedContents, interestContentsFromApi]);

  useEffect(() => {
    (async () => {
      const { data, error, requestName } = await requestInterestCategories();
      if (error) {
        alert(ERROR_REQUEST[requestName][error.response.status]);
        return;
      }

      if (data) {
        setInterestContentsFromApi(data.list);
        const initialSelectedContents = [];
        const initialDatasIterator = initialDatas ?? [];
        initialDatasIterator.forEach((name) => {
          data.list.forEach((contents) => {
            if (contents.name === name) {
              initialSelectedContents.push(contents.id);
            }
          });
        });
        setSelectedContents(initialSelectedContents);
      }
    })();
  }, [initialDatas]);

  return (
    <>
      <div
        style={{
          display: 'flex',
        }}
      >
        <Flex>
          {interestContentsFromApi.map(({ id, name, thumbnailUrl }) => {
            return (
              <ContentsBox
                style={{ backgroundImage: `url('${thumbnailUrl}')` }}
                key={id}
                onClick={() => handleBoxClick(id)}
              >
                <Dim active={selectedContents.includes(id)}>{name}</Dim>
              </ContentsBox>
            );
          })}
        </Flex>
      </div>
      <SaveButton rounded onClick={handleSave}>
        관심 활동 저장하기
      </SaveButton>
    </>
  );
}

const Flex = styled.div`
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const ContentsBox = styled.div`
  width: 180px;
  height: 180px;
  border-radius: 6px;
  margin: 12px;
  overflow: hidden;
  position: relative;
  margin-top: -1px;
  margin-left: -1px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  cursor: pointer;
  background-color: #e9e9e9;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  @media screen and (max-width: 768px) {
    width: 100px;
    height: 100px;
  }
`;

const SaveButton = styled(Button)`
  display: block;
  margin: 40px auto;
  font-size: 24px;
  padding: 0 40px;
  background-color: ${({ theme }) => theme.yellow};
`;

const Dim = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ active }) => (active ? 'rgba(0, 0, 0, 0.8)' : 'rgba(0, 0, 0, 0.3)')};
  height: 100%;
  word-break: keep-all;
  padding: 0 25px;
  color: ${({ active, theme }) => (active ? theme.hoveredYellow : '#fff')};
  font-weight: bold;
  font-size: 24px;
  font-weight: 900;
  @media screen and (max-width: 768px) {
    font-size: 16px;
  }
`;
