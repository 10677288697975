import { requestUser } from 'api/requests';
import ChannelService from 'ChannelService';
import { AuthActions } from 'context/AuthContextProvider';
import { useAuthDispatch, useAuthState } from 'hooks/useAuthContext';
import React, { useEffect, useMemo } from 'react';
import { Container, Navbar } from 'react-bulma-components';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import AuthorizedHeader from './AuthorizedHeader';
import NonAuthorizedHeader from './NonAuthorizedHeader';

function Header({ onlyAuth = false }) {
  const user = useAuthState();
  const dispatch = useAuthDispatch();
  const history = useHistory();

  const { token } = user;
  const check = !!token;

  const cacheOptions = useMemo(
    () => ({
      useCache: true,
      forceUpdate: !check,
    }),
    [check]
  );

  /* 로그인 사용자 정보 로드 */
  useEffect(() => {
    (async () => {
      const { data, error } = await requestUser(null, cacheOptions);

      if (error) {
        return;
      }
      if (data) {
        dispatch({
          type: AuthActions.SET_PROFILE,
          payload: data,
        });
      }
    })();
  }, [cacheOptions, dispatch]);

  /* 로그인 사용자 정보 로드 */
  useEffect(() => {
    if (onlyAuth && !check) {
      alert('로그인이 필요한 페이지입니다.');
      history.replace('/');
    }
  }, [check, history, onlyAuth]);

  /* 채널톡 설정 */
  useEffect(() => {
    ChannelService.boot({
      pluginKey: '3daf7c52-c393-4e0b-8154-812e006e6b16',
      ...(check && {
        profile: {
          name: user.name,
          mobileNumber: user.hp,
        },
      }),
    });
  }, [user, check]);

  return (
    <ResponsiveNavbar>
      <Container breakpoint="desktop" max={true}>
        {check ? <AuthorizedHeader /> : <NonAuthorizedHeader />}
      </Container>
    </ResponsiveNavbar>
  );
}

const ResponsiveNavbar = styled(Navbar)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  /* bootstrap, bulma 충돌 방지 bulma 사용 */
  border-bottom: 1px solid #ebeaeb !important;
  margin-bottom: 0 !important;
  min-height: 3.25rem !important;
  .navbar-brand {
    float: none;
    height: initial;
    padding: initial;
    font-size: initial;
    line-height: initial;
  }
  @media screen and (max-width: 1023px) {
    .container {
      padding: 0;
    }
  }
`;

export default Header;
