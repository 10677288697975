import { getSearch } from 'api/requests/contentsRequests';
import { useFetch } from 'hooks/useFetch';
import { useMemo } from 'react';
import { Container } from 'react-bulma-components';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import NoSearchResult from './NoSearchResult';
import SearchResult from './SearchResult';

export default function SearchPage() {
  const { searchText: _searchText } = useParams();
  const searchText = decodeURIComponent(_searchText);
  const params = useMemo(() => ({ keyword: searchText }), [searchText]);
  const { data, loading } = useFetch(getSearch, params);
  const existData = !!(data?.program?.length || data?.clip?.length || data?.quiz?.length);

  return (
    <SearchPageWrapper breakpoint="desktop" max={true}>
      {(existData || loading) && (
        <SearchInfo>
          <SearchText>'{searchText}'</SearchText> 검색 결과
        </SearchInfo>
      )}

      <SearchResultWrapper>
        {!!data?.program?.length && <SearchResult title={'프로그램'} data={data.program} type={'video'} />}
        {!!data?.clip?.length && <SearchResult title={'클립'} data={data.clip} type={'video'} />}
        {!!data?.quiz?.length && <SearchResult title={'퀴즈'} data={data.quiz} type={'quiz'} />}
        {!existData && !loading && <NoSearchResult keyword={searchText} />}
      </SearchResultWrapper>
    </SearchPageWrapper>
  );
}

const SearchPageWrapper = styled(Container)`
  padding-top: 40px;
`;

const SearchInfo = styled.div`
  font-weight: bold;
  color: #888888;
  font-size: 1.8rem;
`;

const SearchText = styled.div`
  display: inline-block;
  color: ${({ theme }) => theme.gray};
`;

const SearchResultWrapper = styled.div`
  padding-top: 45px;
`;
