import React from 'react';
import { Navbar } from 'react-bulma-components';

export default function Nav({ activeKey, children, ...restProps }) {
  return (
    <Navbar.Container {...restProps}>
      {React.Children.map(children, (child) =>
        React.cloneElement(child, {
          ...child.props,
          active: activeKey.includes(child.key),
        })
      )}
    </Navbar.Container>
  );
}
