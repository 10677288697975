import { Link } from 'react-router-dom';
import styled from 'styled-components';

export default function Content({
  id = 0,
  title = '',
  thumbnail = '',
  tag = '',
  tagColor = '',
  query = {},
  sellMaterial = null,
  disabled = false,
  runningTime,
}) {
  return (
    <ContentWrapper
      to={`/${query.type}/detail/${id}?year=${query.year}&month=${query.month}&date=${query.date}`}
      disabled={disabled}
    >
      <Thumbnail url={thumbnail} tag={tag} tagColor={tagColor}>
        {!!runningTime && (
          <Duration>
            {Math.round(runningTime / 60)}:{(runningTime % 60).toString().padStart(2, '0')}
          </Duration>
        )}
      </Thumbnail>
      <Title>
        {!!sellMaterial && <GiftEmoji>🎁</GiftEmoji>}
        <span style={{ verticalAlign: 'middle' }}>{title}</span>
      </Title>
    </ContentWrapper>
  );
}

const ContentWrapper = styled(Link)`
  margin-top: 10px;
  cursor: pointer;

  ${({ disabled }) => (disabled ? 'pointer-events: none;' : '')}
`;

const Thumbnail = styled.div`
  ${({ url }) => (url ? '' : 'opacity: 0;')}
  background-color: #333;
  padding-bottom: 75%;
  background: #dddddd url('${({ url }) => url}') no-repeat center center;
  background-size: cover;
  border-radius: 6px;
  border: solid 2px rgba(255, 255, 255, 0.41);
  position: relative;
  transition: transform 0.15s ease;
  transform-origin: center;

  .highlighted & {
    padding-bottom: 100%;
  }

  &:after {
    content: '${({ tag }) => tag}';
    position: absolute;
    top: 20px;
    left: -2px;
    font-size: 14px;
    font-weight: bold;
    line-height: 1.25;
    padding: 5px 11px;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    background-color: ${({ tagColor }) => tagColor};
    color: #ffffff;
    opacity: 0.9;
  }

  &:hover {
    transform: scale(1.08);
  }
`;

const Duration = styled.div`
  background: rgba(0, 0, 0, 0.8);
  color: #ffffff;
  position: absolute;
  right: 5px;
  bottom: 5px;
  padding: 3px 4px;
  border-radius: 2px;
  font-size: 0.8rem;
  font-weight: 500;
`;

const Title = styled.div`
  margin: 10px 0 10px 10px;
  font-size: 16px;
  font-weight: 500;
  height: 2.8em;
  overflow: hidden;
  line-height: 1.25;
  word-break: keep-all;
`;

const GiftEmoji = styled.span`
  vertical-align: middle;
  margin-right: 5px;
`;
