import api from 'api';
import { camelize, snakize } from 'utils/obj';

export const requestFindId = async (params) => {
  const { data, error } = await api({
    method: 'post',
    url: '/account/find-id',
    data: params,
  });

  return {
    data: camelize(data),
    error,
    requestName: 'FIND_ID',
  };
};

export const requestResetPw = async (params) => {
  const { data, error } = await api({
    method: 'post',
    url: '/account/reset-pw',
    data: snakize(params),
  });

  return {
    data,
    error,
    requestName: 'RESET_PW',
  };
};

export const requestLogin = async (params) => {
  const { data, error } = await api({
    method: 'post',
    url: '/account/login',
    data: params,
  });

  return {
    data: data ? camelize(data) : null,
    error,
    requestName: 'LOGIN',
  };
};

export const requestSchoolList = async (params) => {
  const { data, error } = await api({
    method: 'get',
    url: '/school',
    params,
  });

  return {
    data,
    error,
    requestName: 'GET_SCHOOL',
  };
};

export const requestSignUp = async (params) => {
  const { data, error } = await api({
    method: 'post',
    url: '/account',
    data: snakize(params),
  });

  return {
    data,
    error,
    requestName: 'SIGN_UP',
  };
};

export const requestEditInterestContents = async (params) => {
  const { data, error } = await api({
    method: 'post',
    url: '/account/interest',
    data: params,
  });

  return {
    data,
    error,
    requestName: 'EDIT_INTEREST',
  };
};

export const requestCheckDuplicationId = async (params) => {
  const { data, error } = await api({
    method: 'post',
    url: '/account/duplication_check',
    data: snakize(params),
  });

  return {
    data,
    error,
    requestName: 'DUPLICATION_CHECK_ID',
  };
};
export const requestCheckRecommendedBy = async (params) => {
  const { data, error } = await api({
    method: 'post',
    url: '/account/recommended-by-check',
    data: snakize(params),
  });

  return {
    data,
    error,
    requestName: 'RECOMMENDED_BY_CHECK',
  };
};

export const requestUser = async (params, options) => {
  const { data, error } = await api({
    method: 'get',
    url: '/account',
    params,
    ...options,
  });

  return {
    data: data ? camelize(data) : null,
    error,
    requestName: 'GET_USER',
  };
};

export const requestEditUser = async (params) => {
  const { data, error } = await api({
    method: 'patch',
    url: '/account',
    data: snakize(params),
  });

  return {
    data: data ? camelize(data) : null,
    error,
    requestName: 'EDIT_USER',
  };
};

export const requestEditEmail = async (params) => {
  const { data, error } = await api({
    method: 'patch',
    url: '/account/email',
    data: params,
  });

  return {
    data,
    error,
    requestName: 'EDIT_EMAIL',
  };
};
export const requestEditPassword = async (params) => {
  const { data, error } = await api({
    method: 'patch',
    url: '/account/password',
    data: snakize(params),
  });

  return {
    data,
    error,
    requestName: 'EDIT_PASSWORD',
  };
};

export const requestSignUpSMSVerify = async (params) => {
  const { data, error } = await api({
    method: 'post',
    url: '/account/join/sms-request',
    data: params,
  });

  return {
    data,
    error,
    requestName: 'JOIN_SMS_REQUEST',
  };
};

export const requestSignUpSMSVerifyCode = async (params) => {
  const { data, error } = await api({
    method: 'post',
    url: '/account/join/sms-verify',
    data: params,
  });

  return {
    data: data ? camelize(data) : null,
    error,
    requestName: 'JOIN_SMS_REQUEST_VERIFY',
  };
};

export const requestFindPwSMSVerify = async (params) => {
  const { data, error } = await api({
    method: 'post',
    url: '/account/reset-pw/sms-request',
    data: params,
  });

  return {
    data,
    error,
    requestName: 'RESET_PW_SMS_REQUEST',
  };
};

export const requestFindPwSMSVerifyCode = async (params) => {
  const { data, error } = await api({
    method: 'post',
    url: '/account/reset-pw/sms-verify',
    data: params,
  });

  return {
    data: data ? camelize(data) : null,
    error,
    requestName: 'RESET_PW_SMS_REQUEST_VERIFY',
  };
};

export const requestChangeHpSMSVerify = async (params) => {
  const { data, error } = await api({
    method: 'post',
    url: '/account/change-hp/sms-request',
    data: params,
  });

  return {
    data,
    error,
    requestName: 'CHANGE_HP_SMS_REQUEST',
  };
};

export const requestChangeHp = async (params) => {
  const { data, error } = await api({
    method: 'post',
    url: '/account/change-hp',
    data: params,
  });

  return {
    data,
    error,
    requestName: 'CHANGE_HP',
  };
};
