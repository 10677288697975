import Logo from 'assets/images/dolbom-logo.svg';
import base64url from 'base64url';
import { useAuthState } from 'hooks/useAuthContext';
import React, { useMemo, useState } from 'react';
import { Navbar } from 'react-bulma-components';
import { Link, Redirect, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { parseQuery } from 'utils/parseQuery';

import Nav from './Nav';
import Search from './Search';

const redirectPath = { '/': '/main' };

export default function AuthorizedHeader() {
  const location = useLocation();
  const user = useAuthState();
  const [active, setActive] = useState(false);
  const [visible, setVisible] = useState(false);
  const pathname = location.pathname;

  const toggleMobileMenu = () => {
    setVisible((prevState) => !prevState);
  };
  const query = useMemo(() => parseQuery(location.search), [location]);

  const activeKey = useMemo(() => {
    if (query.year && query.month && query.date) {
      return 'monthly-program';
    } else {
      return pathname.replace('/', '');
    }
  }, [pathname, query]);

  if (redirectPath[pathname]) {
    return <Redirect to={redirectPath[pathname]} />;
  }

  return (
    <>
      <Navbar.Brand>
        <LogoItem renderAs={Link} to="/main">
          <img src={Logo} alt="Logo" />
        </LogoItem>
        <Navbar.Burger
          onClick={() => {
            toggleMobileMenu();
          }}
        />
      </Navbar.Brand>
      <Navbar.Menu className={`${visible ? 'is-active' : ''}`}>
        <Nav activeKey={activeKey}>
          <Navbar.Item className="monthly-program-step" key="monthly-program" renderAs={Link} to="/monthly-program">
            월간 프로그램
          </Navbar.Item>
          <Navbar.Item className="video-step" key="video" renderAs={Link} to="/video/category">
            돌봄 영상
          </Navbar.Item>
          <Navbar.Item className="quiz-step" key="quiz" renderAs={Link} to="/quiz/category">
            5분 퀴즈
          </Navbar.Item>
          <Navbar.Item className="teacher-step" key="teacher/board" renderAs={Link} to="/teacher/board">
            선생님 공간
          </Navbar.Item>
        </Nav>
        <Navbar.Container align="right">
          <ShoppingMallEventWrap className="event-shopping-step">
            <Navbar.Item
              href="#"
              onClick={() => {
                const token = window.localStorage.getItem('__TOKEN__') || null;
                window.open(`https://schoolever.co.kr/dolbom/login.php?token=${token}&callbackUrl=${base64url('/')}`);
              }}
              style={{ color: '#ffcc2b', fontWeight: 'bold' }}
            >
              쇼핑몰
            </Navbar.Item>
            <Navbar.Item renderAs={Link} to="/event/list">
              이벤트
            </Navbar.Item>
          </ShoppingMallEventWrap>
          <Navbar.Item
            className="profile-step"
            active={active}
            onMouseOver={() => {
              setActive(true);
            }}
            onMouseOut={() => {
              setActive(false);
            }}
          >
            <Navbar.Link arrowless>{user.name ? user.name : '선생'}님</Navbar.Link>
            <Navbar.Dropdown
              onMouseOver={() => {
                setActive(true);
              }}
              onMouseOut={() => {
                setActive(false);
              }}
            >
              <Navbar.Item renderAs={Link} to="/mypage/profile">
                프로필 편집
              </Navbar.Item>
              <Navbar.Item renderAs={Link} to="/mypage/like">
                내가 찜한 콘텐츠
              </Navbar.Item>
              {/* <Navbar.Item >멤버십 관리</Navbar.Item>
              <Navbar.Item >About Us</Navbar.Item>
              <Navbar.Item >고객센터</Navbar.Item> */}
              <Navbar.Item renderAs={Link} to="/logout">
                로그아웃
              </Navbar.Item>
            </Navbar.Dropdown>
          </Navbar.Item>
          <Navbar.Item>
            <Search />
          </Navbar.Item>
        </Navbar.Container>
      </Navbar.Menu>
    </>
  );
}

const LogoItem = styled(Navbar.Item)`
  min-width: 125px;
  & img {
    height: 100%;
  }
`;

const ShoppingMallEventWrap = styled.div`
  display: flex;
  @media screen and (max-width: 1023px) {
    display: block;
  }
`;
