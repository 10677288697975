import { faHeart, faPlay, faStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Columns, Icon } from 'react-bulma-components';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const { Column } = Columns;

const renderStars = (difficulty) => {
  const nodeList = [];
  for (let i = 0; i < 3; i += 1) {
    if (i < difficulty) {
      nodeList.push(<Star key={i} />);
    } else {
      nodeList.push(<Star key={i} filled={'#e9e9e9'} />);
    }
  }

  return nodeList;
};

export default function Content({
  id,
  title: _title,
  imageThumbnail,
  thumbnailUrl,
  difficulty,
  category1Name,
  category2Name,
  category4Name,
  noTag,
  likeCount,
  viewCount,
  useTitleHighlight = false,
  type,
  search = '',
  runningTime,
}) {
  let title = _title;
  if (useTitleHighlight) {
    const highlightKeyword = title.match(/<b>.*?<\/b>/gi)?.[0];
    if (highlightKeyword) {
      const parts = _title.split(new RegExp(`(${highlightKeyword})`, 'gi'));
      title = parts.map((text, index) =>
        text.toLowerCase() === highlightKeyword.toLowerCase() ? (
          <Highlight key={index}>{text.replace('<b>', '').replace('</b>', '')}</Highlight>
        ) : (
          text
        )
      );
    }
  }

  return (
    <ContentWrapper mobile={{ size: 6 }} tablet={{ size: 4 }} widescreen={{ size: 3 }}>
      <Link to={`/${type}/detail/${id}${search}`}>
        <Thumbnail
          style={{ backgroundImage: `url('${imageThumbnail || thumbnailUrl}')` }}
          tag={category4Name || category2Name}
          tagColor={category2Name === '프로그램' ? '#eb7eff' : category2Name === '클립' ? '#1ddb5a' : '#ff352b'}
          noTag={category4Name || category2Name ? noTag : true}
        >
          {typeof difficulty === 'number' && <Stars>{renderStars(difficulty)}</Stars>}
          {!!runningTime && (
            <Duration>
              {Math.round(runningTime / 60)}:{(runningTime % 60).toString().padStart(2, '0')}
            </Duration>
          )}
        </Thumbnail>
        <TitleWrapper>
          <Title>{title}</Title>
          {viewCount !== undefined && likeCount !== undefined && (
            <InfoGroup>
              <Info>
                <Play /> {viewCount}
              </Info>
              <Info>
                <Heart /> {likeCount}
              </Info>
            </InfoGroup>
          )}
        </TitleWrapper>
      </Link>
    </ContentWrapper>
  );
}

const ContentWrapper = styled(Column)`
  display: inline-block;
`;

const Thumbnail = styled.div`
  width: 100%;
  padding-bottom: 75%;
  background: #dddddd no-repeat center center;
  background-size: cover;
  border-radius: 6px;
  position: relative;
  transition: transform 0.15s ease;
  transform-origin: center;

  ${({ noTag, tag, tagColor }) =>
    noTag
      ? ''
      : `
        &:after {
          content: '${tag}';
          position: absolute;
          top: 10px;
          left: 0;
          font-size: 14px;
          font-weight: bold;
          line-height: 1.25;
          padding: 5px 11px;
          border-top-right-radius: 6px;
          border-bottom-right-radius: 6px;
          background-color: ${tagColor};
          color: #ffffff;
          opacity: 0.9;
        }`}

  &:hover {
    transform: scale(1.08);
  }
`;

const Duration = styled.div`
  background: rgba(0, 0, 0, 0.8);
  color: #ffffff;
  position: absolute;
  right: 5px;
  bottom: 5px;
  padding: 3px 4px;
  border-radius: 2px;
  font-size: 0.8rem;
  font-weight: 500;
`;

const Stars = styled.div`
  position: absolute;
  top: 14px;
  right: 10px;
`;

const Star = ({ filled }) => {
  return (
    <Icon style={{ width: 20, height: 20 }}>
      <StyledFontAwesomeIcon icon={faStar} filled={filled} />
    </Icon>
  );
};

const Play = () => {
  return (
    <Icon style={{ width: 8, height: 8, marginRight: 6 }}>
      <StyledFontAwesomeIcon icon={faPlay} filled={'#8e8e8f'} />
    </Icon>
  );
};

const Heart = () => {
  return (
    <Icon style={{ width: 8, height: 8, marginRight: 6 }}>
      <StyledFontAwesomeIcon icon={faHeart} filled={'#8e8e8f'} />
    </Icon>
  );
};

const StyledFontAwesomeIcon = styled(({ filled, ...rest }) => <FontAwesomeIcon {...rest} />)`
  color: ${({ filled, theme }) => filled || theme.yellow};
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  padding-bottom: 5px;
`;

const Title = styled.div`
  color: #1d1d1f;
  line-height: 1.25;
  font-size: 1.05rem;
  height: 1.25em;
  overflow: hidden;
  padding-right: 5px;
`;
const InfoGroup = styled.div`
  margin-left: 5px;
  font-size: 0.76rem;
  display: flex;
  align-items: center;
`;
const Info = styled.div`
  display: inline-flex;
  align-items: center;
  margin-right: 12px;

  &:last-child {
    margin-right: 3px;
  }
`;

const Highlight = styled.mark`
  font-weight: 500;
`;
