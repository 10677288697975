import Button from 'components/Button';
import { useAuthState } from 'hooks/useAuthContext';
import { useEffect, useState } from 'react';
import { Block, Heading, Section } from 'react-bulma-components';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

export default function Welcome() {
  const history = useHistory();
  const [count, setCount] = useState(3);
  const user = useAuthState();

  useEffect(() => {
    const interval = setInterval(() => {
      setCount((prev) => prev - 1);
    }, 1000);

    if (count === 0) {
      clearInterval(interval);
      history.push('/main');
    }
    return () => {
      clearInterval(interval);
    };
  }, [history, count]);

  return (
    <CenterSection>
      <Heading>환영합니다!</Heading>
      <p>{user.id}님, 마이돌봄 회원 가입을 진심으로 축하합니다!</p>
      <p>마이돌봄 회원이 누릴 수 있는 다양한 서비스를 확인해보세요!</p>
      <VerticalCenter>
        <Button rounded>메인 화면으로 가기 </Button>
      </VerticalCenter>
      <Block pt={4} textSize={6}>
        {count}초 후 자동으로 이동합니다.
      </Block>
    </CenterSection>
  );
}

const CenterSection = styled(Section)`
  height: calc(100vh - 295px);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  p {
    font-size: 1.25rem;
    margin-bottom: 8px;
  }
`;

const VerticalCenter = styled.div`
  padding-top: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
