import { emailRegex, idRegex, phoneRegex } from 'utils/validate';

export const SIGN_UP_STEP = {
  CREATE: 'create',
  INTEREST_CONTENTS: 'interestContents',
  WELCOME: 'welcome',
};

export const SIGN_UP_INPUTS = {
  id: {
    name: 'id',
    label: '아이디',
    placeholder: '',
    value: '',
    registerOptions: {
      required: '아이디는 필수 입력사항입니다.',
      pattern: {
        value: idRegex,
        message: '아이디는 4-12자 사이 영문, 숫자만 입력 가능합니다.',
      },
    },
  },
  email: {
    name: 'email',
    label: '이메일 주소',
    placeholder: '이메일 주소를 입력하세요.',
    value: '',
    registerOptions: {
      required: '이메일은 필수 입력사항입니다.',
      pattern: {
        value: emailRegex,
        message: '이메일 형식으로 입력하세요.',
      },
    },
  },
  password: {
    name: 'password',
    label: '비밀번호',
    placeholder: '8자 이상을 입력하세요.',
    value: '',
    registerOptions: {
      required: '비밀번호는 필수 입력사항입니다.',
      minLength: {
        value: 8,
        message: '최소 8자 이상 입력해주세요.',
      },
    },
  },
  passwordConfirm: {
    name: 'passwordConfirm',
    label: '비밀번호 확인',
    placeholder: '비밀번호를 다시 입력하세요.',
    value: '',
  },
  name: {
    name: 'name',
    label: '이름',
    placeholder: '선생님의 성함을 입력하세요.',
    value: '',
    registerOptions: {
      required: '이름은 필수 입력사항입니다.',
    },
  },
  hp: {
    name: 'hp',
    label: '휴대폰 번호',
    placeholder: '- 없이 입력하세요.',
    value: '',
    registerOptions: {
      required: '휴대폰 번호는 필수 입력사항입니다.',
      pattern: {
        value: phoneRegex,
        message: '올바르지 않은 형식입니다.',
      },
    },
  },
  hpVerify: {
    name: 'hpVerify',
    label: '인증번호 입력',
    placeholder: '핸드폰에 전송된 인증번호를 입력하세요.',
    value: '',
    registerOptions: {
      required: '인증번호를 입력하세요.',
      maxLength: 6,
    },
  },
  schoolName: {
    name: 'schoolName',
    label: '학교',
    placeholder: '소속 학교명을 입력하세요.',
    value: '',
    registerOptions: {
      required: '소속 학교명은 필수 입력사항입니다.',
    },
  },
  schoolClassName: {
    name: 'schoolClassName',
    label: '돌봄교실 이름',
    placeholder: '소속 돌봄교실 이름을 입력하세요.',
    value: '',
  },
  recommendedBySchool: {
    name: 'recommendedBySchool',
    label: '추천선생님의 소속 학교',
    placeholder: '추천선생님의 소속 학교를 입력하세요',
    value: '',
  },
  recommendedByName: {
    name: 'recommendedByName',
    label: '추천선생님 이름',
    placeholder: '추천선생님의 이름을 입력하세요',
    value: '',
  },
};
