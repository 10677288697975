import { Heading } from 'react-bulma-components';
import styled from 'styled-components';

export default function TextContent({ title, description, align = 'right', verticalAlign = 'center', className }) {
  return (
    <TextContentWrapper align={align} verticalAlign={verticalAlign} className={className}>
      <TitleHeading size={1} weight={'bold'}>
        {title}
      </TitleHeading>
      <SubHeading subtitle size={4}>
        {description}
      </SubHeading>
    </TextContentWrapper>
  );
}

const TextContentWrapper = styled.div`
  text-align: ${({ align }) => align};
  display: inline-flex;
  flex-direction: column;
  justify-content: ${({ verticalAlign }) => verticalAlign};
  height: 100%;
  @media screen and (max-width: 768px) {
    text-align: center;
    padding-left: 0;
  }
`;

const TitleHeading = styled(Heading)`
  padding-bottom: 20px;

  @media screen and (max-width: 768px) {
    font-size: 2.2rem !important;
  }
`;

const SubHeading = styled(Heading)`
  color: ${({ theme }) => theme.gray};
`;
