import { Box, Container, Content } from 'react-bulma-components';
import styled from 'styled-components';

export default function PrivateThirdParty() {
  return (
    <Container py={6}>
      <Box>
        <TermsContent>
          <h3 id="1-3-">1. 개인정보 제3자 제공</h3>
          <p>
            회사는 「1. 개인정보 수집 및 이용 현황」에서 고지한 범위 내에서만 개인정보를 이용하며, 원칙적으로 이용자의
            개인정보를 제3자에게 제공하지 않습니다. 다만, 아래의 경우에는 예외로 합니다.
          </p>
          <p>가. 이용자가 서비스 이용중 제3자 제공에 동의(수락)한 경우 이용자 동의 후 개인정보 제공이 발생하는 경우</p>
          <p>
            나. 법령의 규정에 의거하거나, 수사 목적으로 법령에 정해진 절차와 방법에 따라 수사기관의 요구가 있는 경우
            귀하께서는 개인정보의 제3자 제공에 대해, 제3자 제공 동의를 철회할 수 있습니다. 다만, 제3자 제공에 기반한
            관련된 일부 서비스의 이용이 제한될 수 있습니다.
          </p>
        </TermsContent>
      </Box>
    </Container>
  );
}

const TermsContent = styled(Content)`
  li {
    padding-bottom: 8px;
  }
  p {
    padding-left: 8px;
    line-height: 1.4;
  }
`;
