import useReducerWithMiddleware from 'hooks/useReducerWithMiddleware';
import { createContext } from 'react';

export const AuthStateContext = createContext();
export const AuthDispatchContext = createContext();

export const AuthActions = {
  SET_AUTH: 'SET_AUTH',
  SET_PROFILE: 'SET_PROFILE',
};

const initialState = {
  token: window.localStorage.getItem('__TOKEN__') || null,
  email: '',
  id: '',
  name: '',
  nick: '',
  hp: '',
  interest1: '',
  interest2: '',
  interest3: '',
  interest4: '',
  interest5: '',
  mailling: false,
  schoolName: '',
  schoolClassName: '',
  sms: false,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case AuthActions.SET_AUTH: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case AuthActions.SET_PROFILE: {
      return {
        ...state,
        ...action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

const authMiddleware = (store) => (next) => (action) => {
  if (action.type === AuthActions.SET_AUTH) {
    window.localStorage.setItem('__TOKEN__', action.payload.token);
  }
  next(action);
};

const middlewares = [authMiddleware];

export const AuthProvider = ({ children }) => {
  const [user, dispatch] = useReducerWithMiddleware(authReducer, initialState, middlewares);

  return (
    <AuthDispatchContext.Provider value={dispatch}>
      <AuthStateContext.Provider value={user}>{children}</AuthStateContext.Provider>
    </AuthDispatchContext.Provider>
  );
};
